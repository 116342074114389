import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';

@Component({
    selector: 'app-hall-configurators',
    templateUrl: './hall-configurators.component.html',
    styleUrls: ['./hall-configurators.component.scss']
})
export class HallConfiguratorsComponent implements OnInit {

    constructor(
        private afs: AngularFirestore,
        private router: Router
    ) { }

    ngOnInit(): void {
    }

    startConfiguration(type: string) {
        // Create a new project in Firestore
        this.afs.collection('projects').add({
            type: type,
            name: `New ${type}`,
            created: new Date(),
            template: `hall-${type.toLowerCase()}`,
            status: 'draft'
        }).then(docRef => {
            // Navigate to the editor with the new project ID
            this.router.navigate(['/e', docRef.id]);
        });
    }
} 