import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { TREE_ACTIONS, IActionMapping } from '@circlon/angular-tree-component';
import nodes from './projectcategories';
import examples from './projectexamples';



@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  projects: any;
  filteredProjects: any;
  searchText: string = '';
  sortColumn: string = '';
  sortDirection: 'asc' | 'desc' = 'desc';
  showHidden: boolean = false;

  showcategories = false;

  curProject: any = null;




  nodes = nodes.nodes.root;
  examples = examples.examples.root;

  constructor(public afs: AngularFirestore) {
    console.log("nodes", this.nodes);
    console.log("examples", this.examples);
    this.afs.collection('projectspublic').valueChanges
      ({ idField: "id" }).subscribe(data => {
        this.projects = data;
        this.filteredProjects = data;
        if (this.sortColumn) {
          this.sortProjects();
        }
        console.log("projects", this.projects);
      });
  }


  ngOnInit(): void {
  }
  updateProject(project) {
    //this.afs.collection('projectspublic').doc(project.id).update(project);
  }



  public select($event) {
    console.log("select", $event);
  }

  public activate(e) {
    console.log("activate", e.node?.data?.id, e);

  }


  curCategoryID = null;
  curExampleID = null;
  showexamples = false;
  nodeActivate(e) {
    console.log("nodeActivate", e.node?.data?.id, e);
    this.curCategoryID = e.node?.data?.id;
  }
  node2Activate(e) {
    console.log("node2Activate", e.node?.data?.id, e);
    this.curExampleID = e.node?.data?.id;
  }

  save() {
    console.log("save");
    if (this.curCategoryID)
      this.curProject.publicCategoryID = this.curCategoryID;
    if (this.curExampleID)
      this.curProject.publicExampleID = this.curExampleID;
    this.afs.collection('projectspublic').doc(this.curProject.id).update(this.curProject)
      .then(() => {
        if (this.sortColumn) {
          this.sortProjects();
        }
      });
  }

  toggleProjectVisibility(project: any, event: Event) {
    event.stopPropagation();
    project.hidden = !project.hidden;
    this.afs.collection('projectspublic').doc(project.id).update({ hidden: project.hidden });
  }

  delCategory(project) {
    console.log("del");
    project.publicCategoryID = null;
    //   if (this.curExampleID)
    //     this.curProject.publicExampleID = this.curExampleID;
    this.afs.collection('projectspublic').doc(project.id).update(project);
  }


  delExample(project) {
    console.log("del");
    project.publicExampleID = null;
    this.afs.collection('projectspublic').doc(project.id).update(project);
  }

  deleteProject(project: any, event: Event) {
    event.stopPropagation();
    if (confirm(`Are you sure you want to delete project "${project.name}"?`)) {
      this.afs.collection('projectspublic').doc(project.id).delete()
        .then(() => {
          if (this.curProject === project) {
            this.curProject = null;
          }
        });
    }
  }

  filterProjects() {
    let filtered = this.projects;

    // Filter by hidden status
    if (!this.showHidden) {
      filtered = filtered.filter(project => !project.hidden);
    }

    // Filter by search text
    if (this.searchText) {
      filtered = filtered.filter(project =>
        project.name.toLowerCase().includes(this.searchText.toLowerCase()) ||
        project.userid?.toLowerCase().includes(this.searchText.toLowerCase()) ||
        project.id?.toLowerCase().includes(this.searchText.toLowerCase())
      );
    }

    this.filteredProjects = filtered;
    this.sortProjects();
  }

  sortBy(column: string) {
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column;
      this.sortDirection = 'asc';
    }
    this.sortProjects();
  }

  private sortProjects() {
    this.filteredProjects.sort((a: any, b: any) => {
      let comparison = 0;
      const aValue = a[this.sortColumn];
      const bValue = b[this.sortColumn];

      if (aValue < bValue) comparison = -1;
      if (aValue > bValue) comparison = 1;

      return this.sortDirection === 'asc' ? comparison : -comparison;
    });
  }

}
