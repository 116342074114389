<div style="text-align: center;">
    <div class="row">
        <div class="col-md-12">
            <iframe width="560" height="315" [src]="sanvideo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
            <!-- <a href="{{curvideo}}" target="_blank">Link to video</a> -->
        </div>
    </div>
    <div class="row" style="padding:20px 30px;width:100%;">

        <div class="col-md-6" style="text-align: left;">

            <ul>
                <li (click)="photoURL('https://www.youtube.com/embed/sMULTYmEIz4');"> 0) Quickstart 3-Min Quickstart</li>
                <li>&nbsp;</li>
                <li style="color: #999; cursor: not-allowed" (click)="photoURL('https://www.youtube.com/embed/oRhuam0gDpM');"> 1) Introduction sheetBuild (Available soon)</li>
                <li style="color: #999; cursor: not-allowed" (click)="photoURL('https://www.youtube.com/embed/JOpSUgLM4wQ');"> 2) Introduction UI-Overview (Available soon)</li>
                <li>&nbsp;</li>
                <li (click)="photoURL('https://www.youtube.com/embed/QGbPV6A44RA');"> 3) How To: Add Controls and Objects</li>
                <li (click)="photoURL( 'https://www.youtube.com/embed/RmMzZo8XLk0');"> 4) How To: Connect controls with 3D Model</li>
                <li (click)="photoURL( 'https://www.youtube.com/embed/scj3poqYidA');"> 5) How To: Import 3D files</li>
                <li style="color: #999; cursor: not-allowed"> 6) How To: Create a parametrized Model (Available soon)</li>
                <li style="color: #999; cursor: not-allowed"> 7) How To: Projects, Sheets, Controls (Available soon)</li>
                <li style="color: #999; cursor: not-allowed"> 8) How To: 2D-Overlays (Available soon)</li>
                <li style="color: #999; cursor: not-allowed"> 9) How To: Charts (Available soon)</li>
                <li style="color: #999; cursor: not-allowed">10) How To: Timers (Available soon)</li>


            </ul>

        </div>

        <div class="col-md-6" style="text-align: left;">

            <ul>
                <li>&nbsp;</li>
                <li>&nbsp;</li>
                <li>&nbsp;</li>
                <li style="color: #999; cursor: not-allowed">11) How To: Import other projects (Available soon)</li>
                <li style="color: #999; cursor: not-allowed">12) How To: Publish a project (Available soon)</li>
                <li style="color: #999; cursor: not-allowed">13) How To: Save End User configurations (Available soon)</li>
                <li style="color: #999; cursor: not-allowed">14) How To: Change the User Interface Look (Available soon)</li>
                <li>&nbsp;</li>
                <li style="color: #999; cursor: not-allowed">15) Expert Scripting Access the API (Available soon)</li>
                <li style="color: #999; cursor: not-allowed">16) Expert Scripting I Interaction in 3D Scene (Available soon)</li>
                <li style="color: #999; cursor: not-allowed">17) Expert Scripting II Import external Data (Available soon)</li>

            </ul>

        </div>
    </div>


</div>