import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'excludeProps'
})
export class ExcludePropsPipe implements PipeTransform {
    transform(obj: any, propsToExclude: string[]): any {
        if (!obj) return obj;

        const result = { ...obj };
        propsToExclude.forEach(prop => {
            delete result[prop];
        });
        return result;
    }
} 