import * as THREE from 'three';

/**
 * We store info for each unique vertex in the scene,
 * to avoid outputting duplicates repeatedly.
 */
interface VertexInfo {
    cpID: number;   // CARTESIAN_POINT ID
    vpID: number;   // VERTEX_POINT ID
    p: THREE.Vector3;
}

export class StepExporter {
    private entityID = 1;
    private vertexMap: Map<string, VertexInfo> = new Map();
    private allFaceIDs: number[] = [];
    private faceMeshMap: Map<number, number> = new Map();
    private nextMeshID = 1;

    constructor() { }

    private getNewEntityID(): number {
        return this.entityID++;
    }

    private writeHeader(): string {
        const now = new Date().toISOString().split('.')[0];
        return [
            'ISO-10303-21;',
            'HEADER;',
            "FILE_DESCRIPTION(('FreeCAD Model'),'2;1');",
            `FILE_NAME('Open CASCADE Shape Model','${now}',('Author'),(`,
            "    ''),'Open CASCADE STEP processor 7.8','FreeCAD','Unknown');",
            "FILE_SCHEMA(('AUTOMOTIVE_DESIGN { 1 0 10303 214 1 1 1 1 }'));",
            'ENDSEC;',
            'DATA;'
        ].join('\n');
    }

    private writeProductContextSection(): string {
        // First section - main product context
        let data = '';
        data += '#1 = APPLICATION_PROTOCOL_DEFINITION(\'international standard\',\n';
        data += '  \'automotive_design\',2000,#2);\n';
        data += '#2 = APPLICATION_CONTEXT(\n';
        data += '  \'core data for automotive mechanical design processes\');\n';
        data += '#3 = SHAPE_DEFINITION_REPRESENTATION(#4,#10);\n';
        data += '#4 = PRODUCT_DEFINITION_SHAPE(\'\',\'\',#5);\n';
        data += '#5 = PRODUCT_DEFINITION(\'design\',\'\',#6,#9);\n';
        data += '#6 = PRODUCT_DEFINITION_FORMATION(\'\',\'\',#7);\n';
        data += '#7 = PRODUCT(\'Unnamed\',\'Unnamed\',\'\',(#8));\n';
        data += '#8 = PRODUCT_CONTEXT(\'\',#2,\'mechanical\');\n';
        data += '#9 = PRODUCT_DEFINITION_CONTEXT(\'part definition\',#2,\'design\');\n';
        data += '#10 = SHAPE_REPRESENTATION(\'\',(#11,#15,#19),#23);\n';
        data += '#11 = AXIS2_PLACEMENT_3D(\'\',#12,#13,#14);\n';
        data += '#12 = CARTESIAN_POINT(\'\',(0.,0.,0.));\n';
        data += '#13 = DIRECTION(\'\',(0.,0.,1.));\n';
        data += '#14 = DIRECTION(\'\',(1.,0.,-0.));\n';
        data += '#15 = AXIS2_PLACEMENT_3D(\'\',#16,#17,#18);\n';
        data += '#16 = CARTESIAN_POINT(\'\',(0.,0.,0.));\n';
        data += '#17 = DIRECTION(\'\',(0.,0.,1.));\n';
        data += '#18 = DIRECTION(\'\',(1.,0.,0.));\n';
        data += '#19 = AXIS2_PLACEMENT_3D(\'\',#20,#21,#22);\n';
        data += '#20 = CARTESIAN_POINT(\'\',(0.,0.,0.));\n';
        data += '#21 = DIRECTION(\'\',(0.,0.,1.));\n';
        data += '#22 = DIRECTION(\'\',(1.,0.,0.));\n';
        data += '#23 = ( GEOMETRIC_REPRESENTATION_CONTEXT(3) \n';
        data += 'GLOBAL_UNCERTAINTY_ASSIGNED_CONTEXT((#27)) GLOBAL_UNIT_ASSIGNED_CONTEXT(\n';
        data += '(#24,#25,#26)) REPRESENTATION_CONTEXT(\'Context #1\',\n';
        data += '  \'3D Context with UNIT and UNCERTAINTY\') );\n';
        data += '#24 = ( LENGTH_UNIT() NAMED_UNIT(*) SI_UNIT(.MILLI.,.METRE.) );\n';
        data += '#25 = ( NAMED_UNIT(*) PLANE_ANGLE_UNIT() SI_UNIT($,.RADIAN.) );\n';
        data += '#26 = ( NAMED_UNIT(*) SI_UNIT($,.STERADIAN.) SOLID_ANGLE_UNIT() );\n';
        data += '#27 = UNCERTAINTY_MEASURE_WITH_UNIT(LENGTH_MEASURE(1.E-07),#24,\n';
        data += '  \'distance_accuracy_value\',\'confusion accuracy\');\n';
        data += '#28 = PRODUCT_RELATED_PRODUCT_CATEGORY(\'part\',$,(#7));\n';
        this.entityID = 29;  // Next ID will be 29
        return data;
    }

    private writeManifoldAndShapeRep(): string {
        let data = '';
        if (this.allFaceIDs.length > 0) {
            // Group faces by mesh ID
            const meshGroups = new Map<number, number[]>();
            for (const [faceID, meshID] of this.faceMeshMap) {
                if (!meshGroups.has(meshID)) {
                    meshGroups.set(meshID, []);
                }
                meshGroups.get(meshID)!.push(faceID);
            }

            let currentID = this.entityID;
            const productDefIDs: number[] = [];

            // Create separate objects for each mesh
            for (const [meshID, faceIDs] of meshGroups) {
                const startID = currentID;

                // Product definition section for this mesh
                data += `#${startID} = SHAPE_DEFINITION_REPRESENTATION(#${startID + 1},#${startID + 7});\n`;
                data += `#${startID + 1} = PRODUCT_DEFINITION_SHAPE('','',#${startID + 2});\n`;
                data += `#${startID + 2} = PRODUCT_DEFINITION('design','',#${startID + 3},#${startID + 6});\n`;
                data += `#${startID + 3} = PRODUCT_DEFINITION_FORMATION('','',#${startID + 4});\n`;
                data += `#${startID + 4} = PRODUCT('Part${meshID}','Part${meshID}','',(#${startID + 5}));\n`;
                data += `#${startID + 5} = PRODUCT_CONTEXT('',#2,'mechanical');\n`;
                data += `#${startID + 6} = PRODUCT_DEFINITION_CONTEXT('part definition',#2,'design');\n`;
                data += `#${startID + 7} = MANIFOLD_SURFACE_SHAPE_REPRESENTATION('',(#11,#${startID + 8}),#${startID + 158});\n`;
                data += `#${startID + 8} = SHELL_BASED_SURFACE_MODEL('',(#${startID + 9}));\n`;
                data += `#${startID + 9} = CLOSED_SHELL('',(${faceIDs.map(id => `#${id}`).join(',')}));\n`;

                // Add geometric context for this mesh
                const contextID = startID + 158;
                data += `#${contextID} = ( GEOMETRIC_REPRESENTATION_CONTEXT(3) \n`;
                data += `GLOBAL_UNCERTAINTY_ASSIGNED_CONTEXT((#${contextID + 4})) GLOBAL_UNIT_ASSIGNED_CONTEXT(\n`;
                data += `(#${contextID + 1},#${contextID + 2},#${contextID + 3})) REPRESENTATION_CONTEXT('Context #1',\n`;
                data += `  '3D Context with UNIT and UNCERTAINTY') );\n`;
                data += `#${contextID + 1} = ( LENGTH_UNIT() NAMED_UNIT(*) SI_UNIT(.MILLI.,.METRE.) );\n`;
                data += `#${contextID + 2} = ( NAMED_UNIT(*) PLANE_ANGLE_UNIT() SI_UNIT($,.RADIAN.) );\n`;
                data += `#${contextID + 3} = ( NAMED_UNIT(*) SI_UNIT($,.STERADIAN.) SOLID_ANGLE_UNIT() );\n`;
                data += `#${contextID + 4} = UNCERTAINTY_MEASURE_WITH_UNIT(LENGTH_MEASURE(1.E-07),#${contextID + 1},\n`;
                data += `  'distance_accuracy_value','confusion accuracy');\n`;

                // Add context dependent shape representation for this mesh
                const depID = contextID + 5;
                data += `#${depID} = CONTEXT_DEPENDENT_SHAPE_REPRESENTATION(#${depID + 1},#${depID + 3});\n`;
                data += `#${depID + 1} = ( REPRESENTATION_RELATIONSHIP('','',#${startID + 7},#10) \n`;
                data += `REPRESENTATION_RELATIONSHIP_WITH_TRANSFORMATION(#${depID + 2}) \n`;
                data += `SHAPE_REPRESENTATION_RELATIONSHIP() );\n`;
                data += `#${depID + 2} = ITEM_DEFINED_TRANSFORMATION('','',#11,#15);\n`;
                data += `#${depID + 3} = PRODUCT_DEFINITION_SHAPE('Placement','Placement of an item',#${depID + 4});\n`;
                data += `#${depID + 4} = NEXT_ASSEMBLY_USAGE_OCCURRENCE('${meshID}','Part${meshID}','',#5,#${startID + 2},$);\n`;
                data += `#${depID + 5} = PRODUCT_RELATED_PRODUCT_CATEGORY('part',$,(#${startID + 4}));\n`;

                // Add styling for this mesh
                const styleID = depID + 6;
                data += `#${styleID} = MECHANICAL_DESIGN_GEOMETRIC_PRESENTATION_REPRESENTATION('',(#${styleID + 1}),#${contextID});\n`;
                data += `#${styleID + 1} = STYLED_ITEM('color',(#${styleID + 2}),#${startID + 8});\n`;
                data += `#${styleID + 2} = PRESENTATION_STYLE_ASSIGNMENT((#${styleID + 3},#${styleID + 9}));\n`;
                data += `#${styleID + 3} = SURFACE_STYLE_USAGE(.BOTH.,#${styleID + 4});\n`;
                data += `#${styleID + 4} = SURFACE_SIDE_STYLE('',(#${styleID + 5}));\n`;
                data += `#${styleID + 5} = SURFACE_STYLE_FILL_AREA(#${styleID + 6});\n`;
                data += `#${styleID + 6} = FILL_AREA_STYLE('',(#${styleID + 7}));\n`;
                data += `#${styleID + 7} = FILL_AREA_STYLE_COLOUR('',#${styleID + 8});\n`;
                data += `#${styleID + 8} = COLOUR_RGB('',0.447059003357,0.474510015008,0.501960993452);\n`;
                data += `#${styleID + 9} = CURVE_STYLE('',#${styleID + 10},POSITIVE_LENGTH_MEASURE(0.1),#${styleID + 11});\n`;
                data += `#${styleID + 10} = DRAUGHTING_PRE_DEFINED_CURVE_FONT('continuous');\n`;
                data += `#${styleID + 11} = COLOUR_RGB('',9.803921802644E-02,9.803921802644E-02,9.803921802644E-02);\n`;

                productDefIDs.push(startID + 2);
                currentID = styleID + 12;
            }

            this.entityID = currentID;
        }
        return data;
    }

    private writeFooter(): string {
        return [
            'ENDSEC;',
            'END-ISO-10303-21;'
        ].join('\n');
    }

    /**
     * Create geometry for all meshes in the scene. Each unique vertex in the scene
     * is assigned a CARTESIAN_POINT + VERTEX_POINT. Then triangles become ADVANCED_FACEs.
     */
    private processGeometry(geometry: THREE.BufferGeometry, matrix: THREE.Matrix4): string {
        let data = '';
        const meshID = this.nextMeshID++;

        const position = geometry.attributes.position;
        const indices = geometry.index;

        // First, transform the geometry points and collect them in "localVertexInfos"
        const localVertexInfos: VertexInfo[] = [];

        for (let i = 0; i < position.count; i++) {
            const p = new THREE.Vector3().fromBufferAttribute(position, i).applyMatrix4(matrix);
            const key = `${p.x.toFixed(10)},${p.y.toFixed(10)},${p.z.toFixed(10)}`;

            let vInfo = this.vertexMap.get(key);
            if (!vInfo) {
                // create new IDs for this unique vertex
                const cpID = this.getNewEntityID();
                const vpID = this.getNewEntityID();
                vInfo = { cpID, vpID, p };
                this.vertexMap.set(key, vInfo);
            }
            localVertexInfos.push(vInfo);
        }

        // Output newly encountered vertices as CARTESIAN_POINT + VERTEX_POINT
        // (Because we only do it once per unique vertex in the entire scene, we mark them as "written")
        for (const vInfo of localVertexInfos) {
            // if not already written
            if (!(vInfo as any)._written) {
                data += `#${vInfo.cpID} = CARTESIAN_POINT('',(${vInfo.p.x},${vInfo.p.y},${vInfo.p.z}));\n`;
                data += `#${vInfo.vpID} = VERTEX_POINT('', #${vInfo.cpID});\n`;
                (vInfo as any)._written = true;
            }
        }

        // Now output faces (triangles)
        if (indices) {
            for (let i = 0; i < indices.count; i += 3) {
                const iA = indices.getX(i);
                const iB = indices.getX(i + 1);
                const iC = indices.getX(i + 2);
                const { data: faceData, faceID } = this.createFace(localVertexInfos, [iA, iB, iC]);
                if (faceID !== -1) {
                    data += faceData;
                    this.allFaceIDs.push(faceID);
                    this.faceMeshMap.set(faceID, meshID);
                }
            }
        } else {
            // Non-indexed geometry => assume it's consecutive triangles
            for (let i = 0; i < localVertexInfos.length; i += 3) {
                if (i + 2 < localVertexInfos.length) {
                    const { data: faceData, faceID } = this.createFace(localVertexInfos, [i, i + 1, i + 2]);
                    if (faceID !== -1) {
                        data += faceData;
                        this.allFaceIDs.push(faceID);
                        this.faceMeshMap.set(faceID, meshID);
                    }
                }
            }
        }

        return data;
    }

    /**
     * Create an ADVANCED_FACE from 3 vertices. 
     * Returns the chunk of STEP data + the face ID or -1 if degenerate.
     */
    private createFace(vertices: VertexInfo[], idx: number[]): { data: string; faceID: number } {
        let stepData = '';

        const pA = vertices[idx[0]].p;
        const pB = vertices[idx[1]].p;
        const pC = vertices[idx[2]].p;

        // Compute face normal
        const ab = new THREE.Vector3().subVectors(pB, pA);
        const ac = new THREE.Vector3().subVectors(pC, pA);
        const normal = new THREE.Vector3().crossVectors(ab, ac).normalize();
        if (normal.lengthSq() === 0) {
            return { data: '', faceID: -1 };
        }

        // Create edges in order
        const edges: [VertexInfo, VertexInfo][] = [
            [vertices[idx[0]], vertices[idx[1]]],
            [vertices[idx[1]], vertices[idx[2]]],
            [vertices[idx[2]], vertices[idx[0]]]
        ];

        // Create edge data
        let edgeData = '';
        const orientedEdgeIDs: number[] = [];

        for (const [v1, v2] of edges) {
            const dir = new THREE.Vector3().subVectors(v2.p, v1.p).normalize();

            // Create LINE with proper direction vector
            const lineID = this.getNewEntityID();
            edgeData += `#${lineID} = LINE('',#${v1.cpID},#${this.getNewEntityID()});\n`;
            edgeData += `#${this.getNewEntityID() - 1} = VECTOR('',#${this.getNewEntityID()},1.);\n`;
            edgeData += `#${this.getNewEntityID() - 1} = DIRECTION('',(${dir.x},${dir.y},${dir.z}));\n`;

            // Create EDGE_CURVE
            const edgeCurveID = this.getNewEntityID();
            edgeData += `#${edgeCurveID} = EDGE_CURVE('',#${v1.vpID},#${v2.vpID},#${lineID},.T.);\n`;

            // Create ORIENTED_EDGE - all .T. like in the working file
            const orientedEdgeID = this.getNewEntityID();
            edgeData += `#${orientedEdgeID} = ORIENTED_EDGE('',*,*,#${edgeCurveID},.T.);\n`;
            orientedEdgeIDs.push(orientedEdgeID);
        }

        // Create EDGE_LOOP
        const loopID = this.getNewEntityID();
        edgeData += `#${loopID} = EDGE_LOOP('',(${orientedEdgeIDs.map(id => `#${id}`).join(',')}));\n`;

        // Create FACE_BOUND
        const faceBoundID = this.getNewEntityID();
        edgeData += `#${faceBoundID} = FACE_BOUND('',#${loopID},.T.);\n`;

        // Create PLANE using face centroid as origin
        const centroid = new THREE.Vector3().add(pA).add(pB).add(pC).multiplyScalar(1 / 3);

        // Create local coordinate system for the plane
        const xAxis = ab.normalize();
        const zAxis = normal;
        const yAxis = new THREE.Vector3().crossVectors(zAxis, xAxis);

        const planeOriginID = this.getNewEntityID();
        const planeAxisID = this.getNewEntityID();
        const planeID = this.getNewEntityID();
        const faceID = this.getNewEntityID();

        stepData += `#${planeOriginID} = CARTESIAN_POINT('',(${centroid.x},${centroid.y},${centroid.z}));\n`;
        stepData += `#${planeAxisID} = AXIS2_PLACEMENT_3D('',#${planeOriginID},#${this.getNewEntityID()},#${this.getNewEntityID()});\n`;
        stepData += `#${this.getNewEntityID() - 2} = DIRECTION('',(${zAxis.x},${zAxis.y},${zAxis.z}));\n`;
        stepData += `#${this.getNewEntityID() - 1} = DIRECTION('',(${xAxis.x},${xAxis.y},${xAxis.z}));\n`;
        stepData += `#${planeID} = PLANE('',#${planeAxisID});\n`;
        stepData += `#${faceID} = ADVANCED_FACE('',(#${faceBoundID}),#${planeID},.T.);\n`;

        stepData += edgeData;
        return { data: stepData, faceID };
    }

    /**
     * Main parse function. 
     */
    public parse(scene: THREE.Scene): string {
        // Reset for each export
        this.entityID = 1;
        this.vertexMap.clear();
        this.allFaceIDs = [];
        this.faceMeshMap.clear();
        this.nextMeshID = 1;

        let step = '';
        step += this.writeHeader();               // 1) HEADER + FILE_SCHEMA
        step += this.writeProductContextSection(); // 2) IDs #1..#15 for product & context

        // 3) Traverse scene to build geometry
        scene.traverse((obj) => {
            if (obj instanceof THREE.Mesh && obj.geometry) {
                obj.updateMatrixWorld(true);
                step += this.processGeometry(obj.geometry, obj.matrixWorld);
            }
        });

        // 4) Create CLOSED_SHELL + BREP + shape referencing #8 and #15
        step += this.writeManifoldAndShapeRep();

        // 5) Footer
        step += this.writeFooter();
        return step;
    }

    /**
     * Utility to export as .stp in a browser environment
     */
    public export(scene: THREE.Scene, filename = 'model.stp'): void {
        const stepData = this.parse(scene);
        const blob = new Blob([stepData], { type: 'text/plain' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    }
}
