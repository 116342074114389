// Interface for I-beam dimensions
export interface IBeamDimensions {
    width: number;           // Total width of the I-beam (mm)
    height: number;          // Total height of the I-beam (mm)
    flangeThickness: number; // Thickness of the flanges (mm)
    webThickness: number;    // Thickness of the web (mm)
}

// Interface for I-beam properties
export interface IBeamProperties {
    crossSectionArea: number;    // Cross-sectional area (mm²)
    momentOfInertiaY: number;    // Moment of inertia about y-axis (mm⁴)
    momentOfInertiaZ: number;    // Moment of inertia about z-axis (mm⁴)
    weightPerMeter: number;      // Weight per meter (kg/m)
    material: string;            // Material grade (e.g., "S235", "S275", "S355")
}

// Enum for common I-beam standards
export enum IBeamStandard {
    IPE = "IPE",      // European I-beam
    HEA = "HEA",      // European wide-flange beam (type A)
    HEB = "HEB",      // European wide-flange beam (type B)
    HEM = "HEM",      // European wide-flange beam (type M)
    UB = "UB",        // British Universal Beam
    UC = "UC",        // British Universal Column
    W = "W"           // American Wide Flange
}

// Class to manage I-beam standards
export class IBeamStandards {
    private static readonly IPE_PROFILES: { [key: string]: IBeamDimensions } = {
        'IPE 100': { width: 5.5, height: 10, flangeThickness: 0.57, webThickness: 0.41 },
        'IPE 120': { width: 6.4, height: 12, flangeThickness: 0.63, webThickness: 0.44 },
        'IPE 140': { width: 7.3, height: 14, flangeThickness: 0.69, webThickness: 0.47 },
        'IPE 160': { width: 8.2, height: 16, flangeThickness: 0.74, webThickness: 0.50 },
        'IPE 180': { width: 9.1, height: 18, flangeThickness: 0.80, webThickness: 0.53 },
        'IPE 200': { width: 10, height: 20, flangeThickness: 0.85, webThickness: 0.56 },
        'IPE 220': { width: 11, height: 22, flangeThickness: 0.92, webThickness: 0.59 },
        'IPE 240': { width: 12, height: 24, flangeThickness: 0.98, webThickness: 0.62 },
        'IPE 270': { width: 13.5, height: 27, flangeThickness: 1.02, webThickness: 0.66 },
        'IPE 300': { width: 15, height: 30, flangeThickness: 1.07, webThickness: 0.71 },
        'IPE 330': { width: 16, height: 33, flangeThickness: 1.15, webThickness: 0.75 },
        'IPE 360': { width: 17, height: 36, flangeThickness: 1.27, webThickness: 0.80 },
        'IPE 400': { width: 18, height: 40, flangeThickness: 1.35, webThickness: 0.86 },
        'IPE 450': { width: 19, height: 45, flangeThickness: 1.46, webThickness: 0.94 },
        'IPE 500': { width: 20, height: 50, flangeThickness: 1.60, webThickness: 1.02 },
        'IPE 550': { width: 21, height: 55, flangeThickness: 1.72, webThickness: 1.11 },
        'IPE 600': { width: 22, height: 60, flangeThickness: 1.90, webThickness: 1.20 }
    };

    private static readonly HEA_PROFILES: { [key: string]: IBeamDimensions } = {
        'HEA 100': { width: 10, height: 9.6, flangeThickness: 0.8, webThickness: 0.5 },
        'HEA 120': { width: 12, height: 11.4, flangeThickness: 0.8, webThickness: 0.5 },
        'HEA 140': { width: 14, height: 13.3, flangeThickness: 0.85, webThickness: 0.55 },
        'HEA 160': { width: 16, height: 15.2, flangeThickness: 0.9, webThickness: 0.6 },
        'HEA 180': { width: 18, height: 17.1, flangeThickness: 0.95, webThickness: 0.6 },
        'HEA 200': { width: 20, height: 19.0, flangeThickness: 1.0, webThickness: 0.65 },
        'HEA 220': { width: 22, height: 21.0, flangeThickness: 1.1, webThickness: 0.7 },
        'HEA 240': { width: 24, height: 23.0, flangeThickness: 1.2, webThickness: 0.75 },
        'HEA 260': { width: 26, height: 25.0, flangeThickness: 1.25, webThickness: 0.75 },
        'HEA 280': { width: 28, height: 27.0, flangeThickness: 1.3, webThickness: 0.8 },
        'HEA 300': { width: 30, height: 29.0, flangeThickness: 1.4, webThickness: 0.85 },
        'HEA 320': { width: 30, height: 31.0, flangeThickness: 1.55, webThickness: 0.9 },
        'HEA 340': { width: 30, height: 33.0, flangeThickness: 1.65, webThickness: 0.95 },
        'HEA 360': { width: 30, height: 35.0, flangeThickness: 1.75, webThickness: 1.0 },
        'HEA 400': { width: 30, height: 39.0, flangeThickness: 1.9, webThickness: 1.1 },
        'HEA 450': { width: 30, height: 44.0, flangeThickness: 2.1, webThickness: 1.15 },
        'HEA 500': { width: 30, height: 49.0, flangeThickness: 2.3, webThickness: 1.2 },
        'HEA 550': { width: 30, height: 54.0, flangeThickness: 2.4, webThickness: 1.25 },
        'HEA 600': { width: 30, height: 59.0, flangeThickness: 2.5, webThickness: 1.3 }
    };

    private static readonly HEB_PROFILES: { [key: string]: IBeamDimensions } = {
        'HEB 100': { width: 10, height: 10, flangeThickness: 1.0, webThickness: 0.6 },
        'HEB 120': { width: 12, height: 12, flangeThickness: 1.1, webThickness: 0.65 },
        'HEB 140': { width: 14, height: 14, flangeThickness: 1.2, webThickness: 0.7 },
        'HEB 160': { width: 16, height: 16, flangeThickness: 1.3, webThickness: 0.8 },
        'HEB 180': { width: 18, height: 18, flangeThickness: 1.4, webThickness: 0.85 },
        'HEB 200': { width: 20, height: 20, flangeThickness: 1.5, webThickness: 0.9 },
        'HEB 220': { width: 22, height: 22, flangeThickness: 1.6, webThickness: 0.95 },
        'HEB 240': { width: 24, height: 24, flangeThickness: 1.7, webThickness: 1.0 },
        'HEB 260': { width: 26, height: 26, flangeThickness: 1.75, webThickness: 1.0 },
        'HEB 280': { width: 28, height: 28, flangeThickness: 1.8, webThickness: 1.05 },
        'HEB 300': { width: 30, height: 30, flangeThickness: 1.9, webThickness: 1.1 },
        'HEB 320': { width: 30, height: 32, flangeThickness: 2.05, webThickness: 1.15 },
        'HEB 340': { width: 30, height: 34, flangeThickness: 2.15, webThickness: 1.2 },
        'HEB 360': { width: 30, height: 36, flangeThickness: 2.25, webThickness: 1.25 },
        'HEB 400': { width: 30, height: 40, flangeThickness: 2.4, webThickness: 1.35 },
        'HEB 450': { width: 30, height: 45, flangeThickness: 2.6, webThickness: 1.4 },
        'HEB 500': { width: 30, height: 50, flangeThickness: 2.8, webThickness: 1.45 },
        'HEB 550': { width: 30, height: 55, flangeThickness: 2.9, webThickness: 1.5 },
        'HEB 600': { width: 30, height: 60, flangeThickness: 3.0, webThickness: 1.55 }
    };

    // Get dimensions for a specific I-beam profile
    public static getDimensions(standard: IBeamStandard, size: string): IBeamDimensions | null {
        const profileName = `${standard} ${size}`;
        switch (standard) {
            case IBeamStandard.IPE:
                return this.IPE_PROFILES[profileName] || null;
            case IBeamStandard.HEA:
                return this.HEA_PROFILES[profileName] || null;
            case IBeamStandard.HEB:
                return this.HEB_PROFILES[profileName] || null;
            default:
                return null;
        }
    }

    // Calculate properties for a given I-beam profile
    public static calculateProperties(dimensions: IBeamDimensions, material: string = "S235"): IBeamProperties {
        const { width, height, flangeThickness, webThickness } = dimensions;

        // Calculate cross-sectional area
        const flangeArea = width * flangeThickness * 2;
        const webArea = (height - 2 * flangeThickness) * webThickness;
        const area = flangeArea + webArea;

        // Calculate moments of inertia
        const Iy = (width * Math.pow(height, 3) / 12) -
            ((width - webThickness) * Math.pow(height - 2 * flangeThickness, 3) / 12);

        const Iz = (2 * flangeThickness * Math.pow(width, 3) / 12) +
            ((height - 2 * flangeThickness) * Math.pow(webThickness, 3) / 12);

        // Calculate weight per meter (assuming steel density of 7850 kg/m³)
        const weightPerMeter = area * 7850 / 1000000; // Convert mm² to m²

        return {
            crossSectionArea: area,
            momentOfInertiaY: Iy,
            momentOfInertiaZ: Iz,
            weightPerMeter: weightPerMeter,
            material: material
        };
    }

    // Get all available sizes for a given standard
    public static getAvailableSizes(standard: IBeamStandard): string[] {
        switch (standard) {
            case IBeamStandard.IPE:
                return Object.keys(this.IPE_PROFILES).map(key => key.replace('IPE ', ''));
            case IBeamStandard.HEA:
                return Object.keys(this.HEA_PROFILES).map(key => key.replace('HEA ', ''));
            case IBeamStandard.HEB:
                return Object.keys(this.HEB_PROFILES).map(key => key.replace('HEB ', ''));
            default:
                return [];
        }
    }

    // Get all available standards
    public static getAvailableStandards(): IBeamStandard[] {
        return Object.values(IBeamStandard);
    }
} 