<div class="export-dialog">
    <h2 mat-dialog-title>Export Model</h2>

    <mat-dialog-content>
        <div class="file-type-section">
            <h3>File Type</h3>
            <mat-form-field>
                <mat-select [(ngModel)]="selectedFileType">
                    <mat-option *ngFor="let type of fileTypes" [value]="type.value">
                        {{type.label}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="object-tree-section" style="display: none;">
            <h3>Objects to Export</h3>
            <div class="tree-container">
                <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                        <button mat-icon-button disabled></button>
                        <mat-checkbox [checked]="node.checked" (change)="toggleNode(node)">
                            {{node.name}}
                        </mat-checkbox>
                    </mat-tree-node>

                    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
                        <button mat-icon-button matTreeNodeToggle>
                            <mat-icon class="mat-icon-rtl-mirror">
                                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                            </mat-icon>
                        </button>
                        <mat-checkbox [checked]="node.checked" (change)="toggleNode(node)">
                            {{node.name}}
                        </mat-checkbox>
                    </mat-tree-node>
                </mat-tree>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button (click)="cancel()">Cancel</button>
        <button mat-raised-button color="primary" (click)="export()">Export</button>
    </mat-dialog-actions>
</div>