// Interface for aluminum profile dimensions
export interface AluProfileDimensions {
    width: number;           // Width of the profile (mm)
    height: number;         // Height of the profile (mm)
    wallThickness: number;  // Wall thickness (mm)
    cornerRadius?: number;  // Optional corner radius (mm)
    slotWidth?: number;     // Optional T-slot width (mm)
    slotDepth?: number;     // Optional T-slot depth (mm)
    standard?: string;      // Reference standard (e.g., "DIN 46277", "ISO 14122")
}

// Interface for aluminum profile properties
export interface AluProfileProperties {
    crossSectionArea: number;    // Cross-sectional area (mm²)
    momentOfInertiaX: number;    // Moment of inertia about x-axis (mm⁴)
    momentOfInertiaY: number;    // Moment of inertia about y-axis (mm⁴)
    weightPerMeter: number;      // Weight per meter (kg/m)
    material: string;            // Material grade (e.g., "EN AW-6060 T66", "EN AW-6063 T6")
}

// Enum for common aluminum profile series
export enum AluProfileSeries {
    BASIC = "BASIC",           // Basic series profiles
    LIGHT = "LIGHT",          // Lightweight series profiles
    HEAVY = "HEAVY",          // Heavy-duty series profiles
    ECONOMIC = "ECONOMIC",     // Economic series profiles
    DIN = "DIN",              // DIN standard profiles
    ISO = "ISO",              // ISO standard profiles
    INDUSTRIAL = "INDUSTRIAL", // Industrial automation profiles
    ITEM = "ITEM"             // Item company profiles
}

// Class to manage aluminum profile standards
export class AluminumProfiles {
    private static readonly DENSITY = 2700; // kg/m³ (aluminum)

    private static readonly BASIC_PROFILES: { [key: string]: AluProfileDimensions } = {
        '20x20': { width: 20, height: 20, wallThickness: 1.8, cornerRadius: 1.5, slotWidth: 6, slotDepth: 5 },
        '30x30': { width: 30, height: 30, wallThickness: 2.2, cornerRadius: 2.0, slotWidth: 8, slotDepth: 6.5 },
        '40x40': { width: 40, height: 40, wallThickness: 2.5, cornerRadius: 2.5, slotWidth: 8, slotDepth: 7 },
        '45x45': { width: 45, height: 45, wallThickness: 2.5, cornerRadius: 2.5, slotWidth: 10, slotDepth: 7.5 },
        '50x50': { width: 50, height: 50, wallThickness: 2.5, cornerRadius: 3.0, slotWidth: 10, slotDepth: 8 }
    };

    private static readonly LIGHT_PROFILES: { [key: string]: AluProfileDimensions } = {
        '20x40': { width: 20, height: 40, wallThickness: 1.8, cornerRadius: 1.5, slotWidth: 6, slotDepth: 5 },
        '30x60': { width: 30, height: 60, wallThickness: 2.2, cornerRadius: 2.0, slotWidth: 8, slotDepth: 6.5 },
        '40x80': { width: 40, height: 80, wallThickness: 2.5, cornerRadius: 2.5, slotWidth: 8, slotDepth: 7 }
    };

    private static readonly HEAVY_PROFILES: { [key: string]: AluProfileDimensions } = {
        '60x60': { width: 60, height: 60, wallThickness: 3.0, cornerRadius: 3.0, slotWidth: 12, slotDepth: 9 },
        '80x80': { width: 80, height: 80, wallThickness: 3.5, cornerRadius: 3.5, slotWidth: 12, slotDepth: 10 },
        '90x90': { width: 90, height: 90, wallThickness: 3.5, cornerRadius: 4.0, slotWidth: 12, slotDepth: 10 },
        '100x100': { width: 100, height: 100, wallThickness: 4.0, cornerRadius: 4.0, slotWidth: 14, slotDepth: 11 }
    };

    private static readonly ECONOMIC_PROFILES: { [key: string]: AluProfileDimensions } = {
        '20x20L': { width: 20, height: 20, wallThickness: 1.5, cornerRadius: 1.5, slotWidth: 6, slotDepth: 4.5 },
        '30x30L': { width: 30, height: 30, wallThickness: 2.0, cornerRadius: 2.0, slotWidth: 8, slotDepth: 6 },
        '40x40L': { width: 40, height: 40, wallThickness: 2.2, cornerRadius: 2.5, slotWidth: 8, slotDepth: 6.5 }
    };

    private static readonly DIN_PROFILES: { [key: string]: AluProfileDimensions } = {
        // DIN 46277 - Standard profiles
        'DIN-30x30': { width: 30, height: 30, wallThickness: 2.0, cornerRadius: 2.0, slotWidth: 8, slotDepth: 6, standard: 'DIN 46277' },
        'DIN-40x40': { width: 40, height: 40, wallThickness: 2.5, cornerRadius: 2.5, slotWidth: 10, slotDepth: 7, standard: 'DIN 46277' },
        'DIN-45x45': { width: 45, height: 45, wallThickness: 2.5, cornerRadius: 2.5, slotWidth: 10, slotDepth: 7.5, standard: 'DIN 46277' },
        'DIN-50x50': { width: 50, height: 50, wallThickness: 3.0, cornerRadius: 3.0, slotWidth: 12, slotDepth: 8, standard: 'DIN 46277' },
        // DIN 17615 - Architectural profiles
        'DIN-60x60': { width: 60, height: 60, wallThickness: 3.0, cornerRadius: 3.0, slotWidth: 12, slotDepth: 9, standard: 'DIN 17615' },
        'DIN-80x80': { width: 80, height: 80, wallThickness: 3.5, cornerRadius: 3.5, slotWidth: 14, slotDepth: 10, standard: 'DIN 17615' }
    };

    private static readonly ISO_PROFILES: { [key: string]: AluProfileDimensions } = {
        // ISO 14122 - Safety of machinery, fixed means of access
        'ISO-30x30': { width: 30, height: 30, wallThickness: 2.0, cornerRadius: 2.0, slotWidth: 8, slotDepth: 6, standard: 'ISO 14122' },
        'ISO-40x40': { width: 40, height: 40, wallThickness: 2.5, cornerRadius: 2.5, slotWidth: 10, slotDepth: 7, standard: 'ISO 14122' },
        'ISO-45x45': { width: 45, height: 45, wallThickness: 2.5, cornerRadius: 2.5, slotWidth: 10, slotDepth: 7.5, standard: 'ISO 14122' },
        // ISO 13920 - Welding tolerances
        'ISO-50x100': { width: 50, height: 100, wallThickness: 3.0, cornerRadius: 3.0, slotWidth: 12, slotDepth: 8, standard: 'ISO 13920' },
        'ISO-60x120': { width: 60, height: 120, wallThickness: 3.5, cornerRadius: 3.5, slotWidth: 12, slotDepth: 9, standard: 'ISO 13920' }
    };

    private static readonly INDUSTRIAL_PROFILES: { [key: string]: AluProfileDimensions } = {
        // Heavy duty industrial profiles
        'IND-100x100': { width: 100, height: 100, wallThickness: 4.0, cornerRadius: 4.0, slotWidth: 14, slotDepth: 11 },
        'IND-120x120': { width: 120, height: 120, wallThickness: 5.0, cornerRadius: 5.0, slotWidth: 16, slotDepth: 12 },
        // Multi-slot profiles
        'IND-80x160': { width: 80, height: 160, wallThickness: 4.0, cornerRadius: 4.0, slotWidth: 14, slotDepth: 11 },
        'IND-160x160': { width: 160, height: 160, wallThickness: 5.0, cornerRadius: 5.0, slotWidth: 16, slotDepth: 12 },
        // Reinforced profiles
        'IND-R-80x80': { width: 80, height: 80, wallThickness: 4.5, cornerRadius: 4.0, slotWidth: 14, slotDepth: 11 },
        'IND-R-100x100': { width: 100, height: 100, wallThickness: 5.0, cornerRadius: 4.5, slotWidth: 16, slotDepth: 12 }
    };

    private static readonly ITEM_PROFILES: { [key: string]: AluProfileDimensions } = {
        // Profile 5 series (lightweight)
        'ITEM-20x20-5': { width: 20, height: 20, wallThickness: 1.7, cornerRadius: 1.5, slotWidth: 5, slotDepth: 4.5, standard: 'Item-5' },
        'ITEM-30x30-5': { width: 30, height: 30, wallThickness: 2.0, cornerRadius: 2.0, slotWidth: 5, slotDepth: 5.5, standard: 'Item-5' },

        // Profile 6 series (standard)
        'ITEM-20x20-6': { width: 20, height: 20, wallThickness: 1.8, cornerRadius: 1.5, slotWidth: 6, slotDepth: 5, standard: 'Item-6' },
        'ITEM-30x30-6': { width: 30, height: 30, wallThickness: 2.2, cornerRadius: 2.0, slotWidth: 6, slotDepth: 6, standard: 'Item-6' },
        'ITEM-40x40-6': { width: 40, height: 40, wallThickness: 2.5, cornerRadius: 2.5, slotWidth: 6, slotDepth: 6, standard: 'Item-6' },

        // Profile 8 series (standard) - Most commonly used
        'ITEM-30x30-8': { width: 30, height: 30, wallThickness: 2.2, cornerRadius: 2.0, slotWidth: 8, slotDepth: 6.5, standard: 'Item-8' },
        'ITEM-40x40-8': { width: 40, height: 40, wallThickness: 2.5, cornerRadius: 2.5, slotWidth: 8, slotDepth: 7, standard: 'Item-8' },
        'ITEM-40x80-8': { width: 40, height: 80, wallThickness: 2.5, cornerRadius: 2.5, slotWidth: 8, slotDepth: 7, standard: 'Item-8' },
        'ITEM-80x80-8': { width: 80, height: 80, wallThickness: 3.5, cornerRadius: 3.5, slotWidth: 8, slotDepth: 7, standard: 'Item-8' },
        // Additional Profile 8 variants
        'ITEM-40x40-8L': { width: 40, height: 40, wallThickness: 2.2, cornerRadius: 2.5, slotWidth: 8, slotDepth: 7, standard: 'Item-8' }, // Lightweight
        'ITEM-40x40-8D': { width: 40, height: 40, wallThickness: 3.0, cornerRadius: 2.5, slotWidth: 8, slotDepth: 7, standard: 'Item-8' }, // Double-slot
        'ITEM-40x160-8': { width: 40, height: 160, wallThickness: 2.5, cornerRadius: 2.5, slotWidth: 8, slotDepth: 7, standard: 'Item-8' },
        'ITEM-80x120-8': { width: 80, height: 120, wallThickness: 3.5, cornerRadius: 3.5, slotWidth: 8, slotDepth: 7, standard: 'Item-8' },
        'ITEM-80x160-8': { width: 80, height: 160, wallThickness: 3.5, cornerRadius: 3.5, slotWidth: 8, slotDepth: 7, standard: 'Item-8' },

        // Profile 10 series (heavy duty)
        'ITEM-40x40-10': { width: 40, height: 40, wallThickness: 2.5, cornerRadius: 2.5, slotWidth: 10, slotDepth: 7.5, standard: 'Item-10' },
        'ITEM-50x50-10': { width: 50, height: 50, wallThickness: 3.0, cornerRadius: 3.0, slotWidth: 10, slotDepth: 8, standard: 'Item-10' },
        'ITEM-60x60-10': { width: 60, height: 60, wallThickness: 3.5, cornerRadius: 3.5, slotWidth: 10, slotDepth: 8.5, standard: 'Item-10' },

        // Profile 12 series (extra heavy duty)
        'ITEM-60x60-12': { width: 60, height: 60, wallThickness: 4.0, cornerRadius: 4.0, slotWidth: 12, slotDepth: 9, standard: 'Item-12' },
        'ITEM-80x80-12': { width: 80, height: 80, wallThickness: 4.5, cornerRadius: 4.5, slotWidth: 12, slotDepth: 10, standard: 'Item-12' },
        'ITEM-100x100-12': { width: 100, height: 100, wallThickness: 5.0, cornerRadius: 5.0, slotWidth: 12, slotDepth: 11, standard: 'Item-12' },

        // Special profiles
        'ITEM-40x40-8E': { width: 40, height: 40, wallThickness: 2.2, cornerRadius: 2.5, slotWidth: 8, slotDepth: 7, standard: 'Item-8-E' }, // E-specific groove
        'ITEM-40x40-8R': { width: 40, height: 40, wallThickness: 3.0, cornerRadius: 2.5, slotWidth: 8, slotDepth: 7, standard: 'Item-8-R' }, // Reinforced
        'ITEM-40x40-8F': { width: 40, height: 40, wallThickness: 2.5, cornerRadius: 2.5, slotWidth: 8, slotDepth: 7, standard: 'Item-8-F' }  // Clean room
    };

    // Get dimensions for a specific aluminum profile
    public static getDimensions(series: AluProfileSeries, size: string): AluProfileDimensions | null {
        switch (series) {
            case AluProfileSeries.BASIC:
                return this.BASIC_PROFILES[size] || null;
            case AluProfileSeries.LIGHT:
                return this.LIGHT_PROFILES[size] || null;
            case AluProfileSeries.HEAVY:
                return this.HEAVY_PROFILES[size] || null;
            case AluProfileSeries.ECONOMIC:
                return this.ECONOMIC_PROFILES[size] || null;
            case AluProfileSeries.DIN:
                return this.DIN_PROFILES[size] || null;
            case AluProfileSeries.ISO:
                return this.ISO_PROFILES[size] || null;
            case AluProfileSeries.INDUSTRIAL:
                return this.INDUSTRIAL_PROFILES[size] || null;
            case AluProfileSeries.ITEM:
                return this.ITEM_PROFILES[size] || null;
            default:
                return null;
        }
    }

    // Calculate properties for a given aluminum profile
    public static calculateProperties(dimensions: AluProfileDimensions, material: string = "EN AW-6063 T6"): AluProfileProperties {
        const { width, height, wallThickness } = dimensions;

        // Calculate cross-sectional area (simplified calculation)
        const outerArea = width * height;
        const innerArea = (width - 2 * wallThickness) * (height - 2 * wallThickness);
        const area = outerArea - innerArea;

        // Calculate moments of inertia (simplified calculation)
        const Ix = (width * Math.pow(height, 3) / 12) -
            ((width - 2 * wallThickness) * Math.pow(height - 2 * wallThickness, 3) / 12);

        const Iy = (height * Math.pow(width, 3) / 12) -
            ((height - 2 * wallThickness) * Math.pow(width - 2 * wallThickness, 3) / 12);

        // Calculate weight per meter
        const weightPerMeter = area * this.DENSITY / 1000000; // Convert mm² to m²

        return {
            crossSectionArea: area,
            momentOfInertiaX: Ix,
            momentOfInertiaY: Iy,
            weightPerMeter: weightPerMeter,
            material: material
        };
    }

    // Get all available sizes for a given series
    public static getAvailableSizes(series: AluProfileSeries): string[] {
        switch (series) {
            case AluProfileSeries.BASIC:
                return Object.keys(this.BASIC_PROFILES);
            case AluProfileSeries.LIGHT:
                return Object.keys(this.LIGHT_PROFILES);
            case AluProfileSeries.HEAVY:
                return Object.keys(this.HEAVY_PROFILES);
            case AluProfileSeries.ECONOMIC:
                return Object.keys(this.ECONOMIC_PROFILES);
            case AluProfileSeries.DIN:
                return Object.keys(this.DIN_PROFILES);
            case AluProfileSeries.ISO:
                return Object.keys(this.ISO_PROFILES);
            case AluProfileSeries.INDUSTRIAL:
                return Object.keys(this.INDUSTRIAL_PROFILES);
            case AluProfileSeries.ITEM:
                return Object.keys(this.ITEM_PROFILES);
            default:
                return [];
        }
    }

    // Get all available series
    public static getAvailableSeries(): AluProfileSeries[] {
        return Object.values(AluProfileSeries);
    }

    // Get common materials used for aluminum profiles
    public static getCommonMaterials(): string[] {
        return [
            "EN AW-6060 T66",  // Most common, good extrudability
            "EN AW-6063 T6",   // Excellent surface finish
            "EN AW-6005A T6",  // Higher strength
            "EN AW-6082 T6"    // Highest strength in 6000 series
        ];
    }

    // Get standards information
    public static getStandardsInfo(): { [key: string]: string } {
        return {
            'DIN 46277': 'German Industrial Standard for Aluminum Profiles and Mounting Systems',
            'DIN 17615': 'Extruded Precision Profiles in Aluminum Alloys',
            'ISO 14122': 'Safety of machinery - Permanent means of access to machinery',
            'ISO 13920': 'General tolerances for welded constructions',
            'Item-5': 'Item Lightweight Series with 5mm Slot',
            'Item-6': 'Item Standard Series with 6mm Slot',
            'Item-8': 'Item Standard Series with 8mm Slot - Most Common',
            'Item-8-E': 'Item Profile 8 with E-specific Groove for Special Applications',
            'Item-8-R': 'Item Profile 8 Reinforced Version',
            'Item-8-F': 'Item Profile 8 Clean Room Version',
            'Item-10': 'Item Heavy-Duty Series with 10mm Slot',
            'Item-12': 'Item Extra Heavy-Duty Series with 12mm Slot'
        };
    }
} 