import * as THREE from 'three';

/**
 * We store info for each unique vertex in the scene,
 * to avoid outputting duplicates repeatedly.
 */
interface VertexInfo {
    pointID: number;   // IfcCartesianPoint ID
    p: THREE.Vector3;
}

export class IFCExporter {
    private entityID = 1;
    private vertexMap: Map<string, VertexInfo> = new Map();
    private allFaceIDs: number[] = [];
    private faceMeshMap: Map<number, number> = new Map();
    private nextMeshID = 1;

    constructor() { }

    private getNewEntityID(): number {
        return this.entityID++;
    }

    private writeHeader(): string {
        const now = new Date().toISOString().split('.')[0];
        return [
            'ISO-10303-21;',
            'HEADER;',
            "FILE_DESCRIPTION(('ViewDefinition [CoordinationView]'),'2;1');",
            `FILE_NAME('model.ifc','${now}',('',''),(''),'IfcOpenShell 0.0.0','IfcOpenShell 0.0.0','');`,
            "FILE_SCHEMA(('IFC4'));",
            'ENDSEC;',
            'DATA;'
        ].join('\n');
    }

    private writeProjectContext(): string {
        let data = '';

        // Person and Organization
        data += '#1=IFCPERSON($,$,\'\',$,$,$,$,$);\n';
        data += '#2=IFCORGANIZATION($,\'\',$,$,$);\n';
        data += '#3=IFCPERSONANDORGANIZATION(#1,#2,$);\n';
        data += '#4=IFCAPPLICATION(#2,\'1.0 build 0\',\'FreeCAD\',\'118df2cf_ed21_438e_a41\');\n';
        data += '#5=IFCOWNERHISTORY(#3,#4,$,.ADDED.,1735507427,#3,#4,1735507427);\n';

        // Directions and placement
        data += '#6=IFCDIRECTION((1.,0.,0.));\n';
        data += '#7=IFCDIRECTION((0.,0.,1.));\n';
        data += '#8=IFCCARTESIANPOINT((0.,0.,0.));\n';
        data += '#9=IFCAXIS2PLACEMENT3D(#8,#7,#6);\n';
        data += '#10=IFCDIRECTION((0.,1.,0.));\n';

        // Units
        data += '#12=IFCDIMENSIONALEXPONENTS(0,0,0,0,0,0,0);\n';
        data += '#13=IFCSIUNIT(*,.LENGTHUNIT.,$,.METRE.);\n';
        data += '#14=IFCSIUNIT(*,.AREAUNIT.,$,.SQUARE_METRE.);\n';
        data += '#15=IFCSIUNIT(*,.VOLUMEUNIT.,$,.CUBIC_METRE.);\n';
        data += '#16=IFCSIUNIT(*,.PLANEANGLEUNIT.,$,.RADIAN.);\n';
        data += '#17=IFCMEASUREWITHUNIT(IFCPLANEANGLEMEASURE(0.017453292519943295),#16);\n';
        data += '#18=IFCCONVERSIONBASEDUNIT(#12,.PLANEANGLEUNIT.,\'DEGREE\',#17);\n';
        data += '#19=IFCUNITASSIGNMENT((#13,#14,#15,#18));\n';

        // Geometric context
        data += '#20=IFCDIRECTION((0.,1.));\n';
        data += '#21=IFCGEOMETRICREPRESENTATIONCONTEXT($,\'Model\',3,1.E-05,#9,#20);\n';
        data += '#22=IFCGEOMETRICREPRESENTATIONSUBCONTEXT(\'Body\',\'Model\',*,*,*,*,#21,$,.MODEL_VIEW.,$);\n';
        data += '#23=IFCPROJECT(\'2JNoiDg_191BkOMwopUQTM\',#5,\'Model\',$,$,$,$,(#21),#19);\n';

        // Building setup
        data += '#211=IFCBUILDINGELEMENTPROXY(\'0lRvArH0X8XR_Ku4YvXJ7X\',#5,\'Origin\',\'\',$,$,$,$,$);\n';
        data += '#212=IFCBUILDING(\'2HdJ0Gp8XAXxALq5G38oUq\',#5,\'Default Building\',\'\',$,$,$,$,.ELEMENT.,$,$,$);\n';
        data += '#213=IFCRELAGGREGATES(\'31ToDhNQv4U85OGE$f4vfJ\',#5,\'ProjectLink\',\'\',#23,(#212));\n';

        // Style
        data += '#52=IFCCOLOURRGB($,0.447059005498886,0.474510014057159,0.50196099281311);\n';
        data += '#53=IFCSURFACESTYLERENDERING(#52,$,$,$,$,$,$,$,.FLAT.);\n';
        data += '#54=IFCSURFACESTYLE($,.BOTH.,(#53));\n';
        data += '#55=IFCPRESENTATIONSTYLEASSIGNMENT((#54));\n';

        this.entityID = 56;
        return data;
    }

    private processGeometry(geometry: THREE.BufferGeometry, matrix: THREE.Matrix4): string {
        let data = '';
        const meshID = this.nextMeshID++;

        const position = geometry.attributes.position;
        const indices = geometry.index;

        // Transform and collect vertices
        const localVertexInfos: VertexInfo[] = [];

        for (let i = 0; i < position.count; i++) {
            const p = new THREE.Vector3().fromBufferAttribute(position, i).applyMatrix4(matrix);
            const key = `${p.x.toFixed(10)},${p.y.toFixed(10)},${p.z.toFixed(10)}`;

            let vInfo = this.vertexMap.get(key);
            if (!vInfo) {
                const pointID = this.getNewEntityID();
                vInfo = { pointID, p };
                this.vertexMap.set(key, vInfo);
                data += `#${pointID}=IFCCARTESIANPOINT((${p.x},${p.y},${p.z}));\n`;
            }
            localVertexInfos.push(vInfo);
        }

        // Process faces
        if (indices) {
            // Group indices into faces (assuming triangles)
            for (let i = 0; i < indices.count; i += 3) {
                const iA = indices.getX(i);
                const iB = indices.getX(i + 1);
                const iC = indices.getX(i + 2);
                const { data: faceData, faceID } = this.createFace(localVertexInfos, [iA, iB, iC]);
                if (faceID !== -1) {
                    data += faceData;
                    this.allFaceIDs.push(faceID);
                    this.faceMeshMap.set(faceID, meshID);
                }
            }
        } else {
            for (let i = 0; i < localVertexInfos.length; i += 3) {
                if (i + 2 < localVertexInfos.length) {
                    const { data: faceData, faceID } = this.createFace(localVertexInfos, [i, i + 1, i + 2]);
                    if (faceID !== -1) {
                        data += faceData;
                        this.allFaceIDs.push(faceID);
                        this.faceMeshMap.set(faceID, meshID);
                    }
                }
            }
        }

        return data;
    }

    private createFace(vertices: VertexInfo[], idx: number[]): { data: string; faceID: number } {
        let data = '';

        const pA = vertices[idx[0]].p;
        const pB = vertices[idx[1]].p;
        const pC = vertices[idx[2]].p;

        // Compute face normal
        const ab = new THREE.Vector3().subVectors(pB, pA);
        const ac = new THREE.Vector3().subVectors(pC, pA);
        const normal = new THREE.Vector3().crossVectors(ab, ac).normalize();
        if (normal.lengthSq() === 0) {
            return { data: '', faceID: -1 };
        }

        // Create face bound with points directly
        const polyLoopID = this.getNewEntityID();
        data += `#${polyLoopID}=IFCPOLYLOOP((#${vertices[idx[0]].pointID},#${vertices[idx[1]].pointID},#${vertices[idx[2]].pointID}));\n`;

        const faceBoundID = this.getNewEntityID();
        data += `#${faceBoundID}=IFCFACEOUTERBOUND(#${polyLoopID},.T.);\n`;

        const faceID = this.getNewEntityID();
        data += `#${faceID}=IFCFACE((#${faceBoundID}));\n`;

        return { data, faceID };
    }

    private writeShapeRepresentation(): string {
        let data = '';
        if (this.allFaceIDs.length > 0) {
            // Group faces by mesh
            const meshGroups = new Map<number, number[]>();
            for (const [faceID, meshID] of this.faceMeshMap) {
                if (!meshGroups.has(meshID)) {
                    meshGroups.set(meshID, []);
                }
                meshGroups.get(meshID)!.push(faceID);
            }

            const containedElements: number[] = [];

            // Create shape representation for each mesh
            for (const [meshID, faceIDs] of meshGroups) {
                const shellID = this.getNewEntityID();
                data += `#${shellID}=IFCCLOSEDSHELL((${faceIDs.map(id => `#${id}`).join(',')}));\n`;

                const brepID = this.getNewEntityID();
                data += `#${brepID}=IFCFACETEDBREP(#${shellID});\n`;

                const styledItemID = this.getNewEntityID();
                data += `#${styledItemID}=IFCSTYLEDITEM(#${brepID},(#55),$);\n`;

                const shapeRepID = this.getNewEntityID();
                data += `#${shapeRepID}=IFCSHAPEREPRESENTATION(#22,'Body','Brep',(#${brepID}));\n`;

                const prodDefID = this.getNewEntityID();
                data += `#${prodDefID}=IFCPRODUCTDEFINITIONSHAPE($,$,(#${shapeRepID}));\n`;

                const localPlacementID = this.getNewEntityID();
                data += `#${localPlacementID}=IFCLOCALPLACEMENT($,#9);\n`;

                const proxyID = this.getNewEntityID();
                data += `#${proxyID}=IFCBUILDINGELEMENTPROXY('${meshID}',#5,'Part${meshID}','',$,#${localPlacementID},#${prodDefID},$,$);\n`;

                containedElements.push(proxyID);
            }

            // Add spatial structure relationship
            const relID = this.getNewEntityID();
            data += `#${relID}=IFCRELCONTAINEDINSPATIALSTRUCTURE('${relID}',#5,'UnassignedObjectsLink','',(${containedElements.map(id => `#${id}`).join(',')}),#212);\n`;
        }
        return data;
    }

    private writeFooter(): string {
        return [
            'ENDSEC;',
            'END-ISO-10303-21;'
        ].join('\n');
    }

    /**
     * Main parse function that converts the THREE.js scene to IFC format
     */
    public parse(scene: THREE.Scene): string {
        // Reset state
        this.entityID = 1;
        this.vertexMap.clear();
        this.allFaceIDs = [];
        this.faceMeshMap.clear();
        this.nextMeshID = 1;

        let ifc = '';
        ifc += this.writeHeader();
        ifc += this.writeProjectContext();

        // Process geometry
        scene.traverse((obj) => {
            if (obj instanceof THREE.Mesh && obj.geometry) {
                obj.updateMatrixWorld(true);
                ifc += this.processGeometry(obj.geometry, obj.matrixWorld);
            }
        });

        ifc += this.writeShapeRepresentation();
        ifc += this.writeFooter();
        return ifc;
    }

    /**
     * Export the scene as an IFC file
     */
    public export(scene: THREE.Scene, filename = 'model.ifc'): void {
        const ifcData = this.parse(scene);
        const blob = new Blob([ifcData], { type: 'text/plain' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    }
} 