<h3 *ngIf="idstr=='publicExampleID'">Examples</h3>
<hr *ngIf="idstr=='publicExampleID'">
<div style="min-height:500px;">

    <div (click)="upPath(oldpath)" style="margin:2px;;width:100%px;h2eight:100px;cursor:pointer;">
        <div *ngIf="oldpath" class="projectfolder" style="float:left;margin-right:20px;padding:5px 10px;border-radius:8px;"><i style="font-size:20px;" class="fa far fas far fa-arrow-alt-circle-up"></i>
            <!-- {{this.oldpath}} -->
            Folder Up
        </div>
        <div style="padding:5px 10px;font-size:16px;font-weight:bold;">Current Folder: <span *ngIf="!curnode">root</span> {{curnode?.name}}</div>
    </div>
    <div style="clear:both;"></div>
    <div *ngFor="let e of entries; let i = index">
        <!-- {{i}}: -->
        <div class="projectfolder" style="margin:2px;border-radius:8px;padding:20px; w2idth:120px;h2eight:100px;float:left;cursor:pointer;" (click)="this.changePath(e.name,i,e)">
            <!-- <i class="fa far fa-folder"></i> -->
            <img src="/assets/folder.png" style="height:100px;">
            <br>
            <div style="width:100%; text-align:center;font-size:16px;"> {{e.name}}</div>
        </div>
        <!-- <div *ngIf="!e.children" style="margin:2px;border:1px solid blue;width:120px;height:100px;float:left;cursor:pointer;">
            {{e.name}}
        </div> -->
    </div>
    <div style="clear:both;"></div>
    <div *ngFor="let p of curprojects" class="projectfolder" (click)="projectSelected(p)" style="margin:2px;border-radius:8px;width:270px;padding:20px;height:280px;float:left;cursor:pointer;">
        <img [src]="p.image" style="width:200px; height:200px; object-fit:cover; transform:scale(1.4);" />
        <br>
        <div style="width:100%; text-align:center;font-size:14px;margin-top:8px;"> {{p.name}}</div>
    </div>
</div>