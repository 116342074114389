<app-adminnav></app-adminnav>

<div class="container mt-4">
    <!-- Header Section with Search -->
    <div class="row mb-4 align-items-center">
        <div class="col-md-6">
            <h3>Public Projects</h3>
            <div class="text-muted">
                <small *ngIf="curProject?.id">Selected Project: {{curProject?.id}}</small>
                <small *ngIf="curCategoryID" class="ms-3">Selected Category: {{curCategoryID}}</small>
            </div>
        </div>
        <div class="col-md-6">
            <div class="d-flex justify-content-end gap-2">
                <input type="text" class="form-control w-50" placeholder="Search projects..." [(ngModel)]="searchText" (keyup)="filterProjects()">
                <div class="form-check form-switch d-flex align-items-center">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="showHidden" (change)="filterProjects()" id="showHiddenSwitch">
                    <label class="form-check-label ms-2" for="showHiddenSwitch">Show Hidden</label>
                </div>
                <button class="btn btn-outline-primary" (click)="showcategories=!showcategories">
                    <i class="fa fa-folder"></i> Categories
                </button>
                <button class="btn btn-outline-primary" (click)="showexamples=!showexamples">
                    <i class="fa fa-lightbulb"></i> Examples
                </button>
                <button class="btn btn-primary" (click)="save()">
                    <i class="fa fa-save"></i> Save
                </button>
            </div>
        </div>
    </div>

    <!-- Floating Panels -->
    <div [hidden]="!showexamples" class="floating-panel">
        <div class="card">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h5 class="mb-0">Examples</h5>
                <button class="btn-close" (click)="showexamples=false"></button>
            </div>
            <div class="card-body">
                <tree-root [nodes]="examples" (nodeActivate)="node2Activate($event)"></tree-root>
            </div>
        </div>
    </div>

    <div [hidden]="!showcategories" class="floating-panel">
        <div class="card">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h5 class="mb-0">Categories</h5>
                <button class="btn-close" (click)="showcategories=false"></button>
            </div>
            <div class="card-body">
                <tree-root [nodes]="nodes" (select)="select($event)" (activate)="activate($event)" (nodeActivate)="nodeActivate($event)"></tree-root>
            </div>
        </div>
    </div>

    <!-- Projects Table -->
    <div class="table-responsive">
        <table class="table table-hover">
            <thead>
                <tr>
                    <th (click)="sortBy('name')" style="cursor: pointer; width: 200px;">
                        Name
                        <i *ngIf="sortColumn === 'name'" class="fa" [ngClass]="{'fa-sort-up': sortDirection === 'asc', 'fa-sort-down': sortDirection === 'desc'}"></i>
                    </th>
                    <th style="width: 60px;">Preview</th>
                    <th (click)="sortBy('userid')" style="cursor: pointer">
                        User ID
                        <i *ngIf="sortColumn === 'userid'" class="fa" [ngClass]="{'fa-sort-up': sortDirection === 'asc', 'fa-sort-down': sortDirection === 'desc'}"></i>
                    </th>
                    <th>Category</th>
                    <th>Example</th>
                    <th (click)="sortBy('publisdate')" style="cursor: pointer">
                        Publish Date
                        <i *ngIf="sortColumn === 'publisdate'" class="fa" [ngClass]="{'fa-sort-up': sortDirection === 'asc', 'fa-sort-down': sortDirection === 'desc'}"></i>
                    </th>
                    <th style="width: 80px;">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let project of filteredProjects" [class.table-active]="project==curProject" (click)="curProject=project">
                    <td>
                        <div class="d-flex align-items-center gap-2">
                            <a [href]="'/p/'+project.id" class="text-decoration-none">{{project.name}}</a>
                            <a [href]="'/p/'+project.id" target="_blank" class="btn btn-outline-primary btn-sm" (click)="$event.stopPropagation()">
                                <i class="fa fa-external-link"></i>
                            </a>
                        </div>
                    </td>
                    <td>
                        <img [src]="project.image" class="project-thumbnail" alt="Project preview" />
                    </td>
                    <td>{{project.userid}}</td>
                    <td>
                        <div class="d-flex align-items-center gap-2">
                            <span>{{project.publicCategoryID}}</span>
                            <button *ngIf="project.publicCategoryID" class="btn btn-outline-danger btn-sm" (click)="delCategory(project); $event.stopPropagation()">
                                <i class="fa fa-times"></i>
                            </button>
                        </div>
                    </td>
                    <td>
                        <div class="d-flex align-items-center gap-2">
                            <span>{{project.publicExampleID}}</span>
                            <button *ngIf="project.publicExampleID" class="btn btn-outline-danger btn-sm" (click)="delExample(project); $event.stopPropagation()">
                                <i class="fa fa-times"></i>
                            </button>
                        </div>
                    </td>
                    <td>{{project.publisdate | date:'medium'}}</td>
                    <td>
                        <div class="d-flex align-items-center gap-2">
                            <button class="btn btn-sm" [class.btn-outline-secondary]="!project.hidden" [class.btn-secondary]="project.hidden" (click)="toggleProjectVisibility(project, $event)">
                                <i class="fa" [class.fa-eye]="!project.hidden" [class.fa-eye-slash]="project.hidden"></i>
                            </button>
                            <button class="btn btn-outline-danger btn-sm" (click)="deleteProject(project, $event)" title="Delete Project">
                                <i class="fa fa-trash"></i>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<style>
    .floating-panel {
        position: absolute;
        right: 20px;
        top: 130px;
        width: 400px;
        z-index: 1000;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    }

    .project-thumbnail {
        max-height: 40px;
        max-width: 50px;
        object-fit: contain;
    }

    .table-active {
        background-color: rgba(0, 0, 0, 0.075) !important;
    }

    .gap-2 {
        gap: 0.5rem;
    }
</style>