<div class="landing-page">
    <!-- Hero Section -->
    <section class="hero">
        <div class="hero-content">
            <h1>Industrial Hall Configurator</h1>
            <p class="hero-subtitle">Design your perfect industrial space in minutes with our powerful 3D configurator</p>
            <button mat-raised-button color="primary" class="cta-button" (click)="startConfiguration('Industrial')">Start Designing Now</button>
        </div>
    </section>

    <!-- Features Section -->
    <section class="features">
        <h2>Why Choose Our Configurator?</h2>
        <div class="features-grid">
            <div class="feature-card">
                <mat-icon>speed</mat-icon>
                <h3>Fast & Easy</h3>
                <p>Configure your hall in minutes with our intuitive interface</p>
            </div>
            <div class="feature-card">
                <mat-icon>3d_rotation</mat-icon>
                <h3>3D Visualization</h3>
                <p>See your design come to life in real-time 3D</p>
            </div>
            <div class="feature-card">
                <mat-icon>calculate</mat-icon>
                <h3>Precise Calculations</h3>
                <p>Get accurate dimensions and cost estimates instantly</p>
            </div>
        </div>
    </section>

    <!-- Solutions Section -->
    <section class="solutions">
        <h2>Solutions For Every Need</h2>
        <div class="solutions-grid">
            <div class="solution-card">
                <div class="card-image industrial"></div>
                <div class="card-content">
                    <h3>Industrial Halls</h3>
                    <p>Custom manufacturing and production spaces</p>
                    <button mat-raised-button color="primary" (click)="startConfiguration('Industrial')">Configure Industrial Hall</button>
                </div>
            </div>
            <div class="solution-card">
                <div class="card-image storage"></div>
                <div class="card-content">
                    <h3>Storage Facilities</h3>
                    <p>Optimized warehousing and logistics centers</p>
                    <button mat-raised-button color="primary" (click)="startConfiguration('Storage')">Configure Storage Hall</button>
                </div>
            </div>
            <div class="solution-card">
                <div class="card-image production"></div>
                <div class="card-content">
                    <h3>Production Halls</h3>
                    <p>Efficient spaces for manufacturing operations</p>
                    <button mat-raised-button color="primary" (click)="startConfiguration('Production')">Configure Production Hall</button>
                </div>
            </div>
        </div>
    </section>

    <!-- Call to Action Section -->
    <section class="cta-section">
        <div class="cta-content">
            <h2>Ready to Start Your Project?</h2>
            <p>Create your custom hall design in minutes with our advanced configurator</p>
            <button mat-raised-button color="primary" class="cta-button" (click)="startConfiguration('Industrial')">Begin Configuration</button>
        </div>
    </section>
</div>