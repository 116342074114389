import * as THREE from 'three';
import JSZip from 'jszip';

export class ThreeMFExporter {
    constructor() { }

    async parse(scene: THREE.Scene): Promise<Blob> {
        const zip = new JSZip();

        // Create the 3D model XML content
        let modelXml = this.create3DModelXml(scene);
        zip.file('3D/3dmodel.model', modelXml);

        // Create the content types XML
        const contentTypesXml = `<?xml version="1.0" encoding="UTF-8"?>
<Types xmlns="http://schemas.openxmlformats.org/package/2006/content-types">
    <Default Extension="rels" ContentType="application/vnd.openxmlformats-package.relationships+xml" />
    <Default Extension="model" ContentType="application/vnd.ms-package.3dmanufacturing-3dmodel+xml" />
</Types>`;
        zip.file('[Content_Types].xml', contentTypesXml);

        // Create the relationships file
        const relsXml = `<?xml version="1.0" encoding="UTF-8"?>
<Relationships xmlns="http://schemas.openxmlformats.org/package/2006/relationships">
    <Relationship Type="http://schemas.microsoft.com/3dmanufacturing/2013/01/3dmodel" Target="/3D/3dmodel.model" Id="rel0" />
</Relationships>`;
        zip.file('_rels/.rels', relsXml);

        // Generate the 3MF file as a blob
        return await zip.generateAsync({ type: 'blob' });
    }

    private create3DModelXml(scene: THREE.Scene): string {
        let meshId = 1;
        let objectId = 1;
        const meshes: string[] = [];
        const objects: string[] = [];
        const processedGeometries = new Map<string, number>();

        scene.traverse((object) => {
            if (object instanceof THREE.Mesh) {
                const geometry = object.geometry;
                const material = object.material;
                let currentMeshId: number;

                // Check if we've already processed this geometry
                const geometryKey = this.getGeometryKey(geometry);
                if (processedGeometries.has(geometryKey)) {
                    currentMeshId = processedGeometries.get(geometryKey)!;
                } else {
                    currentMeshId = meshId++;
                    processedGeometries.set(geometryKey, currentMeshId);

                    // Process vertices and triangles
                    const vertices: THREE.Vector3[] = [];
                    const triangles: number[] = [];

                    if (geometry instanceof THREE.BufferGeometry) {
                        const position = geometry.attributes.position;
                        const indices = geometry.index;

                        // Get vertices
                        for (let i = 0; i < position.count; i++) {
                            vertices.push(new THREE.Vector3().fromBufferAttribute(position, i));
                        }

                        // Get triangles
                        if (indices) {
                            for (let i = 0; i < indices.count; i += 3) {
                                triangles.push(indices.getX(i), indices.getX(i + 1), indices.getX(i + 2));
                            }
                        } else {
                            for (let i = 0; i < position.count; i += 3) {
                                triangles.push(i, i + 1, i + 2);
                            }
                        }
                    }

                    // Create mesh XML
                    let meshXml = `<mesh>
    <vertices>
${vertices.map((v, i) => `        <vertex x="${v.x}" y="${v.y}" z="${v.z}" />`).join('\n')}
    </vertices>
    <triangles>
${Array.from({ length: triangles.length / 3 }, (_, i) => {
                        const idx = i * 3;
                        return `        <triangle v1="${triangles[idx]}" v2="${triangles[idx + 1]}" v3="${triangles[idx + 2]}" />`;
                    }).join('\n')}
    </triangles>
</mesh>`;
                    meshes.push(`    <object id="${currentMeshId}" type="model">${meshXml}</object>`);
                }

                // Create object (instance) XML with transformation
                const matrix = object.matrixWorld.elements;
                objects.push(`    <item objectid="${currentMeshId}" transform="${matrix.join(' ')}" />`);
                objectId++;
            }
        });

        return `<?xml version="1.0" encoding="UTF-8"?>
<model unit="millimeter" xmlns="http://schemas.microsoft.com/3dmanufacturing/core/2015/02">
    <resources>
${meshes.join('\n')}
    </resources>
    <build>
${objects.join('\n')}
    </build>
</model>`;
    }

    private getGeometryKey(geometry: THREE.BufferGeometry): string {
        const position = geometry.attributes.position;
        return Array.from(position.array).join(',');
    }

    public async export(scene: THREE.Scene): Promise<void> {
        const blob = await this.parse(scene);
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'model.3mf';
        link.click();
    }
} 