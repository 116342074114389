<!-- <h1 mat-dialog-title>Publish</h1> -->
<div mat-dialog-content>

    <!-- <mat-form-field appearance="fill">
        <mat-label>Favorite Animal</mat-label>
        <input matInput [(ngModel)]="data.animal">
    </mat-form-field>
     -->

    <style>
        .publish-dialog-content {
            height: 100%;
            min-height: 400px;
            position: relative;
            width: 900px;
            overflow: auto;
        }

        .mat-dialog-content {
            max-height: 100vh !important;
        }

        .dialogbottom button {
            background-color: #9f26f0;
            color: white;
            float: right;
            /* right: 100px; */
        }
    </style>

    <mat-stepper [linear]="false" #stepper>
        <mat-step>
            <ng-template matStepLabel>General</ng-template>

            <div class="publish-dial2og-content" style="overflow:hidden">

                <!-- <h3>Appearance</h3> -->
                <hr>
                <div class="row" style="    height: 377px;   position: relative;   ">
                    <div class="col-md-4" style="padding-top:1px;">

                        <!-- <h5>Accent Color</h5>
                        <input class="cpinput form-control" style="width:100px;margin-bottom:25px;" name="test" [value]="color1" [style.background]="color1" [(colorPicker)]="color1" (chcolorPickerChangeange)="onChange()"
                            [(ngModel)]="project.accentcolor" /> -->

                        <label for="frmname">Name</label>
                        <input type="text" name="frmname" class="form-control" [(ngModel)]="project.name" placeholder="Name" />
                        <label for="frmkeywords" style="padding-top:8px;">Keywords</label>
                        <input type="text" name="frmkeywords" class="form-control" [(ngModel)]="project.keywords" placeholder="Keywords comma separated" />

                        <label style="padding-top:8px">Image</label><br>
                        <img [src]="project.image" style="height:150px;margin-top:25px;" />



                    </div>
                    <div class="col-md-8" style="padding-top:1px;">
                        <mat-tab-group>
                            <mat-tab label="Description">

                                <div style="position:relative;height:370px;">
                                    <angular-editor [config]="editorConfig" [placeholder]="'Enter description here...'" [(ngModel)]="project.description"></angular-editor>
                                </div>

                                <!-- <h5>Desprition</h5> -->
                            </mat-tab>
                            <mat-tab label="Disclaimer">

                                <!-- <h5>Disclaimer</h5> -->
                                <div style="position:relative;height:370px;">
                                    <angular-editor [config]="editorConfig" [placeholder]="'Enter disclaimer here...'" [(ngModel)]="project.disclaimer"></angular-editor>
                                </div>
                            </mat-tab>
                        </mat-tab-group>


                        <!-- <h5>Preview</h5>
                        <img src="https://firebasestorage.googleapis.com/v0/b/xbuild3d.appspot.com/o/thumbnails%2FGSwhlqz2yCgGgH5LnvY4.png?alt=media&token=d583c691-a8f4-4b0f-af51-0aa407602fbd" style="height:120px;" />
                        <br><button>Upload</button> -->

                    </div>

                </div>

            </div>


            <hr>
            <div class="dialogbottom">
                <button mat-button matStepperNext>Next</button>
            </div>

        </mat-step>


        <!----------- Handling TAB -------------  -->
        <mat-step>
            <ng-template matStepLabel>Behaviour</ng-template>

            <div class="publish-dialog-content" style="overflow:hidden;max-height:350px;">

                <hr>
                <div class="row">
                    <div class="col-md-6" style="padding-top:25px;">
                        <h4>Camera</h4>
                        <mat-checkbox [(ngModel)]="project.settings.cameraPan">Enable Pan</mat-checkbox><br>
                        <mat-checkbox [(ngModel)]="project.settings.cameraZoom">Enable Zoom</mat-checkbox><br>
                        <mat-checkbox [(ngModel)]="project.settings.cameraZoomPointer">Zoom on Pointer</mat-checkbox><br>

                        <h5>Initial Camera</h5>
                        <select style="margin-bottom:25px;width:200px;" class="form-control" [(ngModel)]="project.initialcamera">
                            <option *ngFor="let cam of project.cameras; let i=index" [value]="cam">Camera {{i}}</option>
                        </select>

                        <!-- https://www.npmjs.com/package/@kolkov/angular-editor -->
                        <h5>Other</h5>
                        <mat-checkbox [(ngModel)]="project.animate" style="margin-bottom:25px;">animate scene (CPU/GPU intensive)</mat-checkbox>

                    </div>


                    <div class="col-md-6" style="padding-top:25px;">

                        <h4>Handling</h4>
                        <mat-checkbox [(ngModel)]="project.settings.subprojectselectable">user can select subprojects</mat-checkbox><br>
                        <mat-checkbox [(ngModel)]="project.settings.subprojectmouseover">box on subproject mouse over</mat-checkbox><br><br>
                        <div>
                            <label>Box color</label>
                            <input [(ngModel)]="project.settings.subprojectmouseovercolor" class="cpinput form-control" style="width:100px;" name="test" [value]="color1" [style.background]="color1"
                                [(colorPicker)]="color1" (chcolorPickerChangeange)="onChange()" />
                        </div>


                    </div>

                </div>

            </div>

            <hr>


            <div class="dialogbottom">
                <button mat-button matStepperNext>Next</button>
            </div>

        </mat-step>

        <mat-step>
            <ng-template matStepLabel>Configurations</ng-template>

            <div class="publish-dialog-content" style="overflow:hidden;">
                <hr>
                <div class="row">
                    <div class="col-md-6" style="padding-top:25px;">
                        <div>
                            <h4>Save Configurations</h4><br>
                            <mat-checkbox disabled>Export Configuration as Step File</mat-checkbox><br>

                            <mat-checkbox style="margin-top:10px;" [(ngModel)]="project.usercansave">User can save Configuration</mat-checkbox><br>
                            <div>
                                <div>User Form Data:</div>
                                <mat-checkbox style="margin-right:20px;" [(ngModel)]="project.userformmail">E-mail</mat-checkbox><br>
                                <mat-checkbox style="margin-right:20px;" [(ngModel)]="project.userformname">Name</mat-checkbox><br>

                                <mat-checkbox style="margin-right:20px;" [(ngModel)]="project.userformphone">Phone</mat-checkbox><br>
                                <mat-checkbox style="margin-right:20px;" [(ngModel)]="project.userformcompany">Company</mat-checkbox><br>

                                <mat-checkbox [(ngModel)]="project.userformcomment">Comment</mat-checkbox><br>
                            </div>

                        </div>

                    </div>
                    <div class="col-md-6" style="padding-top:25px;">


                        <!-- <mat-form-field appearance="fill">
                            <mat-label>Send E-Mails to:</mat-label>
                            <input matInput formControlName="secondCtrl" placeholder="your@mail.com" required>
                        </mat-form-field><br>

                        <mat-form-field appearance="fill">
                            <mat-label>Send E-Mails to:</mat-label>
                            <input matInput formControlName="secondCtrl2" placeholder="your@mail.com" required>
                        </mat-form-field><br>
                        <mat-form-field>
                            <input matInput placeholder="name" value="value">
                        </mat-form-field>
                        <br>
                        <mat-form-field>
                            <input matInput placeholder="logo" value="value">
                        </mat-form-field> -->

                        <h4>E-Mail</h4>
                        <mat-checkbox [(ngModel)]="project.userformsendmail">Send Configuration Mail</mat-checkbox><br>

                        <label>E-Mail Subject</label>
                        <input [(ngModel)]="project.usermailsubject" style="margin-bottom:14px;" class="form-control" type="text" />
                        <label>E-Mail Text</label>
                        <textarea [(ngModel)]="project.usermailtext" style="height:150px;" class="form-control"></textarea>


                    </div>
                </div>
            </div>

            <hr>
            <div class="dialogbottom">
                <button mat-button matStepperNext>Next</button>
            </div>

        </mat-step>


        <mat-step>
            <ng-template matStepLabel>Finish</ng-template>
            <div class="publish-dialog-content">
                <hr>
                <div style="text-align:center; width:100%">

                    <div *ngIf="step==1">

                        <div style="margin-bottom:10px;margin-top:30px;">New Public Project will be created. </div>
                        <div style="margin-bottom:10px;">
                            Or Replace existing Public Project:
                        </div>
                        <select style="width:340px;margin:auto;" class="form-control" [(ngModel)]="replaceid">
                            <option value="null"></option>
                            <option *ngFor="let p of publishedprojects" [value]="p.id">{{p.name }} <span style="color:gray; font-size:12px"></span>(ID: {{p.id}})</option>
                        </select>
                        <div class="dialogbottom" style="margin-top:20px;width:100%;text-align:center;">
                            <button mat-button style="float:none;" (click)="publish()">Publish now!</button>
                            <br>
                            <mat-checkbox [(ngModel)]="project.private" [checked]="!project.private" disabled style="margin-top:25px;">publish to open library</mat-checkbox>
                            <br> <span style="font-size:12px; color:gray;">(you are in free tier)</span>
                        </div>

                    </div>
                    <div *ngIf="step==2" class="flex justify-center items-center flex-col">
                        <h2>Publishing project...</h2>
                        <div class="flex justify-center w-full">
                            <img *ngIf="true" src="/assets/loading-m.png" class="mt-48 mx-auto">
                        </div>
                    </div>




                    <div *ngIf="step==3">
                        <h1 class="text-3xl font-bold">Project successfully published!</h1>
                        <div class="flex justify-center items-center">
                            <img src="/assets/92460-checkmark-animation.gif" alt="" style="width:150px;margin:20px 30px;text-align:center;" />
                        </div>

                        <h3 class="text-1xl font-bold">
                            <a target="_blank" [href]="'/p/'+newproject.id" style="text-decoration: underline;">Visit Public Project
                                <mat-icon>open_in_new</mat-icon>
                            </a>

                        </h3>

                        <div style="height:30px;"></div>

                        <h4>Share:</h4>
                        <share-buttons [theme]="'material-dark'" [include]="['facebook','twitter','linkedin','reddit']" [show]="5" [showText]="true" [url]="'https://app.sheetbuild.com/p/'+newproject.id"
                            [autoSetMeta]="false"></share-buttons>

                        <!-- <share-buttons theme="material-dark" image="https://loremflickr.com/320/240"></share-buttons> -->


                        <!-- <a href="" (click)="publishtopublicdatabase()">publish also to database of sheetBuild</a><br> -->
                    </div>
                </div>

                <!-- <div class="dialogbottom">
                <button mat-button matStepperPrevious>Back</button>
                <button mat-button (click)="stepper.reset()">Reset</button>
            </div> -->

            </div>

            <hr>

            <div *ngIf="step==3" class="dialogbottom" style="width:100%;text-align:center;">
                <!-- <a mat-button target="_blank" [href]="'/p/'+newproject.id">Visit Public Project</a> -->
                <!-- <button mat-button style="float:none;" (click)="'window.open(/p/'+newproject+'.id')">Open Public Poject</button> -->
            </div>
        </mat-step>
    </mat-stepper>

</div>
<!-- <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">No Thanks</button>
    <button mat-button cdkFocusInitial>Ok</button>
</div> -->