import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-parallel-ai-dialog',
  template: `
    <h2 mat-dialog-title>Parallel AI Runs</h2>
    <mat-dialog-content>
      <div style="margin-bottom: 10px">
        <mat-radio-group [(ngModel)]="data.mode" style="display: flex; flex-direction: column; gap: 10px;">
          <mat-radio-button value="models">Run same prompt across different models</mat-radio-button>
          <mat-radio-button value="prompts">Run multiple prompts with Claude</mat-radio-button>
        </mat-radio-group>
      </div>

      <mat-form-field style="width: 100%">
        <textarea matInput [(ngModel)]="data.prompt" [placeholder]="getPromptPlaceholder()" rows="5"></textarea>
      </mat-form-field>

      <div style="margin-top: 10px" *ngIf="data.mode === 'models'">
        <mat-checkbox [(ngModel)]="data.models.claude">Claude</mat-checkbox>
        <mat-checkbox [(ngModel)]="data.models.gemini">Gemini</mat-checkbox>
        <mat-checkbox [(ngModel)]="data.models.deepseek">DeepSeek</mat-checkbox>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button (click)="onCancel()">Cancel</button>
      <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="!isValid()">Run</button>
    </mat-dialog-actions>
  `
})
export class ParallelAiDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ParallelAiDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      mode: 'models' | 'prompts';
      prompt: string;
      models: {
        claude: boolean;
        gemini: boolean;
        deepseek: boolean;
      };
    }
  ) { }

  getPromptPlaceholder(): string {
    return this.data.mode === 'prompts'
      ? 'Enter multiple prompts (one per line)'
      : 'Enter your prompt';
  }

  isValid(): boolean {
    if (!this.data.prompt) return false;

    if (this.data.mode === 'models') {
      return this.data.models.claude || this.data.models.gemini || this.data.models.deepseek;
    } else {
      // For prompts mode, ensure there's at least one non-empty line
      return this.data.prompt.split('\n').some(line => line.trim().length > 0);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.dialogRef.close(this.data);
  }
} 