exports.nodes = {
    root: [
        {
            "id": "buildings-architecture",
            "name": "Buildings & Architecture",
            "children": [
                {
                    "id": "building-components",
                    "name": "Building Components"
                },
                {
                    "id": "interior",
                    "name": "Interior"
                },
                {
                    "id": "exterior",
                    "name": "Exterior"
                },
                {
                    "id": "industrial",
                    "name": "Industrial"
                },



                //     {
                //         "id": "buildings-house",
                //         "name": "House",
                //         // "children": [
                //         //     {
                //         //         "id": "buildings-house-residential",
                //         //         "name": "Residential"
                //         //     },
                //         //     {
                //         //         "id": "buildings-house-garage",
                //         //         "name": "Garage"
                //         //     }
                //         // ]

            ]
        },
        {
            "id": "machines",
            "name": "Machines",
        },
        {
            "id": "machine-parts",
            "name": "Machine Parts",
            // "children": [
            //     {
            //         "id": "bearings",
            //         "name": "Bearings",
            //         // "children": [
            //         //     {
            //         //         "id": "bearings-ball",
            //         //         "name": "Ball Bearings"
            //         //     },
            //         //     {
            //         //         "id": "bearings-roller",
            //         //         "name": "Roller Bearings"
            //         //     },
            //         //     {
            //         //         "id": "bearings-thrust",
            //         //         "name": "Thrust Bearings"
            //         //     }
            //         // ]
            //     },
            //     {
            //         "id": "profiles",
            //         "name": "Profiles",
            //         // "children": [
            //         //     {
            //         //         "id": "profiles-round",
            //         //         "name": "Round"
            //         //     },
            //         //     {
            //         //         "id": "profiles-squared",
            //         //         "name": "Squared"
            //         //     },
            //         //     {
            //         //         "id": "profiles-ibeam",
            //         //         "name": "I-Beam"
            //         //     },
            //         //     {
            //         //         "id": "profiles-channel",
            //         //         "name": "Channel"
            //         //     }
            //         // ]
            //     },
            //     {
            //         "id": "connections",
            //         "name": "Connections",
            //         // "children": [
            //         //     {
            //         //         "id": "connections-profiles",
            //         //         "name": "Profile connections"
            //         //     },
            //         //     {
            //         //         "id": "connections-other",
            //         //         "name": "Other"
            //         //     },
            //         //     {
            //         //         "id": "connections-bolts",
            //         //         "name": "Bolts"
            //         //     },
            //         //     {
            //         //         "id": "connections-welds",
            //         //         "name": "Welds"
            //         //     }
            //         // ]
            //     },

            // ]
        },
        {
            "id": "science",
            "name": "Science",
            "children": [
                {
                    "id": "chemistry",
                    "name": "Chemistry",
                    // "children": [
                    //     {
                    //         "id": "molecules-organic",
                    //         "name": "Organic"
                    //     },
                    //     {
                    //         "id": "molecules-inorganic",
                    //         "name": "Inorganic"
                    //     }
                    // ]
                },

                {
                    "id": "physics",
                    "name": "Physics",

                }
            ]
        },
        {
            "id": "tools",
            "name": "Tools",
            // "children": [
            //     {
            //         "id": "tools-measurement",
            //         "name": "Measurement",
            //         "children": [
            //             {
            //                 "id": "tools-measurement-calipers",
            //                 "name": "Calipers"
            //             },
            //             {
            //                 "id": "tools-measurement-gauges",
            //                 "name": "Gauges"
            //             }
            //         ]
        },


    ]
},
{
    "id": "other",
    "name": "Other"
}


// ]
//};