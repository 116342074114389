<div [hidden]="!showAIPromptEditor" cdkDrag class="box-shadow dialogwindow"
    style="padding: 1px 3px 10px 3px; position: absolute; z-index: 999; width: 600px; left: 100px; border: 1px solid; color:black; border-radius: 2px; display: flex; flex-direction: column;">
    <div cdkDragHandle style="width:100%;background:rgb(88, 2, 101);height:24px;color:white;">
        <div style="float: left;padding: 2px 11px;font-size: 13px;">AI Prompt Editor</div>
        <div style="padding: 0px 9px;float:right;cursor:pointer;" (click)="showAIPromptEditor=false">
            <i class="fa-solid fa-xmark"></i>
        </div>
    </div>
    <button mat-mini-fab color="primary" (click)="addTab()" style="position: absolute; z-index: 9999; top: 25px; right: 5px; width: 30px; height: 30px; min-height: 0; line-height: 30px;">
        <i class="fa-solid fa-plus" style="font-size: 12px;"></i>
    </button>
    <div style="position: absolute; top: 25px; right: 40px; display: flex; align-items: center; gap: 10px;">
        <mat-form-field style="width: 100px; margin-right: 10px;z-index: 9999;">
            <mat-select [(ngModel)]="aiProvider">
                <mat-option value="claude" matTooltip="Use Claude AI">
                    <i class="fa-solid fa-robot"></i> Claude
                </mat-option>
                <mat-option value="openai" matTooltip="Use OpenAI">
                    <i class="fa-solid fa-brain"></i> OpenAI
                </mat-option>
                <mat-option value="gemini" matTooltip="Use Gemini">
                    <i class="fa-solid fa-microchip"></i> Gemini
                </mat-option>
                <mat-option value="deepseek" matTooltip="Use DeepSeek">
                    <i class="fa-solid fa-atom"></i> DeepSeek
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-slider min="0" max="1" step="0.1" style="width: 100px;" [style.display]="aiProvider === 'openai' ? 'block' : 'none'">
            <input matSliderThumb [(ngModel)]="temperature" matTooltip="Temperature">
        </mat-slider>
    </div>
    <mat-tab-group class="no-animation" [selectedIndex]="currentTabId" (selectedIndexChange)="onTabChange($event)" [@.disabled]="true" style="flex: 1;">


        <mat-tab *ngFor="let tab of tabs" [label]="'Prompt ' + tab.id">
            <ng-template mat-tab-label>
                <span style="color: white;">Prompt {{ tab.id }}</span>
            </ng-template>
            <div class="tabpage">

                <!-- <h2>AI Prompt {{tab.id}}</h2> -->
                <form (ngSubmit)="onAISubmit()">
                    <div style="position: relative;">
                        <textarea #promptTextarea id="context" style="width: 100%; min-height: 100px; max-height:  70vh; resize: vertical;" [(ngModel)]="tab.prompt" (input)="adjustTextareaHeight($event)"
                            name="context"></textarea>
                        <button mat-mini-fab style="position: absolute; bottom: 5px; right: 5px;" color="primary" (click)="$event.preventDefault(); getPrompt(tab.prompt)" matTooltip="AI Wizard"><i
                                class="fa-solid fa-wand-magic-sparkles"></i></button>
                    </div>
                    <button type="submit" mat-raised-button color="primary" style="margin-top:5px;float:right;margin-right:10px;">Submit</button>
                </form>

                <!-- Chat History -->
                <div class="chat-history" style="margin-top: 20px; max-height: 300px; overflow-y: auto; border: 1px solid #ddd; border-radius: 4px; padding: 10px;">
                    <div *ngFor="let message of tab.chatHistory" [ngClass]="{'user-message': message.role === 'user', 'assistant-message': message.role === 'assistant'}"
                        style="margin-bottom: 10px; padding: 8px; border-radius: 4px;">
                        <strong>{{ message.role === 'user' ? 'You' : 'Assistant' }}:</strong>
                        <div style="white-space: pre-wrap;">{{ message.content }}</div>
                    </div>
                </div>

                <!-- Add Handsontable container -->
                <!-- <div [id]="'hotContainer'"></div>
                 -->
                <!-- <div #hotContainer style="height: 200px;width:200px; margin-top: 20px;background:red;"></div> -->

                <div *ngIf="isLoading" class="loading-overlay">
                    <div class="loading-spinner"></div>
                    <p class="loading-text">{{loadingText}}</p>
                </div>

                <div style="padding:5px;    width: 100%;">
                    <div class="screenshot-container" style="float:left;margin-right:10px;">
                        <button mat-mini-fab style="margin-right:5px;" color="primary" (click)="addScreenshot('model')" matTooltip="Take screenshot of model"><i class="fa-solid fa-image"></i></button>
                        <button mat-mini-fab style="margin-right:5px;" color="primary" (click)="addTableMarking('formulas')" matTooltip="Add table formulas"><i class="fa-solid fa-table"></i></button>
                        <button mat-mini-fab mat-raised-button style="margin-right:5px;" color="primary" (click)="addImage()"><i class="fa-solid fa-file-upload"></i></button>
                        <button mat-mini-fab style="margin-right:5px;" color="primary" (click)="testCheckImageContent()" matTooltip="Test Image Content"><i class="fa-solid fa-vial"></i></button>
                        <button mat-raised-button style="margin-right:5px;right:100px;position:absolute;" color="primary" (click)="do5runs()">5x <i class="fa-solid fa-play"></i></button>
                        <!-- <button class="btn btn-secondary btn-sm btn-xs" [matMenuTriggerFor]="screenshotMenu" style="padding: 2px 4px; font-size: 12px;">
                            <mat-icon style="font-size: 16px; height: 16px; width: 16px; line-height: 16px;">add</mat-icon>
                        </button>
                        <mat-menu #screenshotMenu="matMenu">
                            <button mat-menu-item (click)="addScreenshot('model')">Screenshot Model</button>
                            <button mat-menu-item (click)="addTableMarking('values')">Table Values</button>
                            <button mat-menu-item (click)="addTableMarking('formulas')">Table Formulas</button>
                            <button mat-menu-item (click)="addImage()">Upload Image</button>
                        </mat-menu> -->
                    </div>
                    <!-- <div class="table-marking-container">
                        <button class="btn btn-secondary btn-sm" [matMenuTriggerFor]="tableMarkingMenu">
                            <i class="fa fa-table"></i> Table Marking
                            <mat-icon>arrow_drop_down</mat-icon>
                        </button>
                        <mat-menu #tableMarkingMenu="matMenu">
                        </mat-menu>
                    </div> -->


                    <div class="attachments-container">

                        <!-- <h4>Attachments</h4> -->
                        <div class="attachment-row">
                            <span *ngFor="let attachment of tab.attachments; let i = index" class="badge bg-secondary me-2 mb-2 mr-1">
                                {{ attachment.type }} ({{ attachment.index }})
                                <span class="image-popup-container">
                                    <img class="thumbnail" [src]="attachment.data" alt="Attachment thumbnail" />
                                    <img class="popup-image" [src]="attachment.data" alt="Attachment full size" />
                                </span>
                                {{attachment.excelRange}}
                                <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="removeScreenshot(i)">
                                    <i class="fa-solid fa-trash"></i>
                                </button>
                            </span>
                        </div>
                    </div>


                    <div class="feedback-loop-container" style="margin-top: -5px;">
                        <!-- <mat-checkbox [(ngModel)]="feedbackLoop" class="feedback-loop-checkbox">
                            Feedback loop
                        </mat-checkbox> -->
                        <mat-form-field *ngIf="feedbackLoop" class="iterations-input" style="width: 100px; margin-left: 10px;">
                            <input matInput type="number" [(ngModel)]="iterations" placeholder="Iterations" min="1">
                        </mat-form-field>
                    </div>

                    <!-- <div *ngIf="response" class="response" style="position:absolute;bottom:10px;right:10px;">
                        <h3>Claude's Response:</h3>
                        <pre>{{ response }}</pre>
                    </div> -->

                </div>
            </div>
        </mat-tab>
    </mat-tab-group>



    <!-- <div style="height: 300px; margin-top: 80px; display:none;">
        <hot-table #hot style="display:block;height:300px;width:100%;zoom:0.7" [height]="300" [width]="900" [autoColumnSize]="true" [outsideClickDeselects]="false" [manualColumnResize]="true" [rowHeaders]="true"
            [data]="currentTabData" [renderer]="'customStylesRenderer'" [colHeaders]="true" [settings]="hotsettings">
        </hot-table>
    </div> -->
    <!-- <button mat-raised-button color="primary" style="position: relative; top: 5px; left: 10px;" (click)="add2Sheet()"><i class="fa-solid fa-copy"></i> apply</button> -->



    <!-- <button mat-raised-button color="primary" (click)="checkResult()" style="position: absolute; bottom: 10px; left: 120px;">
        Check Result
    </button> -->


</div>