import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as THREE from 'three';
import { CADNodeEngine } from '../editor/cadnodeengine';

interface AISuggestion {
    type: 'error' | 'warning' | 'optimization';
    cell?: string;
    message: string;
    suggestedValue?: string;
    explanation: string;
    category: 'geometry' | 'material' | 'performance' | 'structure' | 'system';
}

@Component({
    selector: 'app-ai-agent',
    templateUrl: './ai-agent.component.html',
    styleUrls: ['./ai-agent.component.scss']
})
export class AIAgentComponent implements OnInit {
    @Input() excelData: any[][];
    @Input() cadEngine: CADNodeEngine;
    @Input() scene: THREE.Scene;
    @Output() suggestionApplied = new EventEmitter<{ cell: string, value: string }>();
    @Output() windowClosed = new EventEmitter<void>();
    @Output() windowMinimized = new EventEmitter<void>();

    suggestions: AISuggestion[] = [];
    isAnalyzing = false;
    isEnabled = true;
    showExplanations = true;
    isMinimized = false;

    private readonly SERVER_URL = 'https://us-central1-xbuild3d.cloudfunctions.net';

    constructor(private http: HttpClient) { }

    ngOnInit() {
        if (this.isEnabled && this.excelData) {
            this.analyzeModel();
        }
    }

    async analyzeModel() {
        this.isAnalyzing = true;
        this.suggestions = [];

        try {
            // Prepare scene data
            const sceneData = this.prepareSceneData();

            // Call backend analysis endpoint
            const response = await this.http.post(`${this.SERVER_URL}/analyzeModel`, {
                excelData: this.excelData,
                sceneData: sceneData
            }).toPromise();

            const result = response as any;
            if (result.success && result.suggestions) {
                this.suggestions = result.suggestions;
            }

            // Sort suggestions by priority
            this.suggestions.sort((a, b) => {
                const priority = { error: 0, warning: 1, optimization: 2 };
                return priority[a.type] - priority[b.type];
            });

        } catch (error) {
            console.error('Error analyzing model:', error);
            // Add a user-friendly error suggestion
            this.suggestions.push({
                type: 'error',
                category: 'system',
                message: 'Error analyzing model',
                explanation: 'There was an error analyzing your model. Please try again later.',
            });
        } finally {
            this.isAnalyzing = false;
        }
    }

    private prepareSceneData() {
        if (!this.scene) return null;

        const sceneData: any = {
            objects: [],
            materials: [],
            stats: {
                totalVertices: 0,
                totalTriangles: 0,
                objectCount: 0
            }
        };

        this.scene.traverse((object) => {
            if (object instanceof THREE.Mesh) {
                const geometry = object.geometry;
                const material = object.material;

                // Get object data
                const objectData = {
                    name: object.name,
                    type: object.type,
                    position: object.position.toArray(),
                    rotation: object.rotation.toArray(),
                    scale: object.scale.toArray(),
                    geometry: {
                        type: geometry.type,
                        vertices: geometry instanceof THREE.BufferGeometry ? geometry.attributes.position?.count || 0 : 0,
                        triangles: geometry instanceof THREE.BufferGeometry ?
                            (geometry.index ? geometry.index.count / 3 : geometry.attributes.position.count / 3) : 0
                    },
                    material: {
                        type: material.type,
                        color: material instanceof THREE.MeshBasicMaterial ? material.color.getHexString() : null,
                        transparent: material.transparent,
                        opacity: material.opacity
                    },
                    boundingBox: new THREE.Box3().setFromObject(object).min.toArray().concat(
                        new THREE.Box3().setFromObject(object).max.toArray()
                    )
                };

                sceneData.objects.push(objectData);
                sceneData.stats.totalVertices += objectData.geometry.vertices;
                sceneData.stats.totalTriangles += objectData.geometry.triangles;
                sceneData.stats.objectCount++;

                // Add unique materials
                if (!sceneData.materials.find(m => m.type === material.type)) {
                    sceneData.materials.push({
                        type: material.type,
                        properties: Object.keys(material)
                    });
                }
            }
        });

        return sceneData;
    }

    applySuggestion(suggestion: AISuggestion) {
        if (suggestion.cell && suggestion.suggestedValue) {
            this.suggestionApplied.emit({
                cell: suggestion.cell,
                value: suggestion.suggestedValue
            });
            this.suggestions = this.suggestions.filter(s => s !== suggestion);
        }
    }

    ignoreSuggestion(suggestion: AISuggestion) {
        this.suggestions = this.suggestions.filter(s => s !== suggestion);
    }

    toggleAgent() {
        this.isEnabled = !this.isEnabled;
        if (this.isEnabled) {
            this.analyzeModel();
        } else {
            this.suggestions = [];
        }
    }

    toggleExplanations() {
        this.showExplanations = !this.showExplanations;
    }

    minimizeWindow() {
        this.isMinimized = true;
        this.windowMinimized.emit();
    }

    closeWindow() {
        this.windowClosed.emit();
    }
} 