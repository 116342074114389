<div class="ai-agent-panel" cdkDrag [cdkDragDisabled]="false"
    style="background-color: #1e1e1e; color: #e0e0e0; position: absolute; z-index: 1000; min-width: 400px; box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5); border-radius: 6px; border: 1px solid #404040;">
    <div class="agent-header" cdkDragHandle
        style="background-color: #2d2d2d; padding: 10px; border-bottom: 1px solid #404040; display: flex; justify-content: space-between; align-items: center; cursor: move; border-radius: 6px 6px 0 0;">
        <div style="display: flex; gap: 8px; align-items: center;">
            <i class="fas fa-robot" style="color: #b84eff;"></i>
            <span style="font-weight: 500;">AI Assistant</span>
        </div>

        <div style="display: flex; gap: 8px;">
            <button class="toggle-button" (click)="toggleAgent()" style="background-color: #3d3d3d; color: #e0e0e0; border: 1px solid #404040; padding: 6px 12px; border-radius: 4px;">
                {{ isEnabled ? 'Disable' : 'Enable' }}
            </button>

            <button class="analyze-button" (click)="analyzeModel()" [disabled]="isAnalyzing || !isEnabled" style="background-color: #b84eff; color: #ffffff; border: none; padding: 6px 12px; border-radius: 4px;"
                [style.opacity]="(isAnalyzing || !isEnabled) ? '0.5' : '1'">
                {{ isAnalyzing ? 'Analyzing...' : 'Analyze Model' }}
            </button>

            <button class="toggle-explanations" (click)="toggleExplanations()" style="background-color: #3d3d3d; color: #e0e0e0; border: 1px solid #404040; padding: 6px 12px; border-radius: 4px;">
                {{ showExplanations ? 'Hide' : 'Show' }} Details
            </button>

            <button (click)="minimizeWindow()" style="background: none; border: none; color: #e0e0e0; padding: 0 6px;">
                <i class="fas fa-window-minimize"></i>
            </button>

            <button (click)="closeWindow()" style="background: none; border: none; color: #e0e0e0; padding: 0 6px;">
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>

    <div *ngIf="isEnabled" class="suggestions-container" style="padding: 15px; max-height: 600px; overflow-y: auto;">
        <div *ngIf="isAnalyzing" class="loading" style="text-align: center; padding: 20px;">
            <div class="spinner" style="border-color: #b84eff #404040 #404040;"></div>
            <p style="color: #b0b0b0;">Analyzing your 3D model...</p>
        </div>

        <div *ngIf="!isAnalyzing && suggestions.length === 0" class="no-suggestions" style="text-align: center; padding: 20px; color: #b0b0b0;">
            <p>No suggestions at the moment. Your model looks good! 👍</p>
        </div>

        <div *ngIf="!isAnalyzing && suggestions.length > 0" class="suggestions-list">
            <div class="suggestions-header" style="background-color: #2d2d2d; padding: 10px; border-radius: 4px 4px 0 0;">
                <h3 style="color: #e0e0e0; margin: 0;">Suggestions ({{ suggestions.length }})</h3>
                <div class="category-filters">
                    <button [class.active]="true" title="Geometry issues" style="background-color: #3d3d3d; color: #e0e0e0; border: 1px solid #404040; padding: 4px 8px; border-radius: 4px; margin-right: 4px;">
                        <i class="fas fa-cube"></i>
                    </button>
                    <button [class.active]="true" title="Material issues" style="background-color: #3d3d3d; color: #e0e0e0; border: 1px solid #404040; padding: 4px 8px; border-radius: 4px; margin-right: 4px;">
                        <i class="fas fa-palette"></i>
                    </button>
                    <button [class.active]="true" title="Performance issues" style="background-color: #3d3d3d; color: #e0e0e0; border: 1px solid #404040; padding: 4px 8px; border-radius: 4px;">
                        <i class="fas fa-tachometer-alt"></i>
                    </button>
                </div>
            </div>

            <div class="suggestion-items" style="background-color: #1e1e1e; border-radius: 0 0 4px 4px;">
                <div *ngFor="let suggestion of suggestions" class="suggestion-item" [class]="suggestion.type"
                    style="background-color: #2d2d2d; border-bottom: 1px solid #404040; padding: 15px; margin: 8px; border-radius: 4px;">
                    <div class="suggestion-header" style="margin-bottom: 10px; display: flex; justify-content: space-between; align-items: center;">
                        <div class="suggestion-type" style="color: #b84eff; display: flex; align-items: center; gap: 6px;">
                            <i class="fas" [class.fa-exclamation-circle]="suggestion.type === 'error'" [class.fa-exclamation-triangle]="suggestion.type === 'warning'"
                                [class.fa-lightbulb]="suggestion.type === 'optimization'"></i>
                            {{ suggestion.type | titlecase }}
                        </div>
                        <div class="suggestion-category" style="color: #808080; display: flex; align-items: center; gap: 6px;">
                            <i class="fas" [class.fa-cube]="suggestion.category === 'geometry'" [class.fa-palette]="suggestion.category === 'material'" [class.fa-tachometer-alt]="suggestion.category === 'performance'"
                                [class.fa-sitemap]="suggestion.category === 'structure'"></i>
                            {{ suggestion.category | titlecase }}
                        </div>
                    </div>

                    <div class="suggestion-content">
                        <p class="message" style="color: #e0e0e0; margin-bottom: 8px;">{{ suggestion.message }}</p>
                        <p *ngIf="showExplanations" class="explanation" style="color: #b0b0b0; margin-bottom: 12px; font-size: 0.9em;">{{ suggestion.explanation }}</p>

                        <div *ngIf="suggestion.cell" class="cell-reference" style="background-color: #1e1e1e; padding: 8px; border-radius: 4px; margin-top: 10px; border: 1px solid #404040;">
                            Cell: <span class="cell-id" style="color: #b84eff; font-weight: 500;">{{ suggestion.cell }}</span>
                            <span *ngIf="suggestion.suggestedValue" class="suggested-value" style="color: #808080; margin-left: 8px;">
                                Suggested: {{ suggestion.suggestedValue }}
                            </span>
                        </div>
                    </div>

                    <div class="suggestion-actions" style="margin-top: 12px; display: flex; gap: 8px;">
                        <button *ngIf="suggestion.suggestedValue" class="apply-button" (click)="applySuggestion(suggestion)"
                            style="background-color: #b84eff; color: #ffffff; border: none; padding: 6px 12px; border-radius: 4px; font-weight: 500;">
                            Apply
                        </button>
                        <button class="ignore-button" (click)="ignoreSuggestion(suggestion)" style="background-color: #1e1e1e; color: #e0e0e0; border: 1px solid #404040; padding: 6px 12px; border-radius: 4px;">
                            Ignore
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>