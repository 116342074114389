// firebase emulators:start --inspect-functions
import { ApplicationRef, Component, ElementRef, HostListener, NgZone, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { ChangeDetectorRef } from "@angular/core";
import { HotTableRegisterer } from "@handsontable/angular";
import Handsontable from 'handsontable';
import traverse from 'traverse';
import { HyperFormula, SimpleCellRange } from 'hyperformula';
import { BaseScene } from './basescene';
import * as THREE from "three";
import { TransformControls } from './transformcontrols.js';
import { SplitComponent, SplitAreaDirective } from 'angular-split'
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as Comlink from "comlink";
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { SnippetManager } from './snippetmanager';
import { CADNodeEngine } from './cadnodeengine';
import { Utils } from './utils';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { ThreeUtils } from './threeutils';
import { FilesDialog } from '../files/files.component';
import { MaterialbComponent } from '../materialb/materialb.component';
import { DialogsubprojectComponent } from './dialogsubproject/dialogsubproject.component';
import { MonacoEditorComponent, MonacoEditorLoaderService } from '@materia-ui/ngx-monaco-editor';
import VBAAPI from './vbapi';
import { AngularFireStorage } from '@angular/fire/storage';
import { InputdialogComponent } from '../inputdialog/inputdialog.component';
import { Config, DiffPatcher } from 'jsondiffpatch';
import { DialogsaveconfigComponent } from './dialogsaveconfig/dialogsaveconfig.component';
import { DialogdisclaimerComponent } from './dialogdisclaimer/dialogdisclaimer.component';
import { DialogmenuComponent } from './dialogmenu/dialogmenu.component';
import { ColorEvent } from 'ngx-color';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogdimensionsComponent } from './dialogdimensions/dialogdimensions.component';
import { DialogshapeComponent } from './dialogshape/dialogshape.component';
import { Taskmanager } from './taskmanager';


import { PublishDialog } from './dialogpublish';
import { AngJsoneditorComponent, JsonEditorOptions } from 'ang-json-editor-13';
import { DialogfunctionsComponent } from './dialogfunctions/dialogfunctions.component';

//import { ViewportGizmo } from "three-viewport-gizmo";
import { ViewportGizmo } from '../lib/ViewportGizmo';
import { ToastrService } from 'ngx-toastr';


// https://hofk.de/main/discourse.threejs/2020/FatLineEdges/FatLineEdges.html
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial.js";
import { Wireframe } from "three/examples/jsm/lines/Wireframe.js";
import { LineSegmentsGeometry } from "three/examples/jsm/lines/LineSegmentsGeometry.js";
import { ScreenshotService } from 'app/services/screenshot.service';
import { AiPromptEditorComponent } from '../ai-prompt-editor/ai-prompt-editor.component';
import { CameraManager } from './camera-manager';
import { ScreenshotService2 } from '../services/screenshot.service';
import { CellStyleManager } from './cell-style-manager';
import { ProjectManager } from './projectmanager';
import { SheetHelper } from './sheet-helper';
import { ConfirmLeaveDialogComponent } from './confirm-leave-dialog.component';
// import { ComponentCanDeactivate } from '../guards/component-can-deactivate';
import { Location } from '@angular/common';
import { InteractionManager } from './interaction-manager';
import { ExportDialogComponent } from './export-dialog/export-dialog.component';
import { AIAgentComponent } from '../ai-agent/ai-agent.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit {
  public isDarkMode = true;
  cameraManager: CameraManager;
  private deactivating = new Subject<boolean>();


  hotid2 = 'hotInstance2';
  datasetsel = [[1, 2, 3], [4, 5, 6], [7, 8, 9]];

  @ViewChild(AngJsoneditorComponent, { static: false }) jsoneditor: AngJsoneditorComponent;

  pickinfos = false;
  snippetManager: SnippetManager;
  autocompleteListControls = ['label', 'textbox', 'number', 'colorchooser', 'image', 'button', 'savebutton', 'select', 'slider', 'expansion', 'checkbox', 'radio', 'tab', 'stepper', 'window', 'timer'];// TODO: externe tabellen
  autocompleteListPrimitives = ['', 'line', 'dimensionline', 'shape', 'surface', 'extrusion', 'sphere', 'cylinder', 'ibeam', 'cube', 'lathe', 'image3d', 'subtraction', 'textflat', 'gltf', 'group', 'light', 'gltfnode', 'text2d', 'overlay', 'rectangle2d', 'image2d', 'chart', 'profile', 'connector', 'aluprofile', 'copy', 'grid'];
  overlayTypes = ['overlay', 'image', 'rectangle2d', 'text2d', 'circle', 'ellipse', 'polygon', 'polyline', 'path', 'chart'];
  cellinfo: string; // = "dsfsd";
  cellinfoleft = "0"; cellinfotop = "0";
  celltooltipleft = "0"; celltooltiptop = "0";
  cellactionvisible = false;
  cellactionleft = "0"; cellactiontop = "0";
  cellinfomenuvisible = false;
  cellactionmenuvisible = false;
  showprojectinfo = false;
  templatesItems: any;
  info: string;
  dataset: any;
  disableEditorDrag = false;
  disableguidrag = false;
  hotsettings: any;
  slidingpanel = false;
  hfoptions = {
    licenseKey: 'gpl-v3',
    language: 'enGB',
    useColumnIndex: true,
    hiddenRows: true,

    //  evaluateNullToZero: true
    //  chooseAddressMappingPolicy: 'AlwaysSparse'
  };
  showComments = 1;

  navigationType = "free";// center
  cad: CADNodeEngine;

  hf: HyperFormula;
  showTableMenu = false;
  showAIPromptEditor = false;
  aiPrompt = '';
  menuleft = '0px';


  taskManager: Taskmanager;
  private hotRegisterer = new HotTableRegisterer();
  public hot: Handsontable;
  hotid = 'hotInstance';
  @ViewChild("rendererContainer") rendererContainer: ElementRef;
  @ViewChild("hot") hotContainer: ElementRef;

  valueSubject = new BehaviorSubject<any>(0);
  renderer;
  showSceneSettings = false;
  appguiVisible = true;
  backgroundSceneColor1: any;
  baseScene: BaseScene;
  scene = null;
  camera: any; // THREE.Camera;
  controls: any;
  viewportGizmo: ViewportGizmo;
  stats: any;
  clock: THREE.Clock;
  isMobile = false;
  showMenu = true;
  showSheetsmenu = false;
  scenemode = "select";
  mnuSelect = 2;
  tutorial: AngularFireObject<any>;
  tutorials: Observable<any[]>;
  showaccountmenu = false;
  SERVER = "https://us-central1-xbuild3d.cloudfunctions.net";  //http://localhost:5001/xbuild3d/us-central1";
  PROJECT: string;
  jsongui: any; jsongui2: any;
  files: any;
  loadSheet = true;
  selectedProjectName = "Main";

  excels: any;
  workers: Worker[];
  sheetid = 0;
  customerid: string;
  projectid: string;
  datasetids = []; datsetrows = [];
  cellformulas: any;
  fixedColumnsLeft = 0; fixedRowsTop = 0;
  project: any;
  showfiles = false;
  showGUIEditor = false; showScriptsEditor = false;
  curSelectionReadable: string;
  objectloader: THREE.ObjectLoader;

  changesQueue = [];

  tdstyles = [];

  selectedEnvMap: string;
  envMaps = [{ value: 'neutral' }, { value: 'technical' }, { value: 'sunset' }, { value: 'city' }];

  //monaco editor
  scripteditorOptions = { theme: 'vs-dark', language: 'javascript' };
  scriptcode: string = 'function xtest() {\nconsole.log("Hello world!"); console.log(globalThis["hftest"]); \n}';
  csseditorOptions = { theme: 'vs-dark', language: 'css' };
  csscode: string = '.class {\nbackground-color: #fff;\n}   .test{font-size:12px;} .test2{font-size:12px; color:red;}';
  monaceditor: MonacoEditorComponent;
  processing = true;
  vba: VBAAPI;
  interactionManager: InteractionManager;

  //
  @HostListener('document:click', ['$event']) onDocumentClick(event) {

    this.scm = false;
    this.showSceneSettings = false;
    this.showSheetsmenu = false;
    this.showTableMenu = false;
    this.showOverlaymenu = false;
    this.showOverlaymenu1 = false;
    this.showothermenu = false;
    this.mnucolorvisible = false;
    this.mnucolorvisible2 = false;
    this.showaccountmenu = false;

    this.ss = false;
    this.scm = false;
    // this.cellactionvisible = false;
    // this.cellinfovisible = false;

  }

  isShiftDown = false;
  isControlDown = false;
  showObjectInfos = false;
  @HostListener('document:keydown', ['$event']) onDocumentd(event) {
    if (event.key == "Shift") {
      this.isShiftDown = true;
    }
    if (event.key == "Control") {
      this.isControlDown = true;
    }
  }


  beforekeydown = (e) => {
    // if (this.editcell) {
    //   var txtarea = document.getElementsByClassName('handsontableInput')[0] as any;

    //   this.setCaretToPos(txtarea, 2);
    // }
    try {
      if (!e.key) {
        //  e.stopImmediatePropagation();
        // e.preventDefault();
        return;
      }

      //    console.log("beforekeydown", e);
      if (e.key == "Alt") {
        if (this.curSelectedRow < SheetHelper.getOutputRow(this.dataset) && this.curSelectedCol < 2) {
          this.contextmenuitem1 = 1;
          this.cellactionmenuvisible = true;
        }
        if (this.curSelectedRow > SheetHelper.getOutputRow(this.dataset) && this.curSelectedCol < 2) {
          this.contextmenuitem = 1;

          this.cellactionmenuvisible = true;
        }
        if (this.cellinfovisible)
          this.cellinfomenuvisible = !this.cellinfomenuvisible;
        if (this.celltoolsvisible) {

          this.showcelltools();
        }
      }

      if (e.key == "Escape") {
        this.cellactionmenuvisible = false;
        this.cellinfomenuvisible = false;
        this.showOverlaymenu = false;
        this.showOverlaymenu1 = false;
        this.showothermenu = false;
        // Check if a subproject is selected
        if (!this.isMainConfig) {
          // Reset to main project
          if (this.selectedObjectHelper)
            this.scene.remove(this.selectedObjectHelper);
          this.selectedNode = this.scene;
          this.selectedSubprojectID = this.PROJECT;
          this.selectedProjectName = null;
          this.selectedSubprojectPath = null;
          this.selectedprojectpath = "root^$" + this.sheetid;
          this.isMainConfig = true;
          this.jsongui2 = null;

          // Reset materials/opacity
          this.scene.traverse((child) => {
            if (child.material) {
              if (child.userData.opacity > 0.99)
                child.material.transparent = false;
              if (child.userData.opacity)
                child.material.opacity = child.userData.opacity;
            }

          });
          this.excels[this.PROJECT].createJsonGUI(0).then(gui => {
            this.jsongui = gui;
            this.csschange(this.project.csscode);
            this.subprojectSelected();
            this.updateThreeFrame();
          });
        }
      }

      // menu1
      if (this.cellactionmenuvisible && this.curSelectedRow < SheetHelper.getOutputRow(this.dataset)) {
        if (e.key == "ArrowDown") {
          this.contextmenuitem1++;
          e.stopImmediatePropagation();
        }

        if (e.key == "ArrowUp") {

          this.contextmenuitem1--;
          e.stopImmediatePropagation();
        }


        if (e.key == "ArrowRight" && this.contextmenuitem1 == 9) {
          this.contextmenuitem1 = 21;
          this.showOverlaymenu1 = true;
          e.stopImmediatePropagation();

          return;
        }
        if (e.key == "ArrowLeft" && this.contextmenuitem1 > 20) {
          this.contextmenuitem1 = 9;
          this.showOverlaymenu1 = false;
          e.stopImmediatePropagation();

          return;

        }

        if (e.key == "Enter") {
          var t = ['', 'label', 'textbox', 'number', 'checkbox', 'radio', 'PNG', 'select', 'slider', '', '',
            '', '', '', '', '', '', '', '', '', '',
            'button', 'expansion', 'colorchooser', 'savebutton'];
          if (this.contextmenuitem1 == 6)
            this.showfilesDialog('PNG');
          else if (this.contextmenuitem1 == 9)
            this.showOverlaymenu1 = true;
          else
            this.addGui(t[this.contextmenuitem1]);

          if (this.contextmenuitem1 != 9)
            this.cellactionmenuvisible = false;

          this.refs = null;
          this.applyCellMeta();

          e.stopImmediatePropagation();
        }

      }

      // menu2
      if (this.cellactionmenuvisible && this.curSelectedRow > SheetHelper.getOutputRow(this.dataset)) {
        if (e.key == "ArrowDown") {
          this.contextmenuitem++;
          e.stopImmediatePropagation();
        }

        if (e.key == "ArrowUp") {

          this.contextmenuitem--;
          e.stopImmediatePropagation();
        }

        if (e.key == "ArrowRight" && this.contextmenuitem == 9) {
          this.contextmenuitem = 40;
          this.showothermenu = true;
          e.stopImmediatePropagation();

          return;
        }
        if (e.key == "ArrowRight" && this.contextmenuitem == 10) {  // showSheetsmenu showOverlaymenu
          this.contextmenuitem = 21;
          this.showSheetsmenu = true;
          e.stopImmediatePropagation();
          return;
        }
        if (e.key == "ArrowRight" && this.contextmenuitem == 11) {  // showSheetsmenu showOverlaymenu
          this.contextmenuitem = 30;
          this.showOverlaymenu = true;
          e.stopImmediatePropagation();
          return;
        }

        if (e.key == "ArrowLeft" && this.contextmenuitem > 19 && this.contextmenuitem < 30) {
          this.contextmenuitem = 10;
          this.showSheetsmenu = false;
          e.stopImmediatePropagation();
          return;
        }
        if (e.key == "ArrowLeft" && this.contextmenuitem > 29) {
          this.contextmenuitem = 11;
          this.showOverlaymenu = false;
          e.stopImmediatePropagation();
          return;
        }

        if (e.key == "Enter") {
          var t = ['', 'extrusion', '', 'line', 'shape', 'cube', 'cylinder', 'textflat', '', '', '',
            '', '', '', '', '', '', '', '', '', '',
            '', '', '', '', '', '', '', '', '', '',
            'overlay', 'text2d', ' rectangle2d', 'image2d', 'chart', "", "", "", "", "",
            'subtraction', 'sphere', 'surface', "light", "profile"];
          if (this.contextmenuitem == 2)
            this.showfilesDialog('gltf');
          else if (this.contextmenuitem == 9)
            this.showSheetsmenu = true;
          else if (this.contextmenuitem == 10)
            this.showOverlaymenu = true;
          else if (this.contextmenuitem == 11)
            this.addProject();
          else if (this.contextmenuitem > 11 && this.contextmenuitem < 30)
            this.addPrimitive('sheet: ' + this.project?.sheets[this.contextmenuitem].name);
          else
            this.addPrimitive(t[this.contextmenuitem]);

          if (this.contextmenuitem != 10)
            this.cellactionmenuvisible = false;
          e.stopImmediatePropagation();
        }

      }

    } catch (e) {
      console.warn("beforekeydown", e);
    }
  }

  @HostListener('document:keyup', ['$event']) onDocumentup(event) {
    if (event.key == "Shift") this.isShiftDown = false;
    if (event.key == "Control") this.isControlDown = false;
  }

  // darkmode
  switchMode() {
    // if (document.body.classList.contains('darkmode')) {
    //   document.body.classList.remove("darkmode");
    // } else {
    //   document.body.classList.add("darkmode");
    //    }
  }

  scripteditorInit($event: any) {
    console.log("scripteditorInit", $event);

  }
  csseditorInit($event) {
    console.log("csseditorInit", $event);
    $event.trigger("editor", "editor.action.formatDocument");
  }

  csschange($e, issubconfig = false) {
    if (!issubconfig)
      this.project.csscode = $e;
    //   console.log("csschange", this.project.csscode);
    // TODO: auch wechseln, bei configwechsel
    var htmlid = "customcss_" + this.projectid;
    if (document.getElementById(htmlid))
      document.getElementById(htmlid).remove();

    // Build your `<link>` dynamically
    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.id = htmlid;
    link.href = 'data:text/css;charset=UTF-8,' + encodeURIComponent($e); // TODO: css von project
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  updateScene($event) {
    console.log("updateScene", this.project.sceneSettings.directionalIntensity, $event);
    this.baseScene.updateScene(this.project.sceneSettings, this.camera);

    this.updateThreeFrame();
  }

  ss = false;
  scm = false;
  async test() {


    console.log("test", this.scm);

    var cellrange = {
      start: { row: 0, col: 0, sheet: this.sheetid },
      end: { row: 0, col: 0, sheet: this.sheetid }
    };
    var t = await this.excels[this.PROJECT].cut(cellrange);
    var p = await this.excels[this.PROJECT].paste({ sheet: 0, row: 0, col: 4 });
    console.log("paste", p);
    this.scm = !this.scm;
  }
  timersEnabled = true;
  timers = []; intervals = [];
  async timerfunction(t, clock: THREE.Clock) {
    var interval = setInterval(async () => {
      //  console.log("timerfunction", t.name, clock);
      if (this.project.sceneSettings.showAnimations) {
        //        var delta = clock.getDelta();
        //  for (var i = 0; i < this.timers.length; i++) {
        var isenabled = await this.excels[this.PROJECT].getCellSerialized({ sheet: 0, row: t.row, col: 6 });
        isenabled = isenabled == "true" ? true : false;

        if (!isenabled)
          clock.stop();
        if (isenabled) {
          if (!clock.running) {

            clock.start();
          }

          var elapsed = clock.getElapsedTime();


          var changes = await this.excels[this.PROJECT].setCellContents({ sheet: 0, row: t.row, col: 4 }, elapsed);
          //  }
          await globalThis.API.updateUI(); // warum auch immer das geht aber applyCellMeta nicht?!

          // var eventinfo = {
          //   point: intersects[0].point,
          //   distance: intersects[0].distance,
          // }

          var jsfunction2 = t.changefunction;

          if (jsfunction2) {
            //        var es = JSON.stringify(eventinfo);
            //       if (!es) es = "null";
            //      jsfgffunction2 = jsfunction2.replace("event", es);
            //       var ud = this.sceneobjectMouseOver;
            //       if (ud) ud = JSON.stringify(ud);
            //       else ud = "null";
            //       jsfunction2 = jsfunction2.replace("object", ud);
            eval(this.project.scriptcode + "  " + jsfunction2 + ";");
          }      //      console.log("timer", elapsed, delta);

        }
        //      this.applyCellMeta();
      }
    }, t.interval);
  }


  clocks = [];
  async createTimers() {
    this.timers = await this.excels[this.PROJECT].getTimers();
    console.log("createTimers", this.timers);
    //
    // var timer = {
    //   name: name, row: j, sheet: i, interval: interval, changefunction: changefunction
    // };

    // neu: timer mit expression
    //await this.excels[this.PROJECT].changeNamedExpression("time100", 100);

    this.intervals = [];
    for (var i = 0; i < this.timers.length; i++) {
      var clock = new THREE.Clock();
      this.clocks.push(clock);
      var t = this.timers[i];
      this.timerfunction(t, this.clocks[i]);
      // var interval = setInterval(async () => {
      //   if (this.project.sceneSettings.showAnimations) {
      //     console.log("timer", t.name);
      //     var delta = clock.getDelta();
      //     var elapsed = clock.getElapsedTime();
      //     //   for (var i = 0; i < this.timers.length; i++) {
      //     var isenabled = await this.excels[this.PROJECT].getCellSerialized({ sheet: 0, row: t.row, col: 6 });
      //     isenabled = isenabled == "true" ? true : false;
      //     if (isenabled)
      //       var changes = await this.excels[this.PROJECT].setCellContents({ sheet: 0, row: t.row, col: 4 }, elapsed);
      //     //  }
      //     await globalThis.API.updateUI(); // warum auch immer das geht aber applyCellMeta nicht?!

      //     var jsfunction2 = t.changefunction;

      //     if (jsfunction2) {
      //       //        var es = JSON.stringify(eventinfo);
      //       //       if (!es) es = "null";
      //       //      jsfunction2 = jsfunction2.replace("event", es);
      //       //       var ud = this.sceneobjectMouseOver;
      //       //       if (ud) ud = JSON.stringify(ud);
      //       //       else ud = "null";
      //       //       jsfunction2 = jsfunction2.replace("object", ud);
      //       eval(this.project.scriptcode + "  " + jsfunction2 + ";");
      //     }      //      console.log("timer", elapsed, delta);

      //     //      this.applyCellMeta();
      //   }
      // }, t.interval);
      // this.intervals.push(interval);
    }

  }

  diffpatchGUI(jsongui: any, jsongui2: any): any {
    var conf: Config;
    conf = {
      arrays: {
        detectMove: true,
        includeValueOnMove: false
      },
      textDiff: {
        minLength: 60
      },
      propertyFilter: function (name, context) {
        return name.slice(0, 1) !== '$';
      },
      cloneDiffValues: false
    };

    var jsondiffpatch = new DiffPatcher(conf);
    var delta = jsondiffpatch.diff(this.jsongui, jsongui);
    //  jsondiffpatch.patch(this.jsongui, jsongui);
    //    this.jsongui = jsondiffpatch.patch(this.jsongui, delta);
    this.jsongui = jsondiffpatch.patch(this.jsongui, delta);
    //  this.jsongui2 = jsongui;
  }

  transformControl: any;
  cellStyleManager: CellStyleManager;

  public jsonEditorOptions: JsonEditorOptions;
  constructor(private cdr: ChangeDetectorRef, private toastr: ToastrService, private screenshotService: ScreenshotService, private monacoLoaderService: MonacoEditorLoaderService, public sanitized: DomSanitizer, private ngZone: NgZone,
    public storage: AngularFireStorage, public dialog: MatDialog, public http: HttpClient, private rtdb: AngularFireDatabase, public auth: AngularFireAuth, public afs: AngularFirestore, public router: Router, public snackBar: MatSnackBar, private appRef: ApplicationRef, private route: ActivatedRoute, private zone: NgZone, public ngrenderer: Renderer2, private location: Location, private titleService: Title) {
    this.scene = new THREE.Scene();
    this.scene.name = "root";
    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, transparent: true } as any);

    this.baseScene = new BaseScene(this.scene, this.renderer);
    //  this.cadengine = new CADEngine(this.scene, this.excels, this.excels);
    this.scene.fog = new THREE.Fog(this.scene.background, 7000, 20000);

    this.cad = new CADNodeEngine();
    this.snippetManager = new SnippetManager();
    this.objectloader = new THREE.ObjectLoader();

    this.hf = HyperFormula.buildEmpty(this.hfoptions as any);

    this.hotsettings = {
      // TODO  editor: CustomEditor,
      licenseKey: 'non-commercial-and-evaluation',
      currentRowClassName: 'currentRow',

    };


    Handsontable.renderers.registerRenderer('customStylesRenderer', (hotInstance, TD, ...rest) => {
      Handsontable.renderers.getRenderer('text')(hotInstance, TD, ...rest);
      if (rest?.length > 1) {
        if (this.project?.settings?.stylesoverride) {
          var r = rest[0];
          var c = rest[1];
          try {
            var meta = this.hot?.getCellMeta(r, c);
          } catch (error) {
          }
          // TODO: direkt aus this.tdstyles holen

          if (meta?.stylemeta?.background) {
            TD.style.background = meta.stylemeta.background;
          }
          if (meta?.stylemeta?.color) {
            TD.style.color = meta.stylemeta.color;
          }
        }

      }
    });

    this.cellStyleManager = new CellStyleManager(this.afs);
    //https://stackoverflow.com/questions/51150422/how-to-detect-click-outside-of-an-element-in-angular
    this.ngrenderer.listen('window', 'click', (e: Event) => {
      //   console.log("window click", e);
      this.showaccountmenu = false;
      for (var i = 0; i < this.project?.sheets?.length; i++) {
        this.project.sheets[i].visible = false;
      }
    });
    this.jsonEditorOptions = new JsonEditorOptions()

    this.jsonEditorOptions.modes = ['view']; // set all allowed modes
    this.jsonEditorOptions.mode = 'view'; //set only one mode
    this.jsonEditorOptions.expandAll = true;

    //    this.jsonEditorOptions.statusBar = false;
    //  this.jsonEditorOptions.mainMenuBar = false;
    //    this.jsonEditorOptions.navigationBar = false;
    this.jsonEditorOptions.theme = 1;

    // Add navigation handling
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        const currentUrl = this.router.url;

        if ((event as any).navigationTrigger === 'popstate') {
          // Store the attempted navigation URL
          const targetUrl = event.url;

          const timeSinceLastSave = Date.now() - this.lastSaveTime;

          if (timeSinceLastSave > 10000) {
            // Cancel the current navigation by navigating back to current URL
            this.router.navigate([currentUrl], { skipLocationChange: true }).then(() => {
              const dialogRef = this.dialog.open(ConfirmLeaveDialogComponent, {
                width: '400px',
                disableClose: true,
                data: { timeSinceLastSave: Math.floor(timeSinceLastSave / 1000) }
              });

              dialogRef.afterClosed().subscribe(result => {
                if (result) {
                  // User confirmed leaving
                  this.deactivating.next(true);
                  this.deactivating.complete();
                  // Navigate to the original target URL
                  this.router.navigateByUrl(targetUrl);
                } else {
                  // User cancelled - stay on page
                  this.deactivating.next(false);
                  this.deactivating.complete();
                  // Ensure we stay on current URL
                  history.pushState(null, '', currentUrl);
                }
              });
            });
          } else {
            // No unsaved changes, navigate to target
            this.router.navigateByUrl(targetUrl);
          }
        }
      }
    });
  }

  private async getCellStyles(sheetid) {
    try {
      const snapshot = await this.afs.collection("/projects/userprojects/" + this.customerid + "/" + this.projectid + "/tdstyles/", ref => ref.where("sheetid", "==", sheetid).orderBy("datetime")).get().toPromise();

      this.tdstyles = snapshot.docs.map(doc => ({ id: doc.id, ...(doc.data() as object) }));

      for (let y = 0; y < this.tdstyles.length; y++) {
        const s = JSON.parse(this.tdstyles[y].s);
        const e = this.tdstyles[y].e;

        for (var i = s[0][0]; i <= s[0][2]; i++) {
          for (var j = s[0][1]; j <= s[0][3]; j++) {
            var cell = this.hot.getCell(i, j);

            try {
              var meta = this.hot.getCellMeta(i, j).stylemeta || {};
            } catch (error) {
              meta = {};
            }

            if (e.background) {
              meta.background = e.background;
            }
            if (e.foreground) {
              meta.color = e.foreground;
            }

            try {
              this.hot.setCellMeta(i, j, 'stylemeta', meta);
            } catch (error) {
              console.warn(error);
            }
          }
        }
      }
      this.hot.render();
    } catch (error) {
      console.error("Error getting documents: ", error);
    }
  }


  color1: any; color2: any;
  setCellBackground(e) {
    this.project.settings.stylesoverride = true;
    this.cellStyleManager.setCellBackground(this.hot, this.dataset, this.sheetid, this.customerid, this.projectid, e);
  }
  //  this.hot.render();
  setCellForeground(e) {
    this.project.settings.stylesoverride = true;
    this.cellStyleManager.setCellForeground(this.hot, this.dataset, this.sheetid, this.customerid, this.projectid, e);
  }

  async applyCellMeta() {

    // Convert Excel-style refs (A1, B3 etc) to HyperFormula simple cell references
    if (this.refs) {
      // Map string refs to array if needed
      if (Array.isArray(this.refs) && this.refs.length > 0 && typeof this.refs[0] === 'string') {
        let newRefs = [];
        for (const ref of this.refs) {
          try {
            const simpleAddress = await this.excels[this.PROJECT].simpleCellAddressFromString(ref, this.sheetid);
            if (simpleAddress) {
              newRefs.push(simpleAddress);
            }
          } catch (err) {
            console.warn("Error converting cell reference:", err);
          }
        }
        this.refs = newRefs;
      }
    }


    this.cellStyleManager.applyCellMetaStyles(this.hot, this.dataset, this.sheetid, this.isEditMode, this.refs, this.curSelectedRow, () => SheetHelper.getOutputRow(this.dataset));
  }

  toload = [];

  getConfigSheets(configs: any, list: any) {  // TODO: funzt nicth mit public von anderen
    console.log('getconfigs', configs);
    if (Object.keys(configs).length === 0 || !configs) {
      return;
    }

    for (const l in configs) {
      console.log('l', l);
      var c = configs[l];
      var userid = this.customerid;
      if (c.userid) userid = c.userid;
      list.push(userid + '_' + c.id);  // TODO: funzt nicth mit public von anderen
      this.getConfigSheets(c.configurations, list);
    }
  }


  impersonationId: string;
  leftpanelvisible = true; isEditMode = true;
  configid: string;

  @ViewChild('viewportGizmo') viewportGizmoElement: ElementRef;

  async ngAfterViewInit() {
    this.projectid = this.route.snapshot.paramMap.get('projectid');
    this.configid = this.route.snapshot.paramMap.get('configid');
    var dbpath = "/projects/userprojects/" + this.customerid + "/" + this.projectid;
    // public
    if (this.route.snapshot.url[0].path == "p") {
      this.leftpanelvisible = false;
      this.isEditMode = false;
      dbpath = "projectspublic" + "/" + this.projectid;
    }

    this.setupthreejs();
    this.cad.interactionManager = this.interactionManager;

    // tasks
    if (this.route.snapshot.url[0].path == "tasks") {
      // this.splitleft = 0;

      this.leftpanelwidth = 0;
      if (this.leftpanelwidth > 9000) this.leftpanelwidth = 0;
      this.threecontainerwidth = window.innerWidth - this.leftpanelwidth;

      this.initview();
      this.updateThreeFrame();
      this.loadSheet = false;
      this.taskManager = new Taskmanager(this.afs, this.storage, this.renderer, this.camera, this.scene, this.controls);
      this.scene.fog = null;
      return;
    }
    else {
      this.excelSync();
      this.auth.authState.subscribe(async x => {
        try {
          this.customerid = x?.uid;

          // impersonation
          this.impersonationId = this.route.snapshot.queryParamMap.get('impersonationId');
          if (this.impersonationId) {
            this.customerid = this.impersonationId;
            console.log('Impersonating customer ID:', this.customerid);
          }

          console.log("authstate sub: ", x);
          if (this.isEditMode)
            dbpath = "/projects/userprojects/" + this.customerid + "/" + this.projectid;

          this.toload = [];
          var pr = (await this.afs.doc(dbpath).get().toPromise());   //.subscribe(data => {

          // project loaded project json
          this.project = pr.data();
          if (!this.customerid) this.customerid = this.project.userid; //TODO: prüfen!!!
          if (!this.project.sceneSettings)
            this.project.sceneSettings = this.baseScene.sceneSettings
          else
            this.baseScene.sceneSettings = this.project.sceneSettings;


          // Add colWidths to each sheet in project if not exist
          if (this.project.sheets) {
            for (let sheetId in this.project.sheets) {
              if (!this.project.sheets[sheetId].colWidths) {
                this.project.sheets[sheetId].colWidths = new Array(300).fill(100);
              }
            }
          }


          if (!this.project.cameras)
            this.addCamera();
          // if (this.project?.initialcamera) {
          //   this.camera.matrix.fromArray(JSON.parse(this.project.initialcamera));
          //   this.camera.matrix.decompose(this.camera.position, this.camera.quaternion, this.camera.scale);
          // }
          // if (this.isEditMode && this.project?.camera) {
          //   this.camera.matrix.fromArray(JSON.parse(this.project.camera));
          //   this.camera.matrix.decompose(this.camera.position, this.camera.quaternion, this.camera.scale);

          // }

          // Load saved camera view if it exists
          if (this.project?.cameraview) {
            const cameraState = JSON.parse(this.project.cameraview);

            // Restore complete camera state
            this.camera.matrix.fromArray(cameraState.matrix);
            this.camera.position.fromArray(cameraState.position);
            this.camera.quaternion.fromArray(cameraState.quaternion);
            this.camera.zoom = cameraState.zoom;
            this.controls.target.fromArray(cameraState.target);

            // Update camera matrices
            this.camera.matrix.decompose(this.camera.position, this.camera.quaternion, this.camera.scale);
            this.camera.updateProjectionMatrix();

            // Update controls
            this.controls.update();
          }


        } catch (error) {
          console.error("Failed to load project data", error);


          this.toastr.error(error.message, null, { positionClass: 'toast-bottom-center', });
          //   this.snackBar.open("Error loading project data: " + error.message, null, { duration: 15000, panelClass: ['snackbar-error'] });
        }


        try {
          if (!this.project.settings) {
            this.project.settings = {};
            this.project.settings.cameraZoom = true;

            this.project.settings.cameraZoomPointer = false;
            this.project.settings.subprojectSelectable = false;
            this.project.settings.cameraPan = true;

          }
          if (!this.project.sceneSettings.dirAngle)
            this.project.sceneSettings.dirAngle = 0;
          if (!this.project.menuSettings?.menuleft)
            this.project.menuSettings.menuleft = '0px';

          this.changeHandling();

          this.baseScene.updateScene(this.project.sceneSettings, this.camera);
          this.project.id = pr.id;
          this.toload.push(this.customerid + '_' + this.projectid);
          this.PROJECT = this.toload[0];

          this.getConfigSheets(this.project.configurations, this.toload);
          // remove duplicates from list
          this.toload = this.toload.filter((v, i, a) => a.indexOf(v) === i);
          console.log('toload ', this.toload);

          this.autocompleteListPrimitives.push('');
          for (var i = 0; i < this.project.sheets.length; i++) {
            this.autocompleteListPrimitives.push("sheet: " + this.project.sheets[i].name);
          }
          //      });


        } catch (error) {
          console.error("Failed to load project data", error);
          this.toastr.error(error.message, null, { positionClass: 'toast-bottom-center', });
          //        this.snackBar.open("Error loading project data: " + error.message, null, { duration: 15000, panelClass: ['snackbar-error'] });
        }

        try {

          // toload.push('userid-assemblyclass-2');
          this.files = this.toload;
          this.workers = [];
          this.excels = {};
          var promises = [];
          for (var i = 0; i < this.toload.length; i++) {
            const w = new Worker(new URL('./../app.worker', import.meta.url), { type: 'module' });
            this.workers.push(w);
            this.excels[this.toload[i]] = Comlink.wrap(this.workers[i]) as any;
            var p = this.excels[this.toload[i]].load(this.toload[i], this.project.sheets);
            promises.push(p);
          }

          var t0 = performance.now();
          var promisesresult = await Promise.all(promises).catch(reason => {
            console.error(reason)
          });
          console.log('promisesresult', promisesresult);
          var t1 = performance.now();
          console.log("webworker loads took " + (t1 - t0) + " milliseconds.");

          this.dataset = promisesresult[0].cells;
          this.datasetids = promisesresult[0].datasetids;
          this.cellformulas = promisesresult[0].cellvalues;

          var res1 = await this.excels[this.PROJECT].addNamedExpression("screensize", "0,0");

          await this.excels[this.PROJECT].addNamedExpression("screenwidth", 0);
          await this.excels[this.PROJECT].addNamedExpression("screenheight", 0);

          if (this.isEditMode) {
            this.excels[this.PROJECT].setCurrentSheetId(this.sheetid);
            this.excels[this.PROJECT].monitorCellFormulas();
          }


          // config params
          if (this.configid) {
            var configpath = "/configs/" + this.customerid + "/" + this.projectid + "/" + this.configid;
            var configmodel = (await this.afs.doc(configpath).get().toPromise()).data();   //.subscribe(data => {
            this.project.configmodel = configmodel;
          }
          if (this.project.configmodel) {
            var outputRowsP = await this.excels[this.PROJECT].getConfiguration(0, this.project.configmodel.inputs, this.project.configmodel.vals, true);
            for (var i = 1; i < outputRowsP.length; i += 2) {
              for (var j = 0; j < outputRowsP[i].length - 1; j++) {
                var rowindex = outputRowsP[i][outputRowsP[i].length - 1];
                this.dataset[rowindex][j] = outputRowsP[i][j];
              }
            }
          }

        } catch (error) {
          console.error("Failed to load project data", error);
          this.toastr.error("Error loading project data: " + error.message, null, { positionClass: 'toast-bottom-center', });
          //       this.snackBar.open("Error loading project data: " + error.message, null, { duration: 15000, panelClass: ['snackbar-error'] });
        }

        try {
          this.hot = this.hotRegisterer.getInstance(this.hotid); //.loadData([['new', 'data']]);
          this.hot.addHook('afterColumnResize', (newSize, column) => {
            //  this.saveColumnWidth(column, newSize);
            console.log("afterColumnResize", column, newSize);
            this.project.sheets[this.sheetid].colWidths[column] = newSize;
          });

          this.hot.updateSettings({
            autoWrapRow: false,
            autoWrapCol: false
          });

          if (this.hot)
            this.applyCellMeta();

          // td styles
          this.tdstyles = [];
          // TODO: nach sheetid filtern
          this.getCellStyles(this.sheetid);

          this.jsongui = await this.excels[this.PROJECT].createJsonGUI(this.sheetid);

          //   this.setSceneSettings()
          this.gridchange();
          this.initview();

          this.createModel(this.scene, this.dataset, this.PROJECT);
          this.loadSheet = false;
          console.log("jsongui", this.jsongui);
          // API ------------------------------------
          globalThis.scene = this.scene;
          globalThis.excels = this.excels;
          globalThis.subprojectSelected = this.selectedSubprojectID;
          globalThis.project = this.project;
          this.vba = new VBAAPI(this.excels, this.hot, this.scene, this.dataset, this, this.controls, this.transformControl, this.project, this.afs);
          //    await this.vba.createScripts();
          globalThis.ActiveWorkbook = this.vba.ActiveWorkbook;

          globalThis.updateUI = this.applyCellMeta;
          globalThis.API = this.vba;
          this.createTimers();
          Utils.basecsschange(this.project.id, this.project.menuSettings);
          this.csschange(this.project.csscode);
          //----------------------------------------

          this.viewportGizmo = new ViewportGizmo(this.camera, this.renderer);
          this.viewportGizmo.target = this.controls.target;

          if (this.isEditMode)
            this.leftpanelwidth = Math.round(this.splitEl.displayedAreas[0].size / 100.0 * window.innerWidth);
          else
            this.leftpanelwidth = 0;
          if (this.leftpanelwidth > 9000) this.leftpanelwidth = 0;
          this.threecontainerwidth = window.innerWidth - this.leftpanelwidth;
          this.viewportGizmo.left = this.threecontainerwidth - 132; // hack
          // // listeners
          var ended = false;
          this.viewportGizmo.addEventListener("start", () => {
            ended = false;
          }
            //this.controls.enabled = false
          );
          this.viewportGizmo.addEventListener("end", () => {
            ended = true;
            // this.controls.enabled = true;
            //          this.camera.updateProjectionMatrix();
            this.updateThreeFrame();
            //      this.renderer.render(this.scene, this.camera);
            //      this.viewportGizmo.render()
            console.log("end")
          });
          this.viewportGizmo.addEventListener("change", () => {
            //  this.updateThreeFrame()
            // var delta = this.clock.getDelta();
            // this.controls.update(delta);
            //   this.camera.updateProjectionMatrix();
            if (!ended) {
              console.log("change");
              this.renderer.render(this.scene, this.camera);
              this.viewportGizmo.render()
            }
            else {
              this.camera.updateProjectionMatrix();

            }
          }

          );

          this.controls.addEventListener("change", () => {
            this.viewportGizmo?.update();
            this.viewportGizmo?.render();
          });



          if (this.project.sceneSettings.iso)
            this.setIsometricView(true);

        } catch (error) {
          console.error("Failed to load project data", error);
          this.toastr.error(error.message, null, { positionClass: 'toast-bottom-center', });
          //     this.snackBar.open("Error loading project data: " + error.message, null, { duration: 15000, panelClass: ['snackbar-error'] });
        }

        eval(this.project.scriptcode); // erste ausführung von eval zur javascript ausführung zB gui erstellung über jquery

        this.updateThreeFrame();
        //    this.renderer.setAnimationLoop(this.animate)
        this.animate(0);

        // Set up title update when project name changes
        this.updateTitle();
        // Watch for project name changes
        this.afs.doc(`projects/${this.project.id}`).valueChanges().subscribe((project: any) => {
          if (project?.name) {
            this.updateTitle();
          }
        });

      });
    }
    var isSaving = false;
    document.addEventListener('keydown', async e => {
      //if (e.ctrlKey && e.key === 'e') {
      if (!isSaving)
        if (e.metaKey && e.key === 'e' || e.ctrlKey && e.key === 's') {
          console.log("saving");
          isSaving = true;
          this.loadSheet = true;  // Show loading screen
          this.processing = true;  // Show processing indicator
          e.preventDefault();
          try {
            await this.saveCells();
            // update model
            if (this.editval)
              await this.sheetChange(this.editcell, this.editval, this.editval);

            await ThreeUtils.clearScene(this.scene);
            await this.applyCellMeta();
            this.jsongui = await this.excels[this.PROJECT].createJsonGUI(this.sheetid);
            await this.createModel(this.scene, this.dataset, this.PROJECT);

            await this.afs.doc("projects/userprojects/" + this.customerid + "/" + this.project.id).update(JSON.parse(JSON.stringify(this.project)));

            // this.snackBar.open("Save completed", "", {
            //   duration: 2000,
            //   panelClass: ['success-snackbar']
            // });
          } catch (error) {
            this.snackBar.open("Error saving: " + error.message, "", {
              duration: 4000,
              panelClass: ['error-snackbar']
            });
          } finally {
            this.processing = false;  // Hide processing indicator
            this.loadSheet = false;  // Hide loading screen
            await new Promise(resolve => setTimeout(resolve, 4000));
            isSaving = false;
          }
        }
    });


    this.subscriptions.add(
      this.valueSubject.pipe(
        debounceTime(2),  // Debounce inputs to reduce frequency
        switchMap(async inputValue => {
          if (this.iscreating) {
            // If a generation is ongoing, save the latest input to be processed later
            this.lastInputValue = inputValue;
            return [] as any;  // Return an empty observable
          } else {

            this.iscreating = true;
            await this.sheetChangeWrapper(inputValue);
            // Start generation immediately if nothing is currently generating
            this.iscreating = false;
            if (this.isEditMode)
              this.hot?.render();
          }
        })
      ).subscribe(model => {

        // TODO: wir nach cell editierung ausgelöst?!

        // console.log('subscribption', model)
        /*     if ((model as any).length > 0) {
              console.log('Model generated:', model);
            } */
        //      this.isGenerating = false;
        // Check if there is a queued last input to process next
        if (this.lastInputValue !== null) {
          let nextInput = this.lastInputValue;
          this.lastInputValue = null;  // Reset last input value
          this.valueSubject.next(nextInput);  // Re-emit the last input
        }
      })
    );

  }

  private subscriptions = new Subscription();
  isGenerating = false;
  lastInputValue: string | null = null;  // Store the last input value during generation
  iscreating = false;


  private initview() {
    // https://github.com/mrdoob/three.js/blob/master/examples/misc_controls_transform.html
    const geometry = new THREE.BoxGeometry(200, 200, 200);
    const material = new THREE.MeshLambertMaterial({ transparent: false, opacity: 0.9, color: 0x00ff00 });
    const mesh = new THREE.Mesh(geometry, material);
    this.transformControl = new TransformControls(this.camera, this.renderer.domElement);
    //  this.control.addEventListener('change', this.render);
    this.transformControl.addEventListener('dragging-changed', (event) => {
      console.log("dragging-changed", event, this.transformControl.object);
      if (this.transformControl.object?.userData?.row) {
        var o = this.transformControl.object;
        var ud = this.transformControl.object.userData;
        for (var i = 0; i < ud.commentrow.length; i++) {
          if (ud.commentrow[i] == "x") {
            this.dataset[ud.rowindex][i] = o.position.x; // p.x = ud.row[i];
            this.excels[this.PROJECT].setCellContents({ row: ud.rowindex, col: i, sheet: ud.sheetid }, o.position.x);
          }
          if (ud.commentrow[i] == "y") {

            this.dataset[ud.rowindex][i] = o.position.y;  //p.y = ud.row[i];
            this.excels[this.PROJECT].setCellContents({ row: ud.rowindex, col: i, sheet: ud.sheetid }, o.position.y);

          }
          if (ud.commentrow[i] == "z") {

            this.dataset[ud.rowindex][i] = o.position.z;
            this.excels[this.PROJECT].setCellContents({ row: ud.rowindex, col: i, sheet: ud.sheetid }, o.position.z);

          }
        }

        if (this.isEditMode)
          this.hot.render();
      }
      // this.transformControl.object.position.set(this.transformControl.object.position);
      this.controls.enabled = !event.value;
    });
    //    this.scene.add(mesh);
    this.transformControl.reset();
    this.transformControl.setSpace("local");//world
    //    this.transformControl.attach(mesh);
    this.transformControl.setMode('translate');
    // this.scene.add(this.transformControl);
    // this.transformControl.detach(mesh);
    //    this.leftpanelwidth = 0.4 * window.innerWidth;;
    console.log('ngAfterContentInit', window.innerWidth, this.leftpanelwidth)

    this.hot = this.hotRegisterer.getInstance(this.hotid); //.loadData([['new', 'data']]);


    this.cdr.detectChanges();

    this.onResize(null);

    if (this.isEditMode) {
      this.splitDragEnd({ sizes: [this.splitleft, 100 - this.splitleft] });
      this.hot?.render();

    }



    // TODO: autoformat?
    this.monacoLoaderService.isMonacoLoaded$.pipe(
      filter(isLoaded => isLoaded),
      // take(1),
    ).subscribe((x) => {
      console.log("monaco loaded", x, monaco);
      // // monaco.editor.setTheme('vs-light');
      // const editor = monaco.editor.create(document.getElementById("editor1"), {
      //   value: "function hello(){\nalert('Hello world2!');}",
      //   language: "css",
      //   //   "autoIndent": true,
      //   "formatOnPaste": true,
      //   "formatOnType": true
      // });
      // console.log('eiddotr1', editor, editor.getAction('editor.action.formatDocument'))
      // editor.getAction('editor.action.formatDocument').run();
      // //(monaco.editor as any).getAction('editor.action.formatDocument').run();
      // globalThis['hftest'] = this.scene;
      // eval('console.log("ausgewertet:",hftest);');
      // eval('globalThis["testf"] = ' + this.scriptcode);
    });

    //this.cdr.detectChanges();

    if (this.isEditMode) {

      this.applyCellMeta();
      this.hot?.render();
      this.hot?.rootElement?.focus();
      this.hot?.refreshDimensions();
      // hack fr splitter
      // this.splitDragEnd({ sizes: [this.splitleft, 99 - this.splitleft] }).then(() => {
      //   this.splitDragEnd({ sizes: [this.splitleft, 100 - this.splitleft] });
      // });
    }

    //    this.rendererContainer.nativeElement.focus();
  }

  async cloneproject() {
    this.loadSheet = true;
    await ProjectManager.cloneProject(this.project, this.customerid, this.http, this.afs, this.snackBar);
    this.loadSheet = false;
  }

  async saveproject() {
    this.loadSheet = true;
    this.processing = true;
    this.processingMessage = 'Saving...';
    try {
      // Save camera state
      const cameraState = {
        matrix: this.camera.matrix.toArray(),
        position: this.camera.position.toArray(),
        quaternion: this.camera.quaternion.toArray(),
        zoom: this.camera.zoom,
        target: this.controls.target.toArray()
      };

      // Save camera state as cameraview in project
      this.project.cameraview = JSON.stringify(cameraState);

      var configModel = await this.createConfigModel();
      this.project.configmodel = configModel;

      await ProjectManager.saveProject(this.project, this.camera, this.customerid, this.projectid, this.afs);
      await this.saveCells();
      this.hot.render();
      // this.snackBar.open("Save completed", "", {
      //   duration: 2000,
      //   panelClass: ['success-snackbar']
      // });
    } catch (error) {
      this.snackBar.open("Error saving: " + error.message, "", {
        duration: 4000,
        panelClass: ['error-snackbar']
      });
    } finally {
      this.processing = false;
      this.processingMessage = '';
      this.loadSheet = false;
    }
  }

  async saveCells() {
    this.cellformulas = await this.excels[this.PROJECT].getSheetSerializedByIndex(this.sheetid);

    try {
      var r = await this.excels[this.PROJECT].saveCells();
      console.log('saveCells', r);
    } catch (e) {
      console.error('saveCells error', e);
      throw e;
    }
  }

  excelSync() {
    // excel sync
    this.afs.collection("cellupdates/" + this.projectid + "/excelupdates").valueChanges({ idField: 'id' }).subscribe(data => {
      for (var i = 0; i < data.length; i++) {
        console.log('cellupdate', JSON.parse((data[i] as any).valueAfter)); // data[i]);
        console.log('del', "cellupdates/" + this.projectid + "/excelupdates/" + (data[i] as any).id)
        this.afs.doc("cellupdates/" + this.projectid + "/excelupdates/" + (data[i] as any).id).delete();
      }
    });
  }

  async createConfigModel(): Promise<any> {
    // root params
    var newsnippet = await this.excels[this.PROJECT].getSnippet(this.project.sheets[0].name);

    // projects
    var configModel = { inputs: newsnippet.values[0], vals: newsnippet.values[1] };
    this.scene.traverse((child) => {
      if (child.userData?.typ?.toUpperCase().startsWith("PROJECT")) {
        var path = child.userData.path;
        if (path.startsWith("^"))
          path = "root" + path;

        configModel[path] = child.userData;
        //      Utils.setValue(configModel, path, child.userData);
        console.log('child', child, child.name);
      }
    });

    console.log('configModel', configModel)
    return configModel;
  }

  stylesoverride(e) {
    console.log('stylesoverride', e, this.project?.settings?.stylesoverride);
    this.project.settings.stylesoverride = e.checked;

    if (this.isEditMode)
      this.hot.render();
  }


  // object is in list 
  isInList(list, object) {
    for (var i = 0; i < list.length; i++) {
      if (JSON.stringify(list[i]) == JSON.stringify(object)) {
        return true;
      }
    }
    return false;
  }

  // TODO: ggf in ww
  overlays: any = { root: {} }; showOverlaymenu = false;
  showOverlaymenu1 = false; showothermenu = false;
  contextmenuitem = -1;
  contextmenuitem1 = -1;
  overlayarray: any = [];
  async createModel(rootNode: any, dataset: any, CONFIGURATION: string) {
    this.processing = true;
    this.processingMessage = 'Building model...';
    try {
      console.log("createModel");
      var newnodes = [];
      this.outputrow = SheetHelper.getOutputRow(this.dataset);
      for (var j = this.outputrow + 1; j < dataset.length; j++) {
        try {

          var typ = dataset[j][1];
          var objectname = "$" + this.sheetid + "#" + dataset[j][0];
          var commentrow = SheetHelper.getCommentRow(dataset, j);

          if (commentrow && typ) {
            // overlaytypes is in ist
            if (this.isInList(this.overlayTypes, typ)) {
              // 2d overlays 
              objectname = "root^$" + this.sheetid + "#" + dataset[j][0];
              var overlay = await this.createOverlay(j, objectname, dataset[j], commentrow, typ);
            }
            else {

              //  console.log('createCADNode ', objectname, typ);
              var newnode = await this.cad.createOrUpdateCADNode(j, 0, objectname, dataset[j], commentrow, rootNode, this.excels, CONFIGURATION, this.toload, this.workers, this.customerid, this.projectid, this.project.configmodel, 0);
              if (newnode) {
                //    newnode.name = objectname;
                rootNode.add(newnode);
                newnodes.push(newnode);
              }
            }
            this.updateThreeFrame();
          }
        } catch (e) {
          console.log('createModel error', e);
        }
      }

      console.log('newnodes', newnodes, this.scene);
      this.updateThreeFrame();
      return newnodes;
    } finally {
      this.processing = false;
      this.processingMessage = '';
    }
  }

  async createChart(params) {
    var sheetid = 0; // zunächst nur overlays aus 1.sheet aus mainconfig
    var from = await this.excels[this.PROJECT].simpleCellAddressFromString(params.values.split(':')[0], sheetid);
    var to = await this.excels[this.PROJECT].simpleCellAddressFromString(params.values.split(':')[1], sheetid);
    var table = await this.excels[this.PROJECT].getRangeValues({ start: from, end: to });
    params.chartvalues = Utils.transformTableToNgxChartsModel(table);
    return params.chartvalues;
  }
  async createOverlay(j, objectname, row, commentrow, typ) {
    var overlayname = "root"
    var params = Utils.mapRowToParams(row, commentrow);
    params.typ = typ;
    if (!this.overlays[overlayname][objectname])
      this.overlays[overlayname][objectname] = {};
    if (params.typ == "chart") {
      this.createChart(params);
    }
    this.overlays[overlayname][objectname] = params;
    this.overlays[overlayname][objectname].objectname = objectname;
    // json keys to array
    var ovkeys = Object.keys(this.overlays.root);
    this.overlayarray = [];
    for (var i = 0; i < ovkeys.length; i++) {
      this.overlayarray.push(this.overlays.root[ovkeys[i]]);
    }
  }
  async updateOverlay(j, objectname, row, commentrow, typ) {
    var overlayname = "root"
    var params = Utils.mapRowToParams(row, commentrow);
    params.typ = typ;
    if (!this.overlays[overlayname][objectname])
      this.overlays[overlayname][objectname] = {};
    if (params.typ == "chart") {
      var v = await this.createChart(params);
      this.overlays[overlayname][objectname] = this.diffchart(this.overlays[overlayname][objectname], params);
      this.overlays[overlayname][objectname].chartvalues = params.chartvalues;
    }
    else
      this.overlays[overlayname][objectname] = params;
    this.overlays[overlayname][objectname].objectname = objectname;
    // json keys to array
    //  var ovkeys = Object.keys(this.overlays.root);
    //    this.overlayarray = [];
    var found = false;
    for (var i = 0; i < this.overlayarray.length; i++) {
      if (this.overlayarray[i].objectname == objectname) {
        found = true;
        this.overlayarray[i] = this.overlays.root[objectname];
        break;

      }
    }
    if (!found) {
      if (!this.overlayarray) this.overlayarray = [];
      this.overlayarray.push(this.overlays.root[objectname]);
    }
  }

  diffchart(params, newparams) {
    var conf: Config;
    conf = {
      arrays: {
        detectMove: true,
        includeValueOnMove: false
      },
      textDiff: {
        minLength: 60
      },
      propertyFilter: function (name, context) {
        return name.slice(0, 1) !== '$';
      },
      cloneDiffValues: false
    };
    var jsondiffpatch = new DiffPatcher(conf);
    var delta = jsondiffpatch.diff(params, newparams);
    var r = jsondiffpatch.patch(params, delta);
    return r;
  }




  dataset2: any;
  updatecount = 0;
  async updateModel(exportedchanges, olddata, scenenode) {
    this.processing = true;
    this.processingMessage = 'Updating model...';
    try {
      console.log('updatemodel', exportedchanges);
      var rowupdated = [];

      for (var j = 0; j < exportedchanges?.length; j++) {
        var change = exportedchanges[j];
        var a = change.address;
        if (!a) continue;
        var sheetid = a.sheet;

        var dataset = this.dataset
        if (!this.isMainConfig)
          dataset = this.dataset2;
        // TODO: check was geupdated wurde und nur zeilenweise updaten
        var outputrow = SheetHelper.getOutputRow(dataset);
        // gelöscht
        if (dataset[a.row][1] == "" && a.row > outputrow && dataset[a.row][0] != "#" && olddata) {
          if (!this.isInList(rowupdated, { sheet: a.sheet, row: a.row })) {
            // übergeordnete projekte nicht selektiert?
            // var nname = parent.name.replace(this.selectedprojectpath, "");
            // var n = "#" + nname.split('#')[1].split('^')[0];
            // var nname2 = this.selectedprojectpath + n;
            // if (!this.selectedprojectpath.endsWith("^$0"))
            //   nname2 = this.selectedprojectpath + "^$0" + n;
            // var nobj = this.scene.getObjectByName(nname2);
            // if (nobj) {
            //   this.selectedprojectpath = nname2;
            //   parent = nobj;
            // }



            var name = SheetHelper.getOldNameByRow(olddata, a.row);
            var objectnameold = "$" + sheetid + "#" + name; // todo: subprojects
            var objectname = this.selectedprojectpath + "#" + name;
            objectname = Utils.preNames(this.scene) + "^" + objectname;
            objectname = objectname.replace("root^root^", "root^");

            rowupdated.push({ sheet: a.sheet, row: a.row });

            var toremoveobject = this.scene.getObjectByName(objectname);
            if (toremoveobject)
              this.scene.remove(toremoveobject);

          }
        }

        if (dataset[a.row][1] != null && dataset[a.row][1] != "" && a.row > outputrow && dataset[a.row][0] != "#") {
          //
          if (!this.isInList(rowupdated, { sheet: a.sheet, row: a.row })) {

            rowupdated.push({ sheet: a.sheet, row: a.row });
            var typ = dataset[a.row][1];
            var objectname = "root^$" + sheetid + "#" + dataset[a.row][0]; // TODO falsch für unterprojekte
            objectname = Utils.preNames(this.scene) + "^" + objectname;
            objectname = objectname?.replace("root^root^", "root^");


            var subtraction = null;

            // TODO: subprojekte erst auf Änderung x,y,z,rx,ry,rz,tx,ty,tz,material,visible checken, dann erst remove/add

            // TODO funzt nicht mit gedropppten rows 
            var hr = SheetHelper.getHeadRow(dataset, a.row);
            if (hr != 0)// {//hr = a.row - 1;// TODO über jack
              //  try {

              if (dataset[hr][a.col]?.startsWith("node")) { // == "nodematerial") {
                if (objectname) {
                  var object = this.scene.getObjectByName(objectname);
                  var nodename = dataset[a.row - 1][a.col].split('=')[1];

                  var statement = dataset[a.row][a.col];
                  var prop = statement.split('=')[0];
                  var value = statement.split('=')[1];
                  // hier wird die node nur updated --------------------
                  this.cad.updateNodel(prop, nodename, value, object);
                  // -----------------------------------------------
                }
              }
              //          } catch (error) {
              //            console.warn('updateModel', error);
              //           }
              //      }
              else {
                var isover = false;
                if (objectname) {
                  if (scenenode) {
                    var object = scenenode?.getObjectByName(objectname);


                    if (object == this.sceneobjectSelected)
                      isover = true;

                  }

                  // while (object) {
                  //   //      console.log(objectname, object);
                  //   // bestimmte Attribute werden direkt im model gesetzt => TODO: zweite prüfung in createORupdateNode nötig?
                  //   var hr = SheetHelper.getHeadRow(dataset, a.row);

                  //   if (this.dataset[hr][a.col] == "x" || this.dataset[hr][a.col] == "y" || this.dataset[hr][a.col] == "z" ||
                  //     this.dataset[hr][a.col] == "rx" || this.dataset[hr][a.col] == "ry" || this.dataset[hr][a.col] == "rz" ||
                  //     this.dataset[hr][a.col] == "sx" || this.dataset[hr][a.col] == "sy" || this.dataset[hr][a.col] == "sz" ||
                  //     this.dataset[hr][a.col].startsWith("node") || this.dataset[hr][a.col] == "visible"
                  //   )
                  //     break;
                  //   if (object) {
                  //     //      console.log('remove', objectname)
                  //     // if (object.userData.subtraction) {

                  //     //   var subtraction = scenenode.getObjectByName(object.userData.subtraction);
                  //     //   console.log('subtraction', object.userData.subtraction, subtraction);

                  //     // }
                  //     scenenode.remove(object);

                  //   }
                  //   object = scenenode.getObjectByName(objectname);

                  // }
                }
                // get subproject node

                // var subnode = this.scene;

                if (!this.selectedSubprojectID)
                  this.selectedSubprojectID = this.PROJECT;

                var commentrow = SheetHelper.getCommentRow(dataset, a.row); // dataset[a.row - 1]

                if (this.isInList(this.overlayTypes, typ)) {
                  // 2d overlays 
                  var overlay = await this.updateOverlay(j, objectname, dataset[a.row], commentrow, typ);
                }
                else {
                  // 3d model node estellen -----------------------------------------------
                  var newnode = await this.cad.createOrUpdateCADNode(a.row, sheetid, objectname, dataset[a.row], commentrow, scenenode, this.excels, this.selectedSubprojectID, this.toload, this.workers, this.customerid, this.projectid, this.project.configModel, sheetid);

                  if (newnode?.userData)
                    if (newnode.userData.typ == "gltf" && newnode.userData.cadstate == "created") {
                      var dim = JSON.parse(JSON.stringify(newnode)).object;
                      await this.handleDimensionsDialog(dim, a.row, commentrow);
                      rowupdated.push({ sheet: a.sheet, row: a.row });
                    }

                  // postprocess cuts and boudnignsboxes and connections

                }

                if (subtraction) {
                  scenenode = scenenode; //?
                  var rowindex = subtraction.userData.rowindex;
                  var row = subtraction.userData.row;
                  commentrow = subtraction.userData.commentrow;
                  var name = subtraction.userData.path;
                  scenenode.remove(subtraction);
                  var newnode = await this.cad.createOrUpdateCADNode(rowindex, sheetid, name, row, commentrow, scenenode, this.excels, this.selectedSubprojectID, this.toload, this.workers, this.customerid, this.projectid, this.project.configModel, sheetid);

                }

                if (isover && newnode)
                  this.sceneobjectSelected = newnode;
              }
          }
        }

      }

      // this.updateSelectedObjectHelpe();
      console.log("this.sceneobjectSelected", this.sceneobjectSelected);
      this.handleObjectSelectionByRows(this.curSelectedRow);//TODO: nicht bei scene nclick und invisible
      this.updateSelectedObjectHelpe();
      this.higlightProject(); // Re-position helper after model updates

      this.updateThreeFrame();

      //  this.project.configmodel = await this.createConfigModel(); // muss nach rendering passieren, da sonst flackern!
      this.processing = false;
    } finally {
      this.processing = false;
      this.processingMessage = '';
    }
  }


  async copyFormulas(i: number, event: any) {
    console.log('copyFormulas', i);
    await Utils.copyFormulas(this.excels[this.PROJECT], i);
    event.stopPropagation();
  }
  async clearSheet(i: number, event: any) {
    console.log('clearSheet', i);
    await this.excels[this.PROJECT].clearSheet(i);
    this.dataset = await this.excels[this.PROJECT].getSheetValues(i);
    this.cellformulas = await this.excels[this.PROJECT].getCellFormulas(i);
    //    event.stopPropagation();
  }
  renameSheet(i: number, event: any) {
    console.log('renameSheet', i);

    const dialogRef = this.dialog.open(InputdialogComponent, {
      width: '400px',
      data: { name: this.project.sheets[i].name, okbutton: "Rename" },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);

      if (result) {
        this.excels[this.PROJECT].renameSheet(i, result);
        this.project.sheets[i].name = result;
      }


    });

    event.stopPropagation();
  }
  removeSheet(i: number, event: any) {
    console.log('removeSheet', i);
    event.stopPropagation();
  }

  additionalColumns = 5;
  additionalRows = 100;
  dlgresizetable = false;
  resizeTables() {
    this.loadSheet = true;
    this.dlgresizetable = false;
    let firebaseFunctionUrl = 'https://us-central1-xbuild3d.cloudfunctions.net/resizeTables'; // Replace with your actual URL
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const body = {
      customerid: this.customerid,
      projectid: this.projectid,
      additionalColumns: this.additionalColumns,
      numberOfRows: this.additionalRows
    };

    this.http.post(firebaseFunctionUrl, body, { headers }).subscribe(result => {
      console.log('resizeTables', result);
      window.location.reload();
    })
  }


  @ViewChild('aiPromptEditor') aiPromptEditor: AiPromptEditorComponent;

  async handleAIITeration(iterationdata: any) {
    console.log('handleAIITeration', iterationdata);
    var data = iterationdata.data;
    var iteration = iterationdata.iteration;
    await this.handleTableDataCopy(data);
    // send screenshots to ai prompt editor
    this.aiPromptEditor.nextIteration();
  }

  async saveChatHistory(chatHistory: any) {
    if (!this.project.chatHistory) {
      this.project.chatHistory = {};
    }
    // Convert array of chat histories to object with tab IDs as keys
    const flattenedHistory = {};
    chatHistory.forEach((tabHistory, index) => {
      flattenedHistory[`tab_${index}`] = tabHistory;
    });
    this.project.chatHistory[this.sheetid] = flattenedHistory;
    await this.saveproject();
  }

  /// from ai prompt editor
  async handleTableDataCopy(event) {
    var data = event.data;
    var run = event.run;
    console.log('Received table data:', data, run);

    if (run != null)
      if (run != this.sheetid) {
        //      await this.excels[this.PROJECT].setCellContents({ sheet: run, row: i, col: 0 }, "");
        // Remove "OUTPUTID" from the first column if present in the target sheet
        // Get range v alues for the target sheet to find OUTPUTID
        const range = {
          start: { row: 0, col: 0, sheet: run },
          end: { row: 100, col: 0, sheet: run } // Check first 100 rows of first column
        };
        const values = await this.excels[this.PROJECT].getRangeValues(range);

        // Find row with OUTPUTID and clear it if found
        for (let i = 0; i < values.length; i++) {
          if (values[i][0] === "OUTPUTID") {
            await this.excels[this.PROJECT].setCellContents({ sheet: run, row: i, col: 0 }, "");
            break;
          }
        }

        var exportedchanges = await this.excels[this.PROJECT].setCellContents({ sheet: run, row: 0, col: 0 }, data);

        return;
      }

    // Remove "OUTPUTID" from the first column if present
    for (let i = 0; i < this.dataset.length; i++) {
      if (this.dataset[i][0] === "OUTPUTID") {
        this.dataset[i][0] = "";
        this.cellformulas[i][0] = "";
        await this.excels[this.PROJECT].setCellContents({ sheet: this.sheetid, row: i, col: 0 }, "");
        break; // Assuming "OUTPUTID" appears only once
      }
    }
    for (var i = 0; i < data.length; i++) {
      for (var j = 0; j < data[i].length; j++) {
        this.dataset[i][j] = data[i][j];
        this.cellformulas[i][j] = data[i][j];
      }
    }
    // Update the dataset and cellformulas
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].length; j++) {
        this.dataset[i][j] = data[i][j];
        this.cellformulas[i][j] = data[i][j];
      }
    }

    var exportedchanges = await this.excels[this.PROJECT].setCellContents({ sheet: this.sheetid, row: 0, col: 0 }, data);
    //    var cell = { row: row, col: 1, sheet: this.sheetid };
    //    this.sheetChange(cell, newsnippet.values, newsnippet.values, true);

    var cellranget = {
      start: { row: 0, col: 0, sheet: this.sheetid },
      end: { row: data.length - 1, col: data[0].length - 1, sheet: this.sheetid }
    };
    const updatedCellValues = await this.excels[this.PROJECT].getRangeValues(cellranget);
    for (let i = 0; i < updatedCellValues.length; i++) {
      for (let j = 0; j < updatedCellValues[i].length; j++) {
        this.dataset[i][j] = updatedCellValues[i][j];
      }
    }

    this.applyCellMeta();

    // Re-create the JSON GUI
    this.jsongui = await this.excels[this.PROJECT].createJsonGUI(this.sheetid);
    var newnodes = await this.createModel(this.scene, this.dataset, this.PROJECT);
    ThreeUtils.zoomObject(this.camera, this.controls, newnodes);
    this.updateThreeFrame();
  }


  async updateControls(exportedchanges, srcchange) {
    var rowupdated = [];

    var uihaschanges = false;
    for (var j = 0; j < exportedchanges?.length; j++) {
      var change = exportedchanges[j];
      var a = change.address;
      var v = change.newValue;

      this.outputrow = SheetHelper.getOutputRow(this.dataset);

      if (!(srcchange.col == 2 && srcchange.row == a.row)) // src control nicht aendern, da fokuc springt (uind nicht nöötig)
        if (this.dataset[a.row][1] != null && this.dataset[a.row][1] != "" && a.row < this.outputrow) {
          //
          if (!this.isInList(rowupdated, { sheet: a.sheet, row: a.row })) {

            rowupdated.push({ sheet: a.sheet, row: a.row });
            var typ = this.dataset[a.row][1];
            var objectname = this.dataset[a.row][0];

            uihaschanges = true;
            // remove all objects from three js scene
            // var objects = this.scene.children;
            // for (var i = 0; i < objects.length; i++) {
            //   var object = objects[i];
            //   if (object.name == objectname) {
            //     this.scene.remove(object);
            //   }
            // }

            globalThis.paths = [];
            globalThis.editnodes = [];
            var newjsongui = await traverse(this.jsongui).forEach(function (value) {
              if (typeof (value) === "object" && value) {
                if (value.controlName == objectname) {
                  var paths = this.path;
                  globalThis.paths.push(paths);
                  globalThis.editnodes.push(value);
                }

              }
            });

            for (var i = 0; i < globalThis.paths.length; i++) {
              var path = globalThis.paths[i];
              var oldobj = globalThis.editnodes[i];

              var parentnode = this.jsongui; // TODO
              var result = await this.excels[this.PROJECT].createNodesFromRow({}, this.dataset, a.row, a.sheet, false, true);
              var newnode = result.rootnode.childs[0];
              console.log('createControlNode ', a, oldobj, path);
              var n = JSON.parse(JSON.stringify(this.jsongui));
              //  Utils.setValue2(this.jsongui, path, newnode);
              //            this.diffpatchGUI(n);
              uihaschanges = true;
              //    uihaschanges          Utils.setValue2(this.jsongui, path, newnode);
            }
            //          var newnode = //(this.dataset[a.row], this.dataset[a.row - 1]);

          }
        }

      // timer restart
      if (this.dataset[a.row][1] == "timer") {
        if (a.col == 6)
          for (var x = 0; x < this.timers.length; x++) {
            var timer = this.timers[x];
            if (timer.name == this.dataset[a.row][0]) {
              if (v == false) {
                // clearInterval(this.intervals[x]);
                this.clocks[x].stop();
                break;
              }
              if (v == true) {
                // var step = this.dataset[a.row][3];
                // var clock = new THREE.Clock();
                // this.clocks[x] = clock;
                this.clocks[x].start();

                // this.intervals[x] = setInterval(async () => {
                //   var changes = await this.excels[this.PROJECT].setCellContents({ sheet: 0, row: this.timers[x].row, col: 4 }, 0);
                //   //   this.timers[x].clock = new THREE.Clock();
                //   this.timerfunction(this.timers[x], this.clocks[x]);
                // }, step);


                // = setInterval(this.timerfunction(null,null), step);
                break;
              }
            }
          }
      }
    }



    // temp hack
    this.outputrow = SheetHelper.getOutputRow(this.dataset);
    let changesBeforeOutputRow = false;
    for (var j = 0; j < exportedchanges?.length; j++) {
      var change = exportedchanges[j];
      var a = change.address;
      var v = change.newValue;
      if (a.row < this.outputrow && a.col != 2 && a.col != 3) {
        changesBeforeOutputRow = true;
        break;
      }
    }

    //    console.log('###Changes before output row:', changesBeforeOutputRow);

    if (changesBeforeOutputRow) {
      var jsongui = await this.excels[this.PROJECT].createJsonGUI(this.sheetid); //TODO: nur bei change erstellen

      //console.log("######jsongui", jsongui);
      // TODO: diffpatch staatt neu erstelen
      // this.jsongui = jsongui;

      this.diffpatchGUI(jsongui, this.jsongui);
    }


  }


  async handleDimensionsDialog(node, row, commentrow) {
    const dialogRef = this.dialog.open(DialogdimensionsComponent, {
      width: '550px',
      data: { node: node },
    });

    dialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed', result);

      if (result) {
        var factor = result.factor;
        var upaxis = result.upaxis;

        // this.cellformulas[this.selectedRange[0]][this.selectedRange[1]] = result;
        // this.cellformulas[cell.row][cell.col] = result;

        //(node as THREE.Object).scale.set(result, result, result);
        var n = SheetHelper.getParameterIndex(commentrow, 'sx') as any;
        var cell = { row: this.selectedRange[0] + 1, col: n, sheet: this.sheetid };
        await this.sheetChange(cell, factor, 1, false, false);
        var n = SheetHelper.getParameterIndex(commentrow, 'sy') as any;
        var cell = { row: this.selectedRange[0] + 1, col: n, sheet: this.sheetid };
        await this.sheetChange(cell, factor, 1, false, false);
        var n = SheetHelper.getParameterIndex(commentrow, 'sz') as any;
        var cell = { row: this.selectedRange[0] + 1, col: n, sheet: this.sheetid };
        await this.sheetChange(cell, factor, 1, false, false);

        if (upaxis == 'X') {
          var n = SheetHelper.getParameterIndex(commentrow, 'ry') as any;
          var cell = { row: this.selectedRange[0] + 1, col: n, sheet: this.sheetid };
          await this.sheetChange(cell, "=PI()/2", 0);
        }
        if (upaxis == 'Y') {
          var n = SheetHelper.getParameterIndex(commentrow, 'rx') as any;
          var cell = { row: this.selectedRange[0] + 1, col: n, sheet: this.sheetid };
          await this.sheetChange(cell, "=PI()/2", 0);
        }

      }
    });
  }

  addCamera() {
    if (!this.project.cameras) {
      this.project.cameras = [];
    }

    // Save complete camera state
    const cameraState = {
      matrix: this.camera.matrix.toArray(),
      position: this.camera.position.toArray(),
      quaternion: this.camera.quaternion.toArray(),
      zoom: this.camera.zoom,
      target: this.controls.target.toArray()
    };

    this.project.cameras.push(JSON.stringify(cameraState));
    //    this.saveproject(); // Save to persist the new camera
  }

  currentcamindex = 1;
  loadCamera(i: number) {
    this.currentcamindex = i + 1;
    const cameraState = JSON.parse(this.project.cameras[i]);

    // Restore complete camera state
    this.camera.matrix.fromArray(cameraState.matrix);
    this.camera.position.fromArray(cameraState.position);
    this.camera.quaternion.fromArray(cameraState.quaternion);
    this.camera.zoom = cameraState.zoom;
    this.controls.target.fromArray(cameraState.target);

    // Update camera matrices
    this.camera.matrix.decompose(this.camera.position, this.camera.quaternion, this.camera.scale);
    this.camera.updateProjectionMatrix();

    // Update controls
    this.controls.update();

    this.updateThreeFrame();
  }

  async copyImpersonated() {
    const user = await this.auth.currentUser;
    const userId = user?.uid;
    console.log('Current User ID:', userId);


    var p = JSON.parse(JSON.stringify(this.project));
    var pid = p.id;
    delete p.id;
    p.name = p.name + "_copy_";
    let currentDate = new Date();
    p.name += currentDate.getFullYear() + "-" +
      ("0" + (currentDate.getMonth() + 1)).slice(-2) + "-" +
      ("0" + currentDate.getDate()).slice(-2) + " " +
      ("0" + currentDate.getHours()).slice(-2) + "-" +
      ("0" + currentDate.getMinutes()).slice(-2);
    p.createtime = new Date().getTime();
    p.lastsave = p.createtime;
    var newdoc = await this.afs.collection("projects/userprojects/" + userId).add(p);
    var npid = newdoc.id;
    this.loadSheet = true;
    let attempt = 0;
    const maxAttempts = 7;
    const attemptInterval = 400; // milliseconds

    const cloneProject = async () => {
      try {
        const response = await this.http.post(this.SERVER + "/copySheets", { customerid: this.customerid, userId: userId, projectid: pid, newprojectid: npid, sheetscount: 5 }).toPromise();
        console.log('clone result', response);
        this.loadSheet = false;
        this.snackBar.open("Project duplicated. Go To Dashboard to load copy.", null, { duration: 3000 });
      } catch (error) {
        if (attempt < maxAttempts) {
          setTimeout(() => {
            attempt++;
            cloneProject();
          }, attemptInterval);
        } else {
          console.error('Failed to clone project after ' + maxAttempts + ' attempts', error);
          this.snackBar.open("Failed to duplicate project. Please try again.", null, { duration: 3000 });
        }
      }
    };
    cloneProject();

  }

  onRightClick($event, index) {
    console.log('onRightClick', $event);
    for (var i = 0; i < this.project.sheets.length; i++) {
      this.project.sheets[i].visible = false;
    }
    this.project.sheets[index].visible = true;
    return false;
  }
  async setSheetIndex(event, i) {
    console.log('setSheetIndex ', i, event);

    this.loadSheet = true;
    // hack
    //    await this.saveCells();
    this.sheetid = i;

    var t0 = performance.now();
    //    this.CONFIGURATION = toload[0];
    var promisesresult = await this.excels[this.PROJECT].getSheetByIndex(i);
    var index = promisesresult.index;
    this.dataset = promisesresult.cells; // cells
    this.datasetids = promisesresult.datasetids;
    this.cellformulas = promisesresult.cellvalues;
    console.log('dataset', this.dataset);
    promisesresult;
    var t1 = performance.now();
    console.log("setSheetIndex took " + (t1 - t0) + " milliseconds.");

    ThreeUtils.clearScene(this.scene);
    this.getCellStyles(this.sheetid);

    const startTime = performance.now();
    this.jsongui = await this.excels[this.PROJECT].createJsonGUI(this.sheetid);
    const endTime = performance.now();
    console.log(`createJsonGUI took ${endTime - startTime} milliseconds`);

    const modelStartTime = performance.now();
    await this.createModel(this.scene, this.dataset, this.PROJECT);
    const modelEndTime = performance.now();
    console.log(`createModel took ${modelEndTime - modelStartTime} milliseconds`);

    this.selectedprojectpath = "root^$" + this.sheetid;
    this.excels[this.PROJECT].setCurrentSheetId(this.sheetid);
    this.loadSheet = false;
    this.updateThreeFrame();
    this.applyCellMeta();
    this.hot.render();

  }
  selectedObject = null;
  selectedObjectHelper: any;// THREE.BoxHelper;
  selectedWidget = null;

  updateSelectedObjectHelpe() {
    if (this.project.sceneSettings.showHelpers) {

      if (this.selectedObjectHelper)
        this.scene.remove(this.selectedObjectHelper);
      if (this.selectedObject) {
        this.selectedObject.updateMatrix();
        this.selectedObject.updateMatrixWorld();

        //   this.selectedObjectHelper = new THREE.BoxHelper(this.selectedObject, 0xff44ff);
        // var bb = ThreeUtils.getBoundingBox(this.selectedObject);
        // var box = new THREE.Box3(bb.min, bb.max); // create a bounding box from min and max
        var bb = ThreeUtils.getBBox(this.selectedObject);
        var box = new THREE.Box3(bb.min, bb.max);
        const boxGeometry2 = new THREE.BoxGeometry(
          box.max.x - box.min.x,
          box.max.y - box.min.y,
          box.max.z - box.min.z
        );
        const center = new THREE.Vector3();
        bb.getCenter(center);

        const edgesGeometry2 = new THREE.EdgesGeometry(boxGeometry2);
        const lineGeometry2 = new LineSegmentsGeometry().fromEdgesGeometry(edgesGeometry2);
        const lineMaterial = new LineMaterial({
          color: 0xff00ff,  // Choose the color as needed
          linewidth: 4,  // Adjust the line width for visibility
          resolution: new THREE.Vector2(window.innerWidth, window.innerHeight)  // Necessary for LineMaterial
        });
        const wireframe2 = new Wireframe(lineGeometry2, lineMaterial);
        wireframe2.position.copy(center);
        //  wireframe2.position.add(this.selectedObject.position);
        wireframe2.scale.set(1.1, 1.1, 1.1);
        wireframe2.computeLineDistances();

        this.scene.add(wireframe2);
        this.selectedObjectHelper = wireframe2;

        if (this.selectedObject.material) {
          //  this.selectedObject.material.transparent = true;
          // this.selectedObject.material.opacity = 0.7;
        }

        this.scene.add(this.selectedObjectHelper);
      }

      this.cad.showFaceCenters(this.selectedObject, this.scene);

    }


  }

  lastSelectedRow: number = -1;
  async handleObjectSelectionByRows(row1) {
    if (!row1) return;

    // Skip if the same row is already selected
    if (this.lastSelectedRow === row1) return;

    this.lastSelectedRow = row1;

    if (this.project.sceneSettings.showHelpers) {

      // row selection
      if (this.selectedObjectHelper)
        this.scene.remove(this.selectedObjectHelper);
      if (this.selectedObject?.material) {
        //  this.selectedObject.material.transparent = false;
        //  this.selectedObject.material.opacity = 1.0;
      }
      this.selectedObject = null;
      if (this.dataset[row1] && row1 > SheetHelper.getOutputRow(this.dataset))
        if (this.dataset[row1][0] && (this.isInList(this.autocompleteListPrimitives, this.dataset[row1][1]) || this.dataset[row1][1]?.startsWith('project'))) {
          // 3d object selected
          var objectname = "root^$" + this.sheetid + "#" + this.dataset[row1][0];
          var object = this.scene.getObjectByName(objectname, true);

          if (object) {
            var bb = ThreeUtils.getBBox(object);
            var box = new THREE.Box3(bb.min, bb.max);
            const boxGeometry2 = new THREE.BoxGeometry(
              box.max.x - box.min.x,
              box.max.y - box.min.y,
              box.max.z - box.min.z
            );
            const center = new THREE.Vector3();
            bb.getCenter(center);

            const edgesGeometry2 = new THREE.EdgesGeometry(boxGeometry2);
            const lineGeometry2 = new LineSegmentsGeometry().fromEdgesGeometry(edgesGeometry2);
            const lineMaterial = new LineMaterial({
              color: 0xff00ff,  // Choose the color as needed
              linewidth: 4,  // Adjust the line width for visibility
              resolution: new THREE.Vector2(window.innerWidth, window.innerHeight)  // Necessary for LineMaterial
            });
            const wireframe2 = new Wireframe(lineGeometry2, lineMaterial);
            wireframe2.position.copy(center);
            wireframe2.scale.set(1.1, 1.1, 1.1);
            wireframe2.computeLineDistances();
            this.scene.add(wireframe2);
            this.selectedObjectHelper = wireframe2;
            this.selectedObject = object;
          }
        }

      await traverse(this.jsongui).forEach(function (value) {
        if (typeof (value) === "object" && value) {
          value.selected = false;
        }
      });
      try {
        if (this.dataset && this.dataset[row1] && this.dataset[row1][0] && this.dataset[row1][1] && this.autocompleteListControls && this.isInList(this.autocompleteListControls, this.dataset[row1][1])) {
          globalThis.dataset = this.dataset;
          await traverse(this.jsongui).forEach(function (value) {
            if (typeof (value) === "object" && value) {
              if (value.controlName == globalThis.dataset[row1][0]) {
                value.selected = true;
              }
            }
          });
        }
      } catch (error) {
        console.warn('Error processing selection', error);
      }

      this.cad.showFaceCenters(this.selectedObject, this.scene);
      this.updateThreeFrame();
    }
  }
  state = {
    h: 150,
    s: 0.50,
    l: 0.20,
    a: 1,
  };
  mnucolorvisible = false;
  mnucolorvisible2 = false;
  primaryColor = "#ff0000";

  changeComplete($event: ColorEvent): void {
    this.state = $event.color.hsl;
    this.primaryColor = $event.color.hex;
    console.log('changeComplete', $event);
  }
  formula: string;
  curSelectedRow: number;
  curSelectedCol: number;
  outputrow: number;
  celltooltip: any = null;
  celltoolstop: string;
  celltoolsleft: string;
  celltoolsvisible = false;

  private isSelectingCell = false;
  private lastSelectionArgs: any = null;
  private selectionDebounceTimeout: any = null;

  cellSelected = async (row1, col1, row2, col2) => {
    // Store the latest selection arguments
    this.lastSelectionArgs = { row1, col1, row2, col2 };

    // Clear any pending debounce timeout
    if (this.selectionDebounceTimeout) {
      clearTimeout(this.selectionDebounceTimeout);
    }

    // Set a new debounce timeout
    this.selectionDebounceTimeout = setTimeout(async () => {
      // If already processing a selection, just return - the last selection will be processed when current one finishes
      if (this.isSelectingCell) {
        return;
      }

      try {
        this.isSelectingCell = true;
        const args = this.lastSelectionArgs;

        this.curSelectedRow = args.row1;
        this.curSelectedCol = args.col1;
        console.log("cell selected", args.row1, args.col1, args.row2, args.col2, this.formula);

        var from = await this.excels[this.PROJECT].simpleCellAddressToString({ sheet: this.sheetid, row: args.row1, col: args.col1 }, this.sheetid);
        var to = await this.excels[this.PROJECT].simpleCellAddressToString({ sheet: this.sheetid, row: args.row2, col: args.col2 }, this.sheetid);
        this.curSelectionReadable = "" + from + " : " + to;
        this.formula = await this.excels[this.PROJECT].getCellSerialized({ sheet: this.sheetid, row: args.row1, col: args.col1 });

        var commentrowindex = await this.excels[this.PROJECT].getCommentRowIndex({ sheet: this.sheetid, row: args.row1, col: args.col1 });
        if (commentrowindex == -1) commentrowindex - args.row1 - 1;
        var above = await this.excels[this.PROJECT].getCellSerialized({ sheet: this.sheetid, row: commentrowindex, col: args.col1 });

        this.outputrow = SheetHelper.getOutputRow(this.dataset);

        this.handleObjectSelectionByRows(this.curSelectedRow);
        this.updateThreeFrame();

        // Handle cell tools and UI elements
        var cellid = "";
        let element = this.hot?.getCell(args.row1, args.col1);
        this.showSheetsmenu = false;
        this.showOverlaymenu = false;
        this.showOverlaymenu1 = false;
        this.showothermenu = false;

        // tooltip
        if (args.col1 < 2) {
          if (element) {
            var clientRect = element.getBoundingClientRect();
            var clientX = clientRect.left;
            var clientY = clientRect.top;
            this.cellactionleft = clientX + 'px';
            this.cellactiontop = clientY - 45 + 'px';
            this.cellactionvisible = true;
            this.cellactionmenuvisible = false;
            this.cellinfovisible = false;
          }
        } else {
          this.cellactionvisible = false;
        }

        // cell info
        if (args.col1 >= 2) {
          if (element) {
            var clientRect = element.getBoundingClientRect();
            var clientX = clientRect.left;
            var clientY = clientRect.top;
            this.cellinfoleft = clientX + 'px';
            this.cellinfotop = clientY - 50 + 'px';

            if (this.dataset?.[args.row1]?.[0] === "#") {
              var newsnippet = null;
              var typ = this.dataset[args.row1 + 1][1];
              for (var i = 0; i < this.snippetManager.snippets.length; i++) {
                var snippet = this.snippetManager.snippets[i];
                if (snippet.name == typ) {
                  newsnippet = this.snippetManager.snippets[i];
                  break;
                }
              }

              this.celltooltip = newsnippet?.infos;
              this.cellinfovisible = true;
              this.celltooltipleft = this.cellinfoleft;
              this.celltooltiptop = (this.hot.rootElement.clientHeight - clientY + 206) + "px";
            } else {
              this.cellinfovisible = false;
            }
            this.cellinfomenuvisible = false;
          }
        }

        // cell tools
        if (above == "material" || above == "baseplane" || above == "filename") {
          var clientRect = element.getBoundingClientRect();
          var clientX = clientRect.left;
          var clientY = clientRect.top;
          this.celltoolsleft = clientX + 'px';
          this.celltoolstop = clientY + 30 + 'px';
          this.celltoolsvisible = true;
        } else {
          this.celltoolsvisible = false;
        }

        try {
          var meta = this.hot.getCellMeta(args.row1, args.col1).stylemeta;
          if (meta) {
            this.color1 = meta.background;
            this.color2 = meta.color;
          }
        } catch (error) {
        }

        this.formulaChange(this.formula);

      } finally {
        this.isSelectingCell = false;
      }
    }, 100); // 100ms debounce delay
  }

  async showcelltools() {
    // selected cell is material? .... TODO: auch fr obere zellen (suchen nach headline)
    var commentrowindex = await this.excels[this.PROJECT].getCommentRowIndex({ sheet: this.sheetid, row: this.curSelectedRow, col: this.curSelectedCol });
    if (commentrowindex == -1) commentrowindex - this.curSelectedRow - 1;

    var above = await this.excels[this.PROJECT].getCellSerialized({ sheet: this.sheetid, row: commentrowindex, col: this.curSelectedCol });
    var selected = await this.excels[this.PROJECT].getCellSerialized({ sheet: this.sheetid, row: this.curSelectedRow, col: this.curSelectedCol });

    if (above == "material") {
      this.showmaterialsDialog(this.baseScene, selected);
    }
    if (above == "baseplane") {
      this.showshapeDialog();
    }
    if (above == "filename") {
      // todo: rowtype statt dateiendung prüfen
      var cell = await this.excels[this.PROJECT].getCellSerialized({ sheet: this.sheetid, row: this.curSelectedRow, col: this.curSelectedCol });
      var filter = "";
      if (cell?.split('?')[0].toLowerCase().endsWith('.glb') ||
        cell?.split('?')[0].toLowerCase().endsWith('.gltf'))
        filter = "gltf";
      if (cell?.split('?')[0].toLowerCase().endsWith('.png') ||
        cell?.split('?')[0].toLowerCase().endsWith('.jpg') ||
        cell?.split('?')[0].toLowerCase().endsWith('.jpeg') ||
        cell?.split('?')[0].toLowerCase().endsWith('.svg'))
        filter = "png";


      this.showfilesDialog(filter, true);
    }

  }

  async cellinput(event) {
    console.log("You entered: ", event.target.value);
    var cell = { row: this.selectedRange[0], col: this.selectedRange[1], sheet: this.sheetid };
    this.cellformulas[this.selectedRange[0]][this.selectedRange[1]] = event.target.value;

    var val = event.target.value;
    var v = null;
    var f = parseFloat(val);
    if (isNaN(f))
      v = val
    else
      v = f;

    // angezeigte Daten aktualisieren
    this.cellformulas[cell.row][cell.col] = v;

    this.sheetChange(cell, v, v);
  }

  /// HANDONSTABLE
  cellinfovisible = false;
  afterOnCellMouseOver = async (event, coords, TD) => {

  }
  afterOnCellMouseOut = async (event, coords, TD) => {
    //  console.log("afterOnCellMouseOut", event, coords, TD);
  }

  copiedformulas: string;
  cutcopyRange: any;
  afterCopy = async (data) => {
    var sr = this.selectedRange[0];
    var er = this.selectedRange[2];
    if (this.selectedRange[2] < this.selectedRange[0]) {
      sr = this.selectedRange[2];
      er = this.selectedRange[0];
    }
    var sc = this.selectedRange[1];
    var ec = this.selectedRange[3];
    if (this.selectedRange[3] < this.selectedRange[1]) {
      sc = this.selectedRange[3];
      ec = this.selectedRange[1];
    }


    // Ensure start/end values are non-negative
    sr = Math.max(0, sr);
    er = Math.max(0, er);
    sc = Math.max(0, sc);
    ec = Math.max(0, ec);

    var cellrange = {
      start: { row: sr, col: sc, sheet: this.sheetid },
      end: { row: er, col: ec, sheet: this.sheetid }
    };
    // var cellrange = {
    //   start: { row: this.selectedRange[0], col: this.selectedRange[1], sheet: this.sheetid },
    //   end: { row: this.selectedRange[2], col: this.selectedRange[3], sheet: this.sheetid }
    // };
    this.cutcopyRange = cellrange;
    var copyresult = await this.excels[this.PROJECT].copy(cellrange);
    this.keydown = null;
    return true;

  }
  isCutting = false;
  cutvalues: any;
  beforeCut = (dat, coords) => {
    console.log("beforecut", dat, coords);
    this.isCutting = true;
    return true;
  }
  afterCut = async (data, coords) => {
    console.log("aftercut", data, coords);

    // Ensure selected range values are non-negative
    this.selectedRange[0] = Math.max(0, this.selectedRange[0]);
    this.selectedRange[1] = Math.max(0, this.selectedRange[1]);
    this.selectedRange[2] = Math.max(0, this.selectedRange[2]);
    this.selectedRange[3] = Math.max(0, this.selectedRange[3]);
    var cellrange = {
      start: { row: this.selectedRange[0], col: this.selectedRange[1], sheet: this.sheetid },
      end: { row: this.selectedRange[2], col: this.selectedRange[3], sheet: this.sheetid }
    };
    this.keydown = null;
    this.cutcopyRange = cellrange;
    // TODO: sonderfaelle

    // copy 2d array from cellformulas to cutvalues
    const startRow = this.selectedRange[0];
    const endRow = this.selectedRange[2];
    const startCol = this.selectedRange[1];
    const endCol = this.selectedRange[3];
    this.cutvalues = [];

    //var test = this.cellformulas[this.selectedRange[0]][this.selectedRange[1]];
    //this.cutvalues = JSON.parse(JSON.stringify(await this.excels[this.PROJECT].getRangeSerialized(cellrange)));
    console.log('afterCut cutvalues', this.cutvalues);
    var cutresult = await this.excels[this.PROJECT].cut(cellrange);

    //  var test = await this.excels[this.PROJECT].paste({ row: 10, col: 2, sheet: this.sheetid });


    var cellranget = {
      start: { row: 10, col: 2, sheet: this.sheetid },
      end: { row: 15, col: 5, sheet: this.sheetid }
    };
    var vals = await this.excels[this.PROJECT].getRangeSerialized(cellranget);
    console.log('vals', vals);

    this.isPasting = true;
    this.isCutting = true;
    return true;
  }

  pastedresult = null;
  isPasting = false;
  beforePaste = (dat, coords) => {
    console.log("beforePaste", this.isCutting, dat, coords, this.cutvalues);
    this.isPasting = true;

    return true;
  }
  afterPaste = async (data, coords) => {

    console.log("afterPaste", data, coords, this.cutvalues);
    this.keydown = null;
    // todo: olddata
    var olddata = null;
    var topleft = { row: this.selectedRange[0], col: this.selectedRange[1], sheet: this.sheetid };

    try {
      if (this.isCutting && false) {
        pasteresult = await this.excels[this.PROJECT].paste(topleft); // nach cut seltsamerweise "nothing to paste"
        //        this.dataset[pasteresult[i].address.row][pasteresult[i].address.col] = name;
        this.isCutting = false;
        this.isPasting = false;
        navigator.clipboard.writeText('').then(function () { // TTODO: ggf "null" workaround, so das keine leeren zellen gepastet werden können
        }, function (err) {
        });
        this.cutvalues = null;
        //  console.log('pasteresult', pasteresult, formulas);
        return;
      }
      // fehler=
      var pasteresult = null; //
      // try {
      pasteresult = await this.excels[this.PROJECT].paste(topleft); // nach cut seltsamerweise leer

      //} catch (err1) {

      //      }


      console.log('pasteresult', pasteresult);
      this.pastedresult = [];
      for (var i = 0; i < pasteresult?.length; i++) {
        var s2 = await this.excels[this.PROJECT].getCellSerialized(pasteresult[i].address);

        // replace name in first col -------------------
        if (pasteresult[i][0] && pasteresult[i][1]) {
          pasteresult[i][0] = pasteresult[i][1] + (topleft.row + i);
        }
        this.dataset[pasteresult[i].address.row][pasteresult[i].address.col] = pasteresult[i].newValue;
        this.cellformulas[pasteresult[i].address.row][pasteresult[i].address.col] = s2;
        this.dataset[pasteresult[i].address.row][pasteresult[i].address.col] = pasteresult[i].newValue;
        if (pasteresult[i].address.col == 0 && pasteresult[i].newValue != '#') {
          var t = await this.excels[this.PROJECT].getCellSerialized({ sheet: pasteresult[i].address.sheet, row: pasteresult[i].address.row, col: 1 })

          var t = Utils.remove_numbers_at_the_end(pasteresult[i].newValue);
          var name = t + (pasteresult[i].address.row + 1);
          this.excels[this.PROJECT].setCellContents({ sheet: pasteresult[i].address.sheet, row: pasteresult[i].address.row, col: 0 }, name);
          this.cellformulas[pasteresult[i].address.row][pasteresult[i].address.col] = name;
          this.dataset[pasteresult[i].address.row][pasteresult[i].address.col] = name;
        }
        // ---------

        //        this.dataset[this.pastedresult.address.row][this.pastedresult.address.col] = await this.excels[this.CONFIGURATION].getValueFromCell(this.pastedresult.address);

        console.log('cellformulas set', s2)

      }

      this.applyCellMeta();
      this.jsongui = await this.excels[this.PROJECT].createJsonGUI(this.sheetid);
      await this.updateModel(pasteresult, olddata, this.scene);
      if (this.isEditMode)
        this.hot.render();

    }
    catch (e) {
      console.warn(e);
      console.log('external copy?! ');
      try {

        // externe kopie => cell updates
        for (var i = parseInt(this.selectedRange[0]); i <= parseInt(this.selectedRange[2]); i++) {
          for (var j = parseInt(this.selectedRange[1]); j <= parseInt(this.selectedRange[3]); j++) {
            var di = i - parseInt(this.selectedRange[0]);
            var dj = j - parseInt(this.selectedRange[1]);
            if (data[di][dj] != null) {
              //   this.cellformulas[i][j] = data[di][dj]; // TODO: formeln copy format von excel?
              var exportedchagnes = await this.excels[this.PROJECT].setCellContents({ sheet: this.sheetid, row: i, col: j }, data[di][dj]);

              for (var k = 0; k < exportedchagnes.length; k++) {
                var s2 = await this.excels[this.PROJECT].getCellSerialized(exportedchagnes[k].address);
                this.cellformulas[exportedchagnes[k].address.row][exportedchagnes[k].address.col] = s2;
                this.dataset[exportedchagnes[k].address.row][exportedchagnes[k].address.col] = exportedchagnes[k].newValue;
              }
              //this.dataset[i][j] = data[di][dj];
            }

          }
        }
      } catch (err) {
        console.error(err);
      }

      ///  this.sheetChange(topleft, this.cellformulas);

    }
    if (this.isEditMode)
      this.hot.render();

    this.isPasting = false;
    this.isCutting = false;

    this.pastedresult = null;
    return true;
  }
  selectedRange: any;
  afterSelectionEnd = async (y, x, y2, x2) => {
    // TODO: strg-Taste
    this.selectedRange = [y, x, y2, x2];
    // console.log('after selection end ', y, x, y2, x2)
    //    this.info = "cell:" + a + " " + b + " " + c + " " + d;
    this.handleObjectSelectionByRows(this.curSelectedRow);
    this.refs = [];
    this.applyCellMeta();
  }

  beforeCellMouseDown = (a, b) => {
    console.log('beforeOnCellMouseDown', this.editcell, a, b);

    if (this.editcell) {
      var ce = this.hot.getActiveEditor();
      var v = ce.getValue();

      if (v.endsWith('=') || v.endsWith('+') || v.endsWith('-') || v.endsWith('*') || v.endsWith('/') || v.endsWith('&') || v.endsWith('(') || v.endsWith('$') || v.endsWith(this.lastcellrefstr)) {
        this.lastcellrefstr = Utils.indices2cellaname(b.col, b.row);
        ce.setValue(v + this.lastcellrefstr);
        // Stop event propagation
        a.stopImmediatePropagation();
      } else {
        // First commit the current value
        this.hot.setDataAtCell(this.editcell.row, this.editcell.col, v);
        // Then finish editing
        ce.finishEditing(true);
      }
    }
  }
  beforeChange = (a) => {
    //console.log('beforeChange', a)
    this.keydown = null;
  }
  afterChange = async (a) => {

    this.editcell = null;

    if (this.autofilling || this.isPasting || this.isCutting) // || iscopying TODO??? warum hier return???
      return;
    console.log("after change ", a)

    if (a == null) return;

    var topleft = { row: a[0][0], col: a[0][1], sheet: this.sheetid };
    //  if (!this.isPasting) this.excels[this.PROJECT].setCellContents(topleft, a[0][3]);

    // änderung zu 2d array TODO: geht vermutlich nicht immer
    var newdata = [];
    var olddata = [];
    // 0:    (4)[33, 2, 'radiusTop', null]
    // 1:    (4)[33, 3, 'radiusBottom', null]
    // 2:    (4)[34, 2, 66, null]
    // 3:    (4)[34, 3, 40, null]
    var r = []; var r2 = [];
    var currow = topleft.row;
    for (var i = 0; i < a.length; i++) {
      if (a[i][0] == currow) {
        if (a[i][3] == null) a[i][3] = '';
        r.push(a[i][3]);
        //    r2.push(a[i][2]);
        olddata.push({ address: { row: a[i][0], col: a[i][1], sheet: this.sheetid }, oldValue: a[i][2] });
      }
      else {
        newdata.push(r);
        r = [];
        currow = a[i][0];
        // neue zeile
        if (a[i][3] == null) a[i][3] = '';
        r.push(a[i][3]);
        olddata.push({ address: { row: a[i][0], col: a[i][1], sheet: this.sheetid }, oldValue: a[i][2] });
      }
    }
    newdata.push(r);

    //////////////
    // snippet?
    if (a?.length == 1) {
      try {

        var row = a[0][0];
        var col = a[0][1];
        var typ = a[0][3];
        var cell = { row: a[0][0], col: a[0][1], sheet: this.sheetid };
        var meta = this.hot.getCellMeta(cell.row, cell.col);
      } catch (error) {

      }

      // ---------------- snippet ----------------
      if (meta?.type == "autocomplete") {
        console.log('autocomplete fll');

        var newsnippet = null;
        for (var i = 0; i < this.snippetManager.snippets.length; i++) {
          var snippet = this.snippetManager.snippets[i];
          if (snippet.name == typ) {
            newsnippet = this.snippetManager.snippets[i];
            break;
          }
        }
      }

      // sheet?
      if (typ?.indexOf("sheet: ") >= 0) {
        // get snippet from excel
        var sheetname = typ.split(": ")[1];
        var sheetid = await this.excels[this.PROJECT].getSheetId(sheetname);
        newsnippet = await this.excels[this.PROJECT].getSnippet(sheetname);
      }
      // TODO: subproject?

      if (newsnippet) {
        // TODO: könnte ggf auch andere Zellen ändern?
        // primitive,sheet
        if (this.isInList(this.autocompleteListPrimitives, typ) || typ.indexOf("sheet: ") >= 0) {
          var outputid = typ + "" + (parseInt(row) + 2);
          this.dataset[row][0] = '#';
          this.cellformulas[row][0] = '#';
          this.dataset[row + 1][0] = outputid;
          this.cellformulas[row + 1][0] = outputid;
          var exportedchanges = await this.excels[this.PROJECT].setCellContents({ sheet: this.sheetid, row: row, col: 0 }, '#');
          var exportedchanges = await this.excels[this.PROJECT].setCellContents({ sheet: this.sheetid, row: row + 1, col: 0 }, outputid);
        }
        // control
        if (this.isInList(this.autocompleteListControls, typ)) {
          var outputid = typ + "" + (parseInt(row) + 1);
          this.dataset[row][0] = outputid;
          this.cellformulas[row][0] = outputid;
          var exportedchanges = await this.excels[this.PROJECT].setCellContents({ sheet: this.sheetid, row: row, col: 0 }, outputid);
        }

        console.log('call sheetshange ')
        this.sheetChange(cell, newsnippet.values, olddata, true);

        this.applyCellMeta();
        return; // automplete dn
      }

    }
    // ---------------- snippet ende ----------------


    /// normale änderung ----------------------------------------

    // floats parsen
    for (var i = 0; i < a?.length; i++) {
      var cell = { row: a[i][0], col: a[i][1], sheet: this.sheetid };
      var val = a[i][3];
      var v = null;
      var f = parseFloat(val);
      if (isNaN(f))
        v = val
      else
        v = f;
      // angezeigte Daten aktualisieren, nötig?
      //      this.cellformulas[cell.row][cell.col] = v;
    }

    var topleft = { row: a[0][0], col: a[0][1], sheet: this.sheetid };
    await this.sheetChange(topleft, newdata, olddata, false, false);
    console.log('sheetChange from afterchange', olddata, newdata)
    if (this.isEditMode)
      this.hot?.render();
  }

  // TODO: olddata vorher bei allen setzen, weil das auch elemente löschen kann
  async sheetChange(topleft, data, olddata, isSnippet = false, debounce = true) {
    if (this.iscreating) return;
    this.processing = true;
    this.processingMessage = 'Applying changes...';
    try {
      //  console.log('sheetChange', topleft, data);

      // TODO: alle sammeln?
      let data1 = {
        topleft: topleft,
        data: data,
        olddata: olddata,
        isSnippet: isSnippet
      }

      if (debounce)
        this.valueSubject.next(data1); // TODO: achtung! mehrere änderungen hinterneinander werden "verschluckt" nur bei änderung von gui bzw. gleichem param, sonst immer
      else
        await this.sheetChangeWrapper(data1)
    } finally {
      this.processing = false;
      this.processingMessage = '';
    }
  }

  async sheetChangeWrapper(datas) {
    var topleft = datas?.topleft;
    var data = datas?.data;
    var olddata = datas?.olddata;

    if (!datas.topleft) return;
    //   if (data) {
    // console.log('sheetChangeWrapper data', datas);
    var isSnippet = datas?.isSnippet;
    // daten entfernt
    // if (topleft?.col == 1 && !data) {
    //   var outputrow = SheetHelper.getOutputRow(this.dataset)
    //   if (topleft.row < outputrow) {
    //   } else {
    //   }
    //   //   return;
    // }

    // snippets PLatzhalter durhc Zellen ersetzen
    if (isSnippet) {
      try {
        if (data) {

          var replacedData = JSON.parse(JSON.stringify(data));
          for (var i = 0; i < data.length; i++) {
            for (var j = 0; j < data[i].length; j++) {
              if (data[i][j]) {
                // bisher Zelle Spalte -1 standard ersatz für ##
                if (typeof data[i][j] === 'string')
                  if (data[i][j].indexOf("##") > -1) {
                    var cellstr = await this.excels[this.PROJECT].simpleCellAddressToString({ sheet: this.sheetid, row: i + topleft.row, col: j + topleft.col - 1 }, this.sheetid);
                    replacedData[i][j] = data[i][j].replace(/##/g, cellstr);
                    //   data[i][j] = data[i][j].replaceAll('##', cellstr);


                  }
              }
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    }

    if (!replacedData) replacedData = datas.data;


    // TODO: nicht anwenden bei copy pastE?!
    if (this.pastedresult) {

      //      this.pastedresult.push({ address: pasteresult[i].address, val: s2 });
    }
    else {

      if (this.isMainConfig) {
        var exportedchanges = await this.excels[this.PROJECT].setCellContents(topleft, replacedData);
        // update hadsontable
        if (exportedchanges)
          for (var j = 0; j < exportedchanges.length; j++) {
            var change = exportedchanges[j];
            var cellAddress = change.address;
            var cellValue = change.newValue;
            // console.log('update handasonstable ', cellAddress, cellValue);
            if (cellAddress) {
              if (cellValue.value) {

                this.dataset[cellAddress.row][cellAddress.col] = cellValue.value;
              }
              else
                this.dataset[cellAddress.row][cellAddress.col] = cellValue;
            }
          }
        if (Array.isArray(data)) {
          for (var i = 0; i < replacedData.length; i++) {
            for (var j = 0; j < replacedData[i].length; j++) {
              if (replacedData[i][j] != null) {
                this.cellformulas[i + topleft.row][j + topleft.col] = replacedData[i][j]; // TODO: formeln copy format von excel?
              }
            }
          }

        }
        this.applyCellMeta();
        if (exportedchanges)
          for (var j = 0; j < exportedchanges.length; j++) {
            var change = exportedchanges[j];
            var cellAddress = change.address;
            var cellValue = change.newValue;
            // console.log('update handasonstable ', cellAddress, cellValue);
            // if (cellAddress?.col == 2 || cellAddress?.col == 3) {
            try {
              var m = this.hot.getCellMeta(cellAddress.row, cellAddress.col);

              this.hot.setCellMeta(cellAddress.row, cellAddress.col, 'className', "pulsing " + m.className);

              if (cellValue.value) {
                this.hot.setCellMeta(cellAddress.row, cellAddress.col, 'className', "error");

              } else {

                this.hot.removeCellMeta(cellAddress.row, cellAddress.col, 'className');
                this.hot.setCellMeta(cellAddress.row, cellAddress.col, 'value', cellValue);
              }
            } catch (error) {

            }
            //}

          }

        if (this.isEditMode)
          this.hot.render();
        // update model ------------------------

        try {
          console.log('update model')
          await this.updateModel(exportedchanges, olddata, this.scene);
        } catch (e) {
          console.error(e);
        }

        // update controls
        // if (topleft.col != 2) {
        try {
          console.log('update controls')
          await this.updateControls(exportedchanges, topleft);
        } catch (e) {
          console.error(e);
        }

        // var outputrow = this.getOutputRow(this.dataset);#
        // if (datas < outputrow) {
        //   this.excels[this.project].createJsonGUI(this.sheetid, this.dataset, this.cellformulas);
        // }
        //        }
      }

      else {
        // subconfig update
        var exportedchanges = await this.excels[this.selectedSubprojectID].setCellContents(topleft, replacedData);
        if (exportedchanges) {

          for (var j = 0; j < exportedchanges.length; j++) {
            var change = exportedchanges[j];
            var cellAddress = change.address;
            var cellValue = change.newValue;
            this.dataset2[cellAddress.row][cellAddress.col] = cellValue;

            // 
            var outputrow = SheetHelper.getOutputRow(this.dataset2);
            for (var y = 1; y < outputrow; y++) {
              for (var k = 0; k < this.selectedNode.userData.commentrow.length; k++)
                if (this.dataset2[y][0] != "")
                  if (this.dataset2[y][0] == this.selectedNode.userData.commentrow[k]) {
                    this.selectedNode.userData.row[k] = this.dataset2[y][2];
                  }
            }
          }
          await this.updateModel(exportedchanges, olddata, this.selectedNode);
        }
      }
    }

    this.updateThreeFrame();
    this.changesQueue = [];
  }


  showcodemenu = false;
  addCodeSnippet(s: any) {
    if (!this.project.scriptcode) this.project.scriptcode = "";
    this.project.scriptcode += s.code;
  }


  async addProject(findemptyrows = false, insertpos = null) {
    const dialogRef = this.dialog.open(DialogsubprojectComponent, {
      width: '1280px',
      //   data: { name: this.name, animal: this.animal },
    });

    dialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed', result);
      if (result) {
        if (result.publicCategoryID) {
          var projectid = result.id;
          var pr = (await this.afs.doc("/projectspublic/" + projectid).get().toPromise());
          var newconfig = pr.data() as any;
          var project = newconfig.userid + '_' + projectid;
        } else {

          var projectid = result.id;
          var project = this.customerid + '_' + projectid
          var pr = (await this.afs.doc("/projects/userprojects/" + this.customerid + "/" + projectid).get().toPromise());
          var newconfig = pr.data() as any; //.valueChanges().subscribe(data => {
          newconfig.id = pr.id;
        }


        if (!this.project.configurations) this.project.configurations = {};
        this.project.configurations[this.curSelectedRow] = newconfig;

        if (this.excels[project]) {
          //  return this.excels[configuration];
        } else {
          var toload = [];
          toload.push(project);

          // alle unterkonfigs
          await traverse(newconfig.configmodel).forEach(function (value) {
            if (typeof (value) === "object" && value?.subproject) {
              toload.push(value.subproject);
            }
          });
          // delete duplicates from array
          toload = toload.filter(function (item, pos) {
            return toload.indexOf(item) == pos;
          }).sort();
          console.log("project data", pr);
          var promises = [];
          for (var i = 0; i < toload.length; i++) {
            const w = new Worker(new URL('./../app.worker', import.meta.url), { type: 'module' });
            this.workers.push(w);
            this.excels[toload[i]] = Comlink.wrap(w) as any;
            var p = this.excels[toload[i]].load(toload[i], newconfig.sheets); // TODO sheets von unterconfig
            promises.push(p);
          }

          var t0 = performance.now();
          var promisesresult = await Promise.all(promises).catch(reason => {
            console.error(reason)
          });
          console.log('promisesresult', promisesresult);
          var t1 = performance.now();
          console.log("webworker loads took " + (t1 - t0) + " milliseconds.");
        }

        // this.dataset = promisesresult[0].cells;
        // this.datasetids = promisesresult[0].datasetids;
        // this.cellformulas = promisesresult[0].cellvalues;
        //this.CONFIGURATION = configuration;
        //var dataset = promisesresult[0].cells;
        //   this.applyCellMeta();

        // var jsongui = await this.excels[project].createJsonGUI(0);
        // var newsnippet = this.projectGUI2Snippet(jsongui, projectid, this.curSelectedRow);

        var sheetname1 = await this.excels[project].getSheetNames();
        var newsnippet = await this.excels[project].getSnippet(sheetname1[0]);
        var outputid = "project" + "" + (this.curSelectedRow + 2);
        // 
        var exportedchanges = await this.excels[this.PROJECT].setCellContents({ sheet: this.sheetid, row: this.curSelectedRow, col: 0 }, '#');
        var exportedchanges = await this.excels[this.PROJECT].setCellContents({ sheet: this.sheetid, row: this.curSelectedRow + 1, col: 0 }, outputid);

        newsnippet.values[0][0] = '';
        newsnippet.values[1][0] = 'project: ' + project; // deprecated + projectid; // TODO:ggf mit userid? also project?
        /// TODO: insertpoint, empty insertrow
        var cell = { row: this.curSelectedRow, col: 1, sheet: this.sheetid };
        this.sheetChange(cell, newsnippet.values, newsnippet.values, true);
        this.dataset[this.curSelectedRow][0] = '#';
        this.cellformulas[this.curSelectedRow][0] = '#';
        this.dataset[this.curSelectedRow + 1][0] = outputid;
        this.cellformulas[this.curSelectedRow + 1][0] = outputid;

        this.applyCellMeta();
        this.createModel(this.scene, this.dataset, this.PROJECT);

        // TODO: add project model
        this.loadSheet = false;
        //    console.log("jsongui", this.jsongui);
      }
    });
  }

  openLink(link) {
    window.open(link, "_blank");
  }

  openDisclaimer() {
    console.log('openDisclaimer');
    const dialogRef = this.dialog.open(DialogdisclaimerComponent, {
      width: '880px',
      data: { disclaimer: this.project.disclaimer },
    });

  }

  async addPrimitive(typ: string, param = null) {
    var newsnippet = null;
    for (var i = 0; i < this.snippetManager.snippets.length; i++) {
      var snippet = this.snippetManager.snippets[i];
      if (snippet.name == typ) {
        newsnippet = this.snippetManager.snippets[i];
        break;
      }
    }

    // Handle copy type
    if (typ === "copy" && this.sceneobjectSelected) {
      const sourceObjectId = this.sceneobjectSelected.name;
      const row = this.curSelectedRow;
      const outputid = typ + (row + 2);

      // Set the output ID
      this.dataset[row][0] = '#';
      this.cellformulas[row][0] = '#';
      this.dataset[row + 1][0] = outputid;
      this.cellformulas[row + 1][0] = outputid;

      // Update Excel
      await this.excels[this.PROJECT].setCellContents({ sheet: this.sheetid, row: row, col: 0 }, '#');
      await this.excels[this.PROJECT].setCellContents({ sheet: this.sheetid, row: row + 1, col: 0 }, outputid);

      // Create copy rows
      const copyValues = [
        ["", "source"],
        ["copy", sourceObjectId]
      ];

      const cell = { row: row, col: 1, sheet: this.sheetid };
      await this.sheetChange(cell, copyValues, copyValues, true);

      this.applyCellMeta();
      return;
    }

    // sheet?
    if (typ.indexOf("sheet: ") >= 0) {
      // get snippet from excel
      var sheetname = typ.split(": ")[1];
      var sn = await this.excels[this.PROJECT].getSheetNames();
      var sheetid = await this.excels[this.PROJECT].getSheetId(sheetname);
      newsnippet = await this.excels[this.PROJECT].getSnippet(sheetname);
    }

    if (newsnippet) {
      var row = this.curSelectedRow;
      // TODO: könnte ggf auch andere Zellen ändern?
      var outputid = typ + "" + (row + 2);
      // primitive,sheet
      if (this.isInList(this.autocompleteListPrimitives, typ) || typ.indexOf("sheet: ") >= 0) {
        this.dataset[row][0] = '#';
        this.cellformulas[row][0] = '#';
        this.dataset[row + 1][0] = outputid;
        this.cellformulas[row + 1][0] = outputid;
        var exportedchanges = await this.excels[this.PROJECT].setCellContents({ sheet: this.sheetid, row: row, col: 0 }, '#');
        var exportedchanges = await this.excels[this.PROJECT].setCellContents({ sheet: this.sheetid, row: row + 1, col: 0 }, outputid);
      }

      if (param)
        newsnippet.values[1][1] = param;

      if (typ === 'profile') {
        // Add default values for profile primitive
        newsnippet.values[1][2] = '(0,0);(100,0);(100,100);(0,100)'; // Default profile points
        newsnippet.values[1][3] = '100'; // Default extrusion depth
      }

      var cell = { row: row, col: 1, sheet: this.sheetid };
      this.sheetChange(cell, newsnippet.values, newsnippet.values, true);

      this.applyCellMeta();
    }
  }
  async addGui(typ: string, param = null) {
    var newsnippet = null;
    for (var i = 0; i < this.snippetManager.snippets.length; i++) {
      var snippet = this.snippetManager.snippets[i];
      if (snippet.name == typ) {
        newsnippet = this.snippetManager.snippets[i];
        break;
      }
    }

    // sheet?
    if (typ.indexOf("sheet: ") >= 0) {
      // get snippet from excel
      var sheetname = typ.split(": ")[1];
      var sheetid = await this.excels[this.PROJECT].getSheetId(sheetname);
      newsnippet = await this.excels[this.PROJECT].getSnippet(sheetname);
    }
    // TODO: subproject
    if (newsnippet) {
      var row = this.curSelectedRow;
      // TODO: könnte ggf auch andere Zellen ändern?
      var outputid = typ + "" + (row + 1);

      // control
      if (this.isInList(this.autocompleteListControls, typ)) {
        this.dataset[row][0] = outputid;
        this.cellformulas[row][0] = outputid;
        var exportedchanges = await this.excels[this.PROJECT].setCellContents({ sheet: this.sheetid, row: row, col: 0 }, outputid);

        if (param) {
          newsnippet.values[0][1] = param;
        }

      }

      var cell = { row: row, col: 1, sheet: this.sheetid };
      this.sheetChange(cell, newsnippet.values, newsnippet.values, true);

      this.applyCellMeta();
    }
  }
  beforeAutoFillRange: SimpleCellRange;
  autofilling = false;
  beforeAutoFill = (changes, source) => {
    this.beforeAutoFillRange = this.selectedRange;
    console.log('beforeAutoFill changes', changes, 'source', source, 'selectedrange', this.selectedRange);
    this.autofilling = true;
  }
  afterAutoFill = async (startcellfill, endcellfill) => {
    // reihenfolger events(hf): beforeautofill, beforechange, afterchagne, (verzögert sheetchange), afterauffill,  sheetchange decoubnce,
    console.log('afterAutoFill startcellfill', startcellfill, 'endcellfill', endcellfill, 'beforefillautrange', this.beforeAutoFillRange);
    console.log('afterAutoFill startcellfill', startcellfill, 'endcellfill', endcellfill, 'selectedrange', this.selectedRange);

    var srcrange = {
      start: { sheet: this.sheetid, row: this.beforeAutoFillRange[0], col: this.beforeAutoFillRange[1] }, end: { sheet: this.sheetid, row: this.beforeAutoFillRange[2], col: this.beforeAutoFillRange[3] }
    };
    var src = await this.excels[this.PROJECT].getRangeSerialized(srcrange);
    var srct = src[0].map((_, colIndex) => src.map(row => row[colIndex])); // transpose
    var targetrange = { start: { sheet: this.sheetid, row: startcellfill.row, col: startcellfill.col }, end: { sheet: this.sheetid, row: endcellfill.row, col: endcellfill.col } };
    var targetrowscount = targetrange.end.row - targetrange.start.row + 1;
    var targetcolscount = targetrange.end.col - targetrange.start.col + 1;
    var fill = [];
    for (var i = 0; i < targetrowscount; i++) fill.push([])

    // NaN Fehler,von llinks nach rechts erweitern, tokenisierung
    for (var i = 0; i < targetcolscount; i++) {
      var xs = srct[i].map((e, i) => { return i }); // transpose
      var ys = srct[i];
      for (var j = 0; j < ys.length; j++)
        if (!isNaN(ys[j]))
          // str to float
          ys[j] = parseFloat(ys[j]);
      // TODO: tokenisierung, Cell-references
      // https://stackoverflow.com/questions/3370263/separate-integers-and-text-in-a-string


      var mb = Utils.findLineByLeastSquares(xs, ys);
      if (!isNaN(mb.m)) {
        var fillcol = [];
        for (var j = 0; j < targetrowscount; j++) {
          var autofillrowindex = xs[xs.length - 1] + 1 + j;
          var yi = mb.m * autofillrowindex + mb.b;
          if (isNaN(yi)) yi = ys[0]; /// ggf unterste zelle?
          fillcol.push(yi);
          fill[j].push(yi);
        }
      } else {
        fill = await this.excels[this.PROJECT].getFillRangeData(
          srcrange,
          targetrange
          //  { start: { sheet: this.sheetid, row: 0, col: 0 }, end: { sheet: this.sheetid, row: 1, col: 1 } },
          //  { start: { sheet: this.sheetid, row: 1, col: 1 }, end: { sheet: this.sheetid, row: 3, col: 3 } }
        );

      }


    }

    if (startcellfill.col == 0) {
      for (var j = 0; j < targetrowscount; j++) {
        var t = Utils.remove_numbers_at_the_end(fill[j][0]);
        var name = t + (startcellfill.row + j + 1);
        fill[j][0] = name;
      }
    }
    var r = await this.excels[this.PROJECT].setCellContents({ sheet: this.sheetid, row: startcellfill.row, col: startcellfill.col }, fill);
    this.sheetChange({ sheet: this.sheetid, row: startcellfill.row, col: startcellfill.col }, fill, fill);

    this.autofilling = false;
    if (this.isEditMode)
      this.hot.render();
  }

  setSelectionRange(input, selectionStart, selectionEnd) {
    if (input.setSelectionRange) {
      input.focus();
      input.setSelectionRange(selectionStart, selectionEnd);
    }
    else if (input.createTextRange) {
      var range = input.createTextRange();
      range.collapse(true);
      range.moveEnd('character', selectionEnd);
      range.moveStart('character', selectionStart);
      range.select();
    }
  }


  setCaretToPos(input, pos) {
    this.setSelectionRange(input, pos, pos);
  }
  keydown = null;
  editval = null;
  refcell = null;
  lastcellrefstr = null;
  lastcellvalue = "";
  refs: any;
  afterDocumentKeyDown = (e) => {
    //  console.log('afterDocumentKeyDown', e.key, this.editcell);
    try {
      if (this.editcell) {
        //  var t = this.hot.getDataAtCell(this.editcell.row, this.editcell.col);

        this.keydown = e.key;
        var ce = this.hot.getActiveEditor(); //(this.editcell.row, this.editcell.col);
        var v = ce.getValue();

        //      ce.enableFullEditMode();

        if (e.key == "Tab") {
          this.hot.selectCell(this.editcell.row, this.editcell.col + 1);
          this.refs = null;
          this.applyCellMeta();

          e.preventDefault();
        }

        if (v.endsWith('=') || v.endsWith('+') || v.endsWith('-') || v.endsWith('*') || v.endsWith('/') || v.endsWith('&') || v.endsWith('(') || v.endsWith('$') || v.endsWith(this.lastcellrefstr)) {
          if (e.key == 'ArrowUp' || e.key == 'ArrowDown' || e.key == 'ArrowLeft' || e.key == 'ArrowRight') {
            this.refcell = this.editcell;
            if (e.key == 'ArrowUp') this.refcell.row--;
            if (e.key == 'ArrowDown') this.refcell.row++;
            if (e.key == 'ArrowLeft') this.refcell.col--;
            if (e.key == 'ArrowRight') this.refcell.col++;
            if (v.endsWith(this.lastcellrefstr)) {
              // remove lastcellrefstr from v
              var lastl = this.lastcellrefstr.length;
              this.lastcellrefstr = Utils.indices2cellaname(this.refcell.col, this.refcell.row); // await this.excels[this.PROJECT].simpleCellAddressToString(this.refcell, this.sheetid);
              v = v.substring(0, v.length - lastl);
              ce.setValue(v + this.lastcellrefstr);

              this.refs = Utils.getCellReferences(v + this.lastcellrefstr);
              //          this.colorizeCellReferences(refs);
              this.applyCellMeta();
              //   return
            }
            else {
              this.lastcellrefstr = Utils.indices2cellaname(this.refcell.col, this.refcell.row); // await this.excels[this.PROJECT].simpleCellAddressToString(this.refcell, this.sheetid);
              ce.setValue(v + this.lastcellrefstr);
              this.refs = Utils.getCellReferences(v + this.lastcellrefstr);
              //    this.colorizeCellReferences(refs);
              this.applyCellMeta();

              // return;
            }

            v = ce.getValue();
            var txtarea = document.getElementsByClassName('handsontableInput')[0] as any;

            this.setCaretToPos(txtarea, v.length);
            e.preventDefault();
            e.stopImmediatePropagation();
          } else {
            this.refcell = null;
            this.applyCellMeta();
            return;
          }
        } else {
          if (e.key == 'ArrowLeft' || e.key == 'ArrowRight') {
            //  e.preventDefault();
            //      this.hot.selectCell(this.editcell.row, this.editcell.col);

            if (!v)
              this.hot.selectCell(this.editcell.row, this.editcell.col);

            this.refs = null;
            return;
          }
          if (e.key == 'ArrowUp' || e.key == 'ArrowDown') {
            // e.preventDefault();
            this.hot.selectCell(this.editcell.row, this.editcell.col);
            this.refs = null;
            return;
          }
          this.refs = null;
          this.applyCellMeta();
          if (e.key == 'Enter') {

          }
          else {
            e.stopImmediatePropagation();
          }
        }



      }
      else
        if (e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowLeft' && e.key != 'ArrowRight' && e.key != 'Enter') {
          var ce = this.hot.getActiveEditor();

          if (ce) {

            ce.enableFullEditMode();
            this.lastcellvalue = e.key;
            this.keydown = e.key;
            ce.setValue(this.lastcellvalue);
          }

        }
      if (e.key == 'Enter') {
        this.refs = null;
        this.applyCellMeta();
      }

    } catch (e) {
      console.warn("afterDocumentKeyDown", e);
    }
  }

  editcell = null; editcelltop = null;
  afterBeginEditing = async (row, col) => {
    try {
      console.log('afterBeginEditing', row, col);

      this.editcell = { sheet: this.sheetid, row: row, col: col };
      var el = document.getElementsByClassName('handsontableInputHolder')[0] as any;
      this.editcelltop = parseInt(el.style.top.replace("px", ""));

      // Get the current editor value
      const editor = this.hot.getActiveEditor();
      const currentValue = editor?.getValue() || '';

      // If we're starting with an equals sign, this is a formula - don't overwrite
      if (currentValue.startsWith('=')) {
        return;
      }

      // Only get serialized data if we're not in the middle of a paste operation
      // and not starting a formula
      if (!this.isPasting && !this.keydown?.startsWith('=')) {
        var serializeddata = await this.excels[this.PROJECT].getCellSerialized({ sheet: this.sheetid, row: row, col: col });
        if ((!this.keydown || this.keydown == "Enter" || this.keydown == "Tab") && serializeddata != "" && serializeddata != null) {
          this.dataset[row][col] = serializeddata;
          if (this.isEditMode) {
            editor?.setValue(serializeddata);
            this.hot.render();
            this.refs = Utils.getCellReferences(serializeddata);
            this.applyCellMeta();
          }
        }
      }
    } catch (e) {
      console.warn("afterBeginEditing", e);
    }
  }
  afterDeselect = () => {
    console.log('afterDeselect');
    this.editcell = null;
    this.keydown = null;
  }


  afterSetDataAtRowProp(changes, source) {
    console.log('afterSetDataAtRowProp', changes, source, this.selectedRange);
  }
  viewHelper: any; composer: any; outlinePass: any; renderPass: any;
  setupthreejs() {
    var threecontainer = document.getElementById("threecontainer");
    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, transparent: true } as any);
    this.rendererContainer.nativeElement.appendChild(this.renderer.domElement);

    this.renderer.setSize(threecontainer.clientWidth, threecontainer.clientHeight);
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.autoUpdate = true;
    this.renderer.shadowMap.needsUpdate = false;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    this.cameraManager = new CameraManager(this.scene, this.renderer, threecontainer);
    this.camera = this.cameraManager.camera;
    this.controls = this.cameraManager.controls;

    this.camera.position.z = 2500;
    this.camera.position.x = 1500;
    this.camera.position.y = 2000;

    this.clock = new THREE.Clock();
    this.camera.up.set(0, 0, 1);

    // Initialize interaction manager
    this.interactionManager = new InteractionManager(this.scene, this.camera, this.renderer, this.cursorType);
    this.cursorSubscription = this.interactionManager.cursorTypeChange.subscribe((cursorType: string) => {
      console.log('cursorType', cursorType);
      this.ngZone.run(() => {
        this.cursorType = cursorType;
      });

    });


  }

  zoomall() {
    //ThreeUtils.pointCameraTo(this.camera, this.controls, this.selectedObject);
    ThreeUtils.zoomObject(this.camera, this.controls, this.selectedObject);
    this.updateThreeFrame();
  }
  zoomallscene() {
    //ThreeUtils.pointCameraTo(this.camera, this.controls, this.selectedObject);
    ThreeUtils.zoomObject(this.camera, this.controls, this.scene.getObjectByName("root"));
    this.updateThreeFrame();
  }

  threecontainerwidth = 100;
  leftpanelwidth = 200;
  splitleft = 45;
  @ViewChild(SplitComponent) splitEl: SplitComponent
  @ViewChildren(SplitAreaDirective) areasEl: QueryList<SplitAreaDirective>
  // Add this as a class property
  private initialFrustumSize: number | null = null;

  async onResize(event: any) {
    console.log('onResize', event);
    if (this.isEditMode)
      this.leftpanelwidth = Math.round(this.splitEl.displayedAreas[0].size / 100.0 * window.innerWidth);
    else
      this.leftpanelwidth = 0;
    if (this.leftpanelwidth > 9000) this.leftpanelwidth = 0;
    this.threecontainerwidth = window.innerWidth - this.leftpanelwidth;
    var container = document.getElementById("threecontainer");

    // Handle isometric view if active
    if (this.camera instanceof THREE.OrthographicCamera) {
      const aspect = this.threecontainerwidth / container.clientHeight;
      const currentZoom = this.camera.zoom;

      // Store initial size only once when first switching to orthographic
      if (this.initialFrustumSize === null) {
        this.initialFrustumSize = this.camera.top - this.camera.bottom;
      }

      // Always use the initial size for calculations
      const size = this.initialFrustumSize;

      // Update all frustum planes using the initial size
      this.camera.left = -size * aspect / 2;
      this.camera.right = size * aspect / 2;
      this.camera.top = size / 2;
      this.camera.bottom = -size / 2;

      // this.camera.zoom = currentZoom * 0.5;
    } else {
      // Normal perspective mode
      this.camera.aspect = this.threecontainerwidth / container.clientHeight;
    }

    this.camera.updateProjectionMatrix();
    this.renderer.setSize(this.threecontainerwidth, container.clientHeight);
    this.renderer.autoClear = false;
    this.renderer.setClearColor(0x000000, 0.0);

    await this.setVars();

    this.splitleft = this.splitEl.displayedAreas[0].size;
    if (this.isEditMode)
      this.hot?.render();

    if (!this.isEditMode)
      this.splitleft = 0;
    // this.splitDragEnd({ sizes: [this.splitleft, 99 - this.splitleft] }).then(() => {
    //   this.splitDragEnd({ sizes: [this.splitleft, 100 - this.splitleft] });
    // });

    if (this.viewportGizmo)
      this.viewportGizmo.left = this.threecontainerwidth - 128; // hack

    this.updateThreeFrame();

    // menu
    if (window.innerWidth < 600) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }


  projecthelper: any;
  higlightProject() {
    try {
      if (this.projecthelper) {
        // console.log("remove projecthelper", this.projecthelper);
        this.scene.remove(this.projecthelper);
        this.projecthelper = null;
      }
      if (this.highlightproject) {
        this.highlightproject.updateMatrixWorld();
        this.highlightproject.updateMatrix();
        var box = ThreeUtils.getBBox(this.highlightproject);
        box = box.expandByScalar(10);
        var m2 = ThreeUtils.create3DMarker(box, 50, 5, 20, 0xff0000);
        this.projecthelper = m2;

        // Add edit button sprite
        const editSprite = this.createEditButtonSprite();

        // Position sprite at the center of the bounding box
        const center = new THREE.Vector3();
        box.getCenter(center);
        editSprite.position.copy(center);

        // Move sprite slightly forward of the box center
        const boxDepth = box.max.z - box.min.z;
        editSprite.position.z = center.z + (boxDepth / 2) + 5; // Just 5 units in front of the box surface

        // Adjust vertical position to be exactly at center
        editSprite.position.y = center.y;

        editSprite.renderOrder = 99999;
        editSprite.userData.isHelper = true;

        // Add sprite to project helper
        this.projecthelper.add(editSprite);

        this.projecthelper.renderOrder = 99999;
        this.projecthelper.userData.isHelper = true;

        this.scene.add(this.projecthelper);
      }
    }
    catch (err) {
      console.warn(err);
    }
  }

  // Add this helper method to create the edit button sprite
  private createEditButtonSprite(): THREE.Sprite {
    const canvas = document.createElement('canvas');
    canvas.width = 256;
    canvas.height = 128;
    const context = canvas.getContext('2d');

    // Clear canvas
    context.clearRect(0, 0, canvas.width, canvas.height);

    // Draw rounded rectangle background
    const radius = 20;
    context.beginPath();
    context.moveTo(radius, 0);
    context.lineTo(canvas.width - radius, 0);
    context.quadraticCurveTo(canvas.width, 0, canvas.width, radius);
    context.lineTo(canvas.width, canvas.height - radius);
    context.quadraticCurveTo(canvas.width, canvas.height, canvas.width - radius, canvas.height);
    context.lineTo(radius, canvas.height);
    context.quadraticCurveTo(0, canvas.height, 0, canvas.height - radius);
    context.lineTo(0, radius);
    context.quadraticCurveTo(0, 0, radius, 0);
    context.closePath();

    // Create gradient
    const gradient = context.createLinearGradient(0, 0, 0, canvas.height);
    gradient.addColorStop(0, '#4CAF50');
    gradient.addColorStop(1, '#45a049');

    context.fillStyle = gradient;
    context.fill();

    // Add text
    context.fillStyle = 'white';
    context.font = 'bold 64px Arial';
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillText('EDIT', canvas.width / 2, canvas.height / 2);

    // Create sprite
    const texture = new THREE.CanvasTexture(canvas);
    const spriteMaterial = new THREE.SpriteMaterial({
      map: texture,
      transparent: true,
      depthTest: false,
      depthWrite: false
    });

    const sprite = new THREE.Sprite(spriteMaterial);
    sprite.scale.set(20, 10, 1);

    return sprite;
  }

  async undo() {
    var changes = await this.excels[this.PROJECT].undo();
    this.changes2dataset(changes);
    this.hot.render();
    this.updateModel(changes, null, this.scene);
    this.updateThreeFrame();
  }
  async redo() {
    var changes = await this.excels[this.PROJECT].redo();
    this.changes2dataset(changes);
    this.hot.render();
    this.updateModel(changes, null, this.scene);
    this.updateThreeFrame();
  }

  changes2dataset(changes: any) {
    for (var i = 0; i < changes?.length; i++) {
      var change = changes[i];
      if (!change.address) continue;
      var sheet = change.address.sheet;
      var row = change.address.row;
      var col = change.address.col;
      var value = change.value;
      if (change.newValue)
        value = change.newValue;
      if (sheet == 0)
        this.dataset[row][col] = value;
    }
  }

  scenemousedown(e: any) {
    this.handleSceneEvent("MouseDown", e);
    this.mousebuttondown = true;
  }

  sceneobjectMouseOver = null;
  sceneobjectSelected = null;
  highlightproject: any = null;
  mousedrag = false;
  mousebuttondown = false;
  scenemousemove(e: any) {
    // mouseMOVE immer aufgerufen, nicht objektbezogen => sceneMouseMove  objectEnter objectSelected

    this.handleSceneEvent("MouseMove", e);
    if (this.mousebuttondown) this.mousedrag = true;
    // handlerootevent
    // params:  deltax deltay deltaz pos lastpos deltapos  xydelta xzdelta yzdelta
  }


  scenemouseup(e: any) {
    this.mousebuttondown = false;
    this.showObjectInfos = false;
    ThreeUtils.gltfNodeBox(null, this.scene);

    this.handleSceneEvent("MouseUp", e);
  }

  highlightNode: any;

  sceneobjectSceneSelected = null;
  overinfo = "";
  selectedprojectpath = "root^$0"; hoverObject = null;
  public cursorType = "default";

  handleSceneEvent(type: string, e: any) {
    //  console.log("handleSceneEvent", type, e);
    const canvasTarget = document.getElementById('threecontainer');
    var mouse = new THREE.Vector2();
    mouse.x = (e.layerX / canvasTarget.clientWidth) * 2 - 1;
    mouse.y = -(e.layerY / canvasTarget.clientHeight) * 2 + 1;
    var raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, this.camera);
    var intersects = raycaster.intersectObjects(this.scene.children);

    if (intersects.length > 0) {
      var obj = null;
      for (var i = 0; i < intersects.length; i++) {
        if (!intersects[i].object.userData.isHelper) {
          if (intersects[i].object.visible) {
            obj = intersects[i].object;
            break;
          }
        }
      }
      if (!obj) {
        this.highlightproject = nobj;
        console.log('!obj')
        this.higlightProject();
        this.cursorType = "default";
        this.updateThreeFrame();

        return; // todo: javascript wird hier nicht ausgewührt?
      }

      // widget
      if (this.isShiftDown && Object.keys(this.transformControl.object).length == 0 && obj.isMesh) {
        // Find the nearest project or sheet group parent
        let targetNode = obj;
        while (targetNode?.parent &&
          !targetNode.userData?.issubproject && // Check for project node
          !targetNode.userData?.issheet && // Check for sheet node
          targetNode.parent.name !== "root") {
          targetNode = targetNode.parent;
        }

        if (targetNode && (targetNode.userData?.issubproject || targetNode.userData?.issheet)) {
          this.transformControl.setSpace("local");
          this.transformControl.attach(targetNode);
          this.transformControl.enabled = true;
          this.transformControl.setMode('translate');
          this.scene.add(this.transformControl);
          this.updateThreeFrame();
        }
      }
      if (Object.keys(this.transformControl.object).length !== 0 && !this.isShiftDown) {
        this.transformControl.enabled = false;
        this.transformControl.detach(this.transformControl.object);
        console.log("detach", this.transformControl.object);
        this.scene.remove(this.transformControl);
      }


      // gltf infos
      if (ThreeUtils.isGltfNode(obj) && this.isControlDown || this.scenemode == "info") {
        try {

          this.showObjectInfos = true;

          var bb = ThreeUtils.gltfNodeBox(obj, this.scene);
          console.log("gltf obj", obj, ThreeUtils.isGltfNode(obj), obj.name, intersects[0].point);
          this.hoverObject = JSON.parse(JSON.stringify(obj)).object;
          var m = JSON.parse(JSON.stringify(obj.material));
          this.hoverObject.material = m;
          var m2 = JSON.parse(JSON.stringify(obj.position));
          this.hoverObject.position = m2;
          var normal = intersects[0].face?.normal;

          var w = Math.round(bb.max.x - bb.min.x);
          var h = Math.round(bb.max.y - bb.min.y);
          var d = Math.round(bb.max.z - bb.min.z);

          this.datasetsel = [
            ["Name", obj.name, "", ""],
            ["Position", intersects[0].point.x.toFixed(2), intersects[0].point.y.toFixed(2), intersects[0].point.z.toFixed(2)],
            ["Normal", normal.x.toFixed(2), normal.y.toFixed(2), normal.z.toFixed(2)],
            ["Size", w, h, d]
          ];
        } catch (e) {
          console.warn("gltf infos", e);
        }
        return;

      }

      // gltf node?
      while (obj?.userData?.isGltf) {
        obj = obj.parent;
        parent = obj;
      }

      // project?a
      if (obj?.userData?.path?.indexOf("project") > -1) {
        this.overinfo = obj.userData?.path;
        //      console.log("project", obj); //"root^$0#project33^$0#extrusion29" $sheetid^typrow
        var projectpath = "root^$" + this.sheetid + "#" + obj.userData.path.split("^")[1];// TODO: je nach aktueller wahl
        projectpath = projectpath.replace('$' + this.sheetid + '$' + this.sheetid, '$' + this.sheetid);
        var nobj = null;
        if (obj.parent.name == "root") {

          nobj = this.scene.getObjectByName(projectpath);
        }
        else {

          // übergeordnete projekte nicht selektiert?
          var nname = obj.parent.name.replace(this.selectedprojectpath, "");
          if (nname == "") return; // object bereits gewählt
          var n = "#" + nname.split('#')[1].split('^')[0];
          var nname2 = this.selectedprojectpath + n;
          if (!this.selectedprojectpath.endsWith("^$" + this.sheetid))
            nname2 = this.selectedprojectpath + "^$" + this.sheetid + n;
          nobj = this.scene.getObjectByName(nname2);
          if (nobj) {
            //this.selectedprojectpath = nname2;
            obj = nobj;
          }
        }

        //https://chowdera.com/2021/04/20210402055611792p.html
        this.highlightproject = nobj; //.parent;
        this.cursorType = "pointer";

      }
      else {
        if (obj?.type != "LineSegments")
          this.cursorType = "default";
        this.highlightproject = null;
      }

    }
    else {
      this.cursorType = "default";
      this.highlightproject = null;
    }

    this.higlightProject();


    //    if (type == "MouseMove" && this.sceneobjectMouseDown && this.sceneobjectMouseDown == obj) {
    if (type == "MouseMove" && this.sceneobjectSceneSelected) {
      // this.vba.deactivateMouse();
      // var jsfunctiondrag = this.sceneobjectSelected?.userData?.row[this.sceneobjectSelected?.userData?.commentrow.indexOf("Drag")];
      // if (jsfunctiondrag) {
      //   // TODO: es point,distance umrechnen aus Ebene = dragy dragz dragx
      //   // ebene zuvor transformieren oderunterknoten  hinzufügen damit transformationshierarchie stimmt
      //   // alternativ widget von threejs nutzen?
      //   // alternativ dragcontrol? https://threejs.org/docs/#examples/en/controls/DragControls

      //   var es = JSON.stringify(eventinfo);
      //   if (!es) es = "null";
      //   jsfunctiondrag = jsfunctiondrag.replace("event", es);
      //   var ud = this.sceneobjectSelected.userData;
      //   if (ud) ud = JSON.stringify(ud);
      //   else ud = "null";
      //   jsfunctiondrag = jsfunctiondrag.replace("object", ud);
      //   eval(this.project.scriptcode + "  " + jsfunctiondrag + ";"); // TODO: parameter ersetzen, position, userdata==objectdata, ...
      // }
    }
    if (type == "MouseDown" && this.sceneobjectSceneSelected) {
      // this.vba.deactivateMouse();
    }

    //   console.log("mouseleave1", this.sceneobjectMouseOver, obj);
    if (this.sceneobjectMouseOver != obj) {
      // mouseleave 
      var jsfunction2 = this.sceneobjectMouseOver?.userData?.row[this.sceneobjectMouseOver?.userData?.commentrow.indexOf("MouseLeave")];
      if (jsfunction2) {
        var es = JSON.stringify(eventinfo);
        if (!es) es = "null";
        jsfunction2 = jsfunction2.replace("event", es);
        var ud = this.sceneobjectMouseOver;
        if (ud) ud = JSON.stringify(ud);
        else ud = "null";

        globalThis["leavedobject"] = this.sceneobjectMouseOver;
        jsfunction2 = jsfunction2.replace("object", "globalThis['leavedobject']");
        eval(this.project.scriptcode + "  " + jsfunction2 + ";");
      }
      this.sceneobjectMouseOver = null;
    }


    // neue beredchnung
    if (intersects.length > 0) {
      this.updateThreeFrame();
      obj = intersects[0].object;
      if (obj.parent?.parent?.gizmo) return;
      for (var i = 0; i < intersects.length; i++)
        if (!obj.userData?.commentrow)
          if (intersects[i].object.visible)
            obj = intersects[i].object;
      if (obj.parent?.parent?.gizmo) return;

      var parent = obj.parent;
      //   console.log("obj", obj.name);
      var eventinfo = {
        point: intersects[0].point,
        distance: intersects[0].distance,
        normal: intersects[0].face?.normal,
      }



      /*   if (type == "MouseDown") {
          if (this.sceneobjectSceneSelected && this.sceneobjectSceneSelected != obj && !obj.parent?.parent?.gizmo) {
            // deselect
            try {
              console.log("deselected1", this.sceneobjectSceneSelected);
              var jsfunction2b = this.sceneobjectSceneSelected?.userData?.row[this.sceneobjectSceneSelected?.userData?.commentrow.indexOf("DeSelected")];
              if (jsfunction2b) {
                var es = JSON.stringify(e);
                if (!es) es = "null";
                jsfunction2b = jsfunction2b.replace("event", es);
                var ud = this.sceneobjectSceneSelected;
                globalThis["sceneobjectDeSelected"] = ud;
                if (ud) ud = JSON.stringify(ud);
                else ud = "null";
                jsfunction2b = jsfunction2b.replace("object", ud);
                eval(this.project.scriptcode + "  " + jsfunction2b + ";");
              }
            } catch (ex) {
              console.warn(ex);
            }
            this.sceneobjectSceneSelected = null;
          }
        } */

      if (obj?.userData?.commentrow) {
        //   console.log('scenemousemove', obj?.userData?.commentrow);

        if (type == "MouseMove") {
          // Mouse-Handling unabhaening von definierten events auf sceneobjekte ------
          if (this.sceneobjectMouseOver != obj) {

            // mouseenter
            //    console.log("mouseenter", obj);
            var jsfunction3 = obj?.userData?.row[obj?.userData?.commentrow.indexOf("MouseEnter")];
            if (jsfunction3) {
              var es = JSON.stringify(eventinfo);
              if (!es) es = "null";
              jsfunction3 = jsfunction3.replace("event", es);
              var ud = obj;
              var test = this.scene.getObjectByProperty('uuid', obj.uuid);
              globalThis["enteredobject"] = obj;
              if (ud) ud = JSON.stringify(ud);
              else ud = "null";
              jsfunction3 = jsfunction3.replace("object", "globalThis['enteredobject']");
              eval(this.project.scriptcode + " " + jsfunction3 + ";"); // TODO: parameter ersetzen, position, userdata==objectdata, ...
            }
            this.sceneobjectMouseOver = obj;
          }
        }
        if (type == "MouseDown" && !obj.parent?.parent?.gizmo && obj != this.sceneobjectSceneSelected) {
          this.sceneobjectSceneSelected = obj;
          globalThis["sceneobjectSelected"] = obj;
          if (obj?.userData?.commentrow.indexOf("Selected") > -1) {
            var jsfunctions = obj?.userData?.row[obj?.userData?.commentrow.indexOf("Selected")];
            if (jsfunctions) {
              var es = JSON.stringify(eventinfo);
              if (!es) es = "null";
              jsfunctions = jsfunctions.replace("event", es);
              var ud = obj;
              if (ud) ud = JSON.stringify(ud);
              else ud = "null";
              jsfunctions = jsfunctions.replace("object", ud);
              try {

                eval(this.project.scriptcode + "  " + jsfunctions + ";"); // TODO: parameter ersetzen, position, userdata==objectdata, ...
              } catch (ex) {
                console.warn(ex);
              }
            }
            return;
          }
        }

        if (type == "MouseUp") {
          this.vba.activateMouse();
          // this.sceneobjectSelected = null;
          // globalThis["sceneobjectMouseDown"] = null;
        }

        // alle ereignisse von Objekten -----
        if (obj?.userData?.commentrow.indexOf(type) > -1) {

          var jsfunction = obj?.userData?.row[obj?.userData?.commentrow.indexOf(type)];
          if (jsfunction) {
            var es = JSON.stringify(eventinfo);
            if (!es) es = "null";
            jsfunction = jsfunction.replace("event", es);
            var ud = obj;
            if (ud) ud = JSON.stringify(ud);
            else ud = "null";
            jsfunction = jsfunction.replace("object", ud);
            eval(this.project.scriptcode + "  " + jsfunction + ";"); // TODO: parameter ersetzen, position, userdata==objectdata, ...
          }
          return;
        }
      }
      //  }
    } else {
      // keine intersection
      if (this.sceneobjectSceneSelected && type == "MouseUp") {
        // deselect 
        console.log("deselected2", this.sceneobjectSceneSelected);
        var jsfunction2b = this.sceneobjectSceneSelected?.userData?.row[this.sceneobjectSceneSelected?.userData?.commentrow.indexOf("DeSelected")];
        if (jsfunction2b) {
          var es = JSON.stringify(e);
          if (!es) es = "null";
          jsfunction2b = jsfunction2b.replace("event", es);
          var ud = this.sceneobjectSceneSelected;
          globalThis["sceneobjectDeSelected"] = ud;
          if (ud) ud = JSON.stringify(ud);
          else ud = "null";
          jsfunction2b = jsfunction2b.replace("object", ud);
          eval(this.project.scriptcode + "  " + jsfunction2b + ";");
        }
        //  this.sceneobjectMouseOver = null;
      }
      if (this.sceneobjectMouseOver) {
        // mouseleave 
        //  console.log("mouseleave2", this.sceneobjectMouseOver);
        var jsfunction2 = this.sceneobjectMouseOver?.userData?.row[this.sceneobjectMouseOver?.userData?.commentrow.indexOf("MouseLeave")];
        if (jsfunction2) {
          var es = JSON.stringify(e);
          if (!es) es = "null";
          jsfunction2 = jsfunction2.replace("event", es);
          var ud = this.sceneobjectMouseOver;

          if (ud) ud = JSON.stringify(ud);
          else ud = "null";
          jsfunction2 = jsfunction2.replace("object", ud);
          eval(this.project.scriptcode + "  " + jsfunction2 + ";");
        }
        this.sceneobjectMouseOver = null;
      }

    }
    this.updateThreeFrame();
  }


  selectedSubprojectID = null;
  selectedSubprojectPath = null;
  meshes: any;
  isMainConfig = true;
  sheetSelected = false;
  uihead = "";
  async sceneDoubleClick(e) {
    if (this.mousedrag) {
      this.mousedrag = false;
      return;
    }

    // Get mouse position and setup raycaster
    const canvasTarget = document.getElementById('threecontainer');
    var mouse = new THREE.Vector2();
    mouse.x = (e.layerX / canvasTarget.clientWidth) * 2 - 1;
    mouse.y = -(e.layerY / canvasTarget.clientHeight) * 2 + 1;
    var raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, this.camera);

    // Get intersected objects
    var r = this.selectedprojectpath;
    if (this.selectedprojectpath == "root^$" + this.sheetid) r = "root" // #TODO: sheet1 funzt net
    var proobj = this.scene.getObjectByName(r);
    if (proobj) {
      var intersects = raycaster.intersectObjects(proobj?.children, true);
    }
    var obj = null;

    // Check for dimension text sprite click
    for (var i = 0; i < intersects?.length; i++) {
      if (intersects[i].object?.userData?.isDimensionText) {
        await this.handleDimensionTextClick(intersects[i].object);
        return;
      }

      if (!intersects[i].object.userData.isHelper && intersects[i].object.visible) {
        if (intersects[i].object)
          obj = intersects[i].object;
        break;
      }
    }

    console.log('sceneDoubleClick', e);
    this.sheetSelected = false;
    // this.selectedNode = null;
    //  const canvasTarget = document.getElementById('threecontainer');

    // three js hit test
    var mouse = new THREE.Vector2();
    mouse.x = (e.layerX / canvasTarget.clientWidth) * 2 - 1;
    mouse.y = -(e.layerY / canvasTarget.clientHeight) * 2 + 1;

    //  mouse.x = (this.mouse.x / window.innerWidth) * 2 - 1;
    //   mouse.y = -(this.mouse.y / window.innerHeight) * 2 + 1;
    var raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, this.camera);

    var r = this.selectedprojectpath;
    if (this.selectedprojectpath == "root^$" + this.sheetid) r = "root"
    var proobj = this.scene.getObjectByName(r);
    var intersects = raycaster.intersectObjects(proobj?.children);
    var obj = null;
    //    var intersects = raycaster.intersectObjects(this.scene.children);
    if (intersects?.length > 0) {
      let copied = false;
      for (var i = 0; i < intersects?.length; i++) {

        if (intersects[i].object?.userData?.outputid2 && intersects[i].object?.userData?.index) {
          const outputid = intersects[i].object.userData.outputid2;
          const index = intersects[i].object.userData.index;
          const textToCopy = outputid + "." + index;
          copied = true;
          await this.excels[this.PROJECT].clearClipboard();

          navigator.clipboard.writeText(textToCopy).then(() => {
            console.log('Text copied to clipboard');
            this.toastr.info("Copied to clipboard", textToCopy, { positionClass: 'toast-bottom-right', timeOut: 3000 });
          });
          if (copied) break;
        }
      }

      for (var i = 0; i < intersects?.length; i++) {
        if (!intersects[i].object.userData.isHelper && intersects[i].object.visible) {
          if (intersects[i].object)
            obj = intersects[i].object;
          //  this.cad.visualizeFaceCenters(obj);
          break;
        }
      }
      if (obj) {

        var parent = obj.parent;

        // gltf node?
        while (obj?.userData?.isGltf) {
          obj = obj.parent;
          parent = obj;
        }

        // übergeordnete projekte nicht selektiert?
        var nname = parent.name.replace(this.selectedprojectpath, "");
        if (nname == "") return; // object bereits gewählt

        // object in scene
        if (nname == "root") {
          if (obj.userData.commentrow?.indexOf("ONCLICK") > -1) {
            var index = obj.userData.commentrow.indexOf("ONCLICK");
            var eventname = obj.userData.row[index];
            try {
              var s = eval(this.project.scriptcode + "  " + eventname + ";    console.log('excels. ', this.project)");
            } catch (error) {
              console.log('error', error);

            }

            if (obj.userData.sheetid == this.sheetid) {
              this.hot.selectCell(obj.userData.rowindex, 2);
            }

            return;
          }

          if (obj.userData.sheetid == this.sheetid) {
            this.hot.selectCell(obj.userData.rowindex, 2);
          }
          console.log("obj", obj);

          return;
        }

        // ------------------------

        // project, sheet selected
        var n = "#" + nname.split('#')[1].split('^')[0];
        var nname2 = this.selectedprojectpath + n;
        if (!this.selectedprojectpath.endsWith("^$" + this.sheetid))
          nname2 = this.selectedprojectpath + "^$" + this.sheetid + n;
        var nobj = this.scene.getObjectByName(nname2);
        if (nobj) {
          this.selectedprojectpath = nname2;
          parent = nobj;
        }

        this.scene.traverse((child) => {
          if (child.material) {
            child.material.opacity = 1;// #TODO: opacity zurücksetzen, nicht immer 1
            child.material.transparent = false;
          }
        });


        // project, sheet selected
        if (parent?.userData?.typ?.toUpperCase().startsWith('PROJECT') || parent?.userData?.typ?.toUpperCase().startsWith('SHEET')) {
          if (parent.userData.sheetid == this.sheetid) {
            this.hot.selectCell(parent.userData.rowindex, 2);
          }

          if (parent?.userData?.typ?.toUpperCase().startsWith('PROJECT')) {
            if (parent.visible) {

              this.selectedNode = parent;
              this.selectedSubprojectID = parent.userData.subproject;
              this.selectedProjectName = parent.name;
              this.selectedSubprojectPath = parent.name;// parent.userData.path;
              //   var cellpath = await this.vba.getCellPath(this.selectedSubprojectPath); // zur Bestimmung von javascript selected event
              // TODO              (0, eval)(" globalThis." + configid + path + ".project_created()");

              this.isMainConfig = false;
            }
          }
          if (parent?.userData?.typ?.toUpperCase().startsWith('SHEET')) {
            if (parent?.visible)
              this.selectedNode = parent;
          }

          this.scene.traverse((child) => {

            if (child.material) {

              if (child.userData?.isGltf) {
                child.material.format = THREE.RGBAFormat;
              }
              child.material.transparent = true;
              child.userData.opacity = child.material.opacity;
              child.material.opacity = 0.1;


            }
          });
          this.selectedNode.traverse((child) => {
            if (child.material) {
              if (child.userData.opacity > 0.99)
                child.material.transparent = false;
              if (child.userData.opacity)
                child.material.opacity = child.userData.opacity;
              else
                child.material.opacity = 1;


            }
          });



          this.updateThreeFrame();
          if (parent?.userData?.typ?.toUpperCase().startsWith('SHEET')) {
            if (parent.visible && this.isEditMode) { // sheets by default nicht selectable im Usermode

              this.sheetSelected = true;
              var sid = this.selectedNode.userData.sheetid;
              if (parent.userData.sourcesheetid == this.sheetid) {
                this.hot.selectCell(parent.userData.rowindex, 2);
              }
              // TODO: werte aus zeile holen, aber nicht dauerhaft!

              var vals = Utils.mapRowToParams(parent.userData.row, parent.userData.commentrow);
              this.uihead = parent.userData.row[0];
              this.jsongui2 = await this.excels[this.PROJECT].createJsonGUI(sid);

              this.subprojectSelected();
              this.updateThreeFrame();
              this.selectedObject = obj;
              console.log('selectedObject1', this.selectedObject);
            }
            return;
          } else {
            // subproject/subconfiguration selected

            // konfigurationswerte setzen
            //  this.selectedNode.userData.row
            //    this.selectedNode.userData.commentrow, this.dataset2);
            this.subprojectSelected();
            console.log('selectedObject2', this.selectedObject);

            return;
          }
        }

      }
      this.selectedObject = obj;

      this.highlightproject = null;
      this.higlightProject();
      this.subprojectSelected();

    }

    this.selectedObject = obj;
    // if (obj == null)
    //   this.cad.showFaceCenters(this.selectedObject, this.scene); // removen, sonst über rowselect
    console.log('selectedObject', this.selectedObject);


    // zurück zum Hauptprojekt ------
    // werte in zelle setzen, wenn rootproject
    if (this.selectedNode?.parent?.name == "root" || this.selectedNode?.name == "root") {
      try {
        var cellchanges = [];
        for (var i = 0; i < this.selectedNode?.userData?.row?.length; i++) {
          var cellval = this.selectedNode.userData.row[i];

          // formelnn nicht übernehmen
          var oldcellval = this.cellformulas[this.selectedNode.userData.rowindex][i];
          if (typeof oldcellval === 'string' && oldcellval.startsWith("=")) {
            cellval = oldcellval;
            break;
          }
          var tc = await this.excels[this.PROJECT].setCellContents({ sheet: 0, row: this.selectedNode.userData.rowindex, col: i }, cellval);
          cellchanges.push(...tc);
          this.isMainConfig = true;
          //  this.dataset[this.selectedNode.userData.rowindex][i] = cellval;

        }
        this.cellChanges2dataset(cellchanges);
        this.updateModel(cellchanges, null, this.scene);
        if (this.isEditMode)
          this.hot.render();
      } catch (error) {
        console.warn('updateModel', error);
      }
    }

    if (this.selectedObjectHelper)
      this.scene.remove(this.selectedObjectHelper);
    this.selectedNode = this.scene;
    this.selectedSubprojectID = this.PROJECT;
    this.selectedProjectName = null;
    this.selectedSubprojectPath = null;
    this.selectedprojectpath = "root^$" + this.sheetid;
    this.isMainConfig = true;
    this.jsongui2 = null;
    this.scene.traverse((child) => {
      if (child.material) {
        if (child.userData.opacity > 0.99)
          child.material.transparent = false;
        if (child.userData.opacity)
          child.material.opacity = child.userData.opacity;
        //     else
        //       child.material.opacity = 1;
      }
    });

    this.jsongui = await this.excels[this.PROJECT].createJsonGUI(0);
    this.csschange(this.project.csscode);
    this.subprojectSelected();

    this.updateThreeFrame();

    console.log("scene", this.scene)
  }

  cellChanges2dataset(cellchanges: any[]) {
    for (var i = 0; i < cellchanges?.length; i++) {
      var a = cellchanges[i].address;
      if (a)
        this.dataset[a.row][a.col] = cellchanges[i].newValue;
    }
  }

  async subprojectSelected() {
    if (this.selectedSubprojectID && this.selectedSubprojectID != this.PROJECT) {
      try {

        await this.excels[this.selectedSubprojectID].setConfiugartionsParams(0, this.selectedNode.userData.commentrow, this.selectedNode.userData.row);
        var subsheet = await this.excels[this.selectedSubprojectID].getSheetByIndex(0);
        this.dataset2 = subsheet.cells;
        this.uihead = this.selectedNode.userData.row[0];
        this.jsongui2 = await this.excels[this.selectedSubprojectID].createJsonGUI(0);
        var newcss = this.project.configurations[this.selectedNode.userData.rowindex - 1]?.csscode;
        this.csschange(newcss, true);
      } catch (error) {
        console.warn('subprojectSelected', error);
      }
    }

    // Make all connectors invisible except for main config and selected config
    this.scene.traverse((child) => {
      // Handle connectors
      if (child.userData?.isConnector) {
        child.visible = false;
        const objectPath = child.name;
        const isMainConfig = objectPath.startsWith("root^$" + this.sheetid + "#") && !objectPath.includes("project");
        const isSelectedConfig = objectPath.startsWith(this.selectedprojectpath);
        if (child.material) {
          child.material.visible = isMainConfig || isSelectedConfig;
        }
        child.visible = isMainConfig || isSelectedConfig;
      }

      // Handle edges by checking for objects named "KANTE"
      if (child.name === "KANTE") {
        // Get the parent mesh that owns this edge
        const parent = child.parent;
        if (parent) {
          const isMainConfig = parent.name.startsWith("root^$" + this.sheetid + "#") && !parent.name.includes("project");
          const belongsToSelectedProject = parent === this.selectedNode || this.selectedNode.getObjectById(parent.id);

          // Show edges if:
          // 1. We're in main config and it's a main config object
          // 2. We're in a subproject and the edge belongs to the selected project
          // 3. We're deselecting (selectedSubprojectID === PROJECT) and it's a main config object
          const shouldShowEdge =
            (this.isMainConfig && isMainConfig) ||
            (!this.isMainConfig && belongsToSelectedProject) ||
            (this.selectedSubprojectID === this.PROJECT && isMainConfig) ||
            (this.selectedNode === this.scene); // Show all main config edges when returning to main scene

          child.visible = shouldShowEdge;
          if (child.material) {
            child.material.visible = shouldShowEdge;
          }
        }
      }
    });

    this.updateThreeFrame();
  }


  gridchange() {
    this.baseScene.axes.visible = this.project.sceneSettings.showGrid;
    this.baseScene.grid.visible = this.project.sceneSettings.showGrid;
    this.updateThreeFrame();
  }

  async logout() {
    var r = await this.auth.signOut();
    console.log('logout', r);
    this.router.navigate(['login']);
  }

  mouseWheel(event) {
    if (this.project.settings.cameraZoomPointer) {
      var container = document.getElementById("threecontainer");
      var offsetleft = window.innerWidth - container.clientWidth;
      var mX = (event.clientX - offsetleft) / (container.clientWidth) * 2 - 1;
      var mY = -event.clientY / container.clientHeight * 2 + 1;
      var vector = new THREE.Vector3(mX, mY, 0.1);
      vector.unproject(this.camera);
      vector.sub(this.camera.position);
      var factor = 8 + this.camera.position.distanceTo(vector) / 40;
      //console.log("mousewheel vector", vector, "campos", this.camera.position, this.camera.position.distanceTo(vector));

      if (event.deltaY < 0) {
        this.camera.position.addVectors(this.camera.position, vector.setLength(factor));
        this.controls.target.addVectors(this.controls.target, vector.setLength(factor));
      } else {
        this.camera.position.subVectors(this.camera.position, vector.setLength(factor));
        this.controls.target.subVectors(this.controls.target, vector.setLength(factor));
      }
    }

    // console.log("mousewheel", this.camera.position, this.camera.zoom, this.camera.aspect, this.camera.fov, this.camera);

    this.updateThreeFrame();
  }


  handleAngleChange(angle: number) {
    console.log('Angle changed to:', angle);
    //   this.baseScene.dirLight.position.set(Math.cos(angle) * 2000, Math.sin(angle) * 2000, 1000.75);
    //    this.baseScene.dirLight.position.set(-1, -1.45, 1.75);

    //   this.baseScene.dirLightHelper.parent.updateMatrixWorld();
    // this.baseScene.dirLightHelper.update();
    this.project.sceneSettings.dirAngle = angle;
    this.updateScene(null);
    this.updateThreeFrame();
  }

  changeHandling() {
    console.log('changeHandling', this.project.settings.subprojectSelectable, this.project.cameraPan, this.project.settings.midpontNavigation);
    if (this.project.settings.subprojectSelectable) {
    }
    // if (this.chkCameraPan) {
    this.controls.enablePan = this.project.settings.cameraPan;

    this.controls.enableZoom = this.project.settings.cameraZoom;
  }
  // outlinePass: OutlinePass;
  // composer: EffectComposer;
  animate(time) {
    try {
      if (this.project?.sceneSettings?.showAnimations) {
        window.requestAnimationFrame(async () => {
          if (!this.processing) {
            var delta = this.clock.getDelta();
            this.scene.traverse(function (node) {
              if (node?.userData && Object.keys(node.userData).length > 0) {
                if (node.userData.mixer) {
                  node.userData.mixer.update(delta);
                }
              }
            });

            //          this.camera.aspect = this.threecontainerwidth / this.threecontainerheight;
            //  this.camera.updateProjectionMatrix();
            this.controls.update(delta);
            this.renderer.render(this.scene, this.camera);
            this.viewportGizmo?.update();
            this.viewportGizmo?.render();

          } else {
            // Add small delay without blocking thread
            this.renderer.render(this.scene, this.camera);
            await new Promise(resolve => setTimeout(resolve, 300));
          }
          this.animate(time);
        }
        );
      }
    } catch (error) {
      console.warn("animate", error);
    }


  }

  async takeScreenshot(overwrite) {
    this.processing = true;
    this.processingMessage = 'Taking screenshot...';
    try {
      await this.save();  // Use save() instead of saveproject() to properly handle the isSaving state
      ScreenshotService2.takeScreenshot(this.scene, this.camera, this.renderer, this.projectid, this.customerid, this.project, this.afs, overwrite);
    } finally {
      this.processing = false;
      this.processingMessage = '';
    }
  }
  updateThreeFrame() {
    try {
      if (!this.project?.sceneSettings?.showAnimations) {
        this.controls.enableDamping = false;

        var delta = this.clock.getDelta();
        this.controls.update(delta);
        this.camera.updateProjectionMatrix();

        this.scene.traverse((object) => {
          // Check if the object is a sprite
          if (object instanceof THREE.Sprite) {
            // Update the sprite's matrix
            object.updateMatrix();
          }
        });


        this.renderer.render(this.scene, this.camera);
        this.viewportGizmo?.update();
        this.viewportGizmo?.render();
        //  this.composer.render();
      }
      else {
        this.controls.enableDamping = true;
      }
    } catch (error) {
      console.warn("updateThreeFrame", error);
    }
  }

  raycaster = null;
  mouse = new THREE.Vector2();
  INTERSECTED = null;
  mousemove(event: any) {
    this.updateThreeFrame();
    var container = document.getElementById("threecontainer");
    this.camera.aspect = container.clientWidth / container.clientHeight;
    this.mouse.x = (event.layerX / container.clientWidth) * 2 - 1;
    this.mouse.y = -(event.layerY / container.clientHeight) * 2 + 1;
  }
  async splitDragEnd(x: any) {
    try {
      console.log('splitDragEnd', x);
      // Calculate panel widths
      this.leftpanelwidth = Math.round(x.sizes[0] / 100 * window.innerWidth);
      this.splitleft = x.sizes[0];
      this.threecontainerwidth = window.innerWidth - this.leftpanelwidth;

      // Get container and ensure it exists
      const container = document.getElementById("threecontainer");
      if (!container) throw new Error("Container 'threecontainer' not found");

      // Update container style if needed
      container.style.width = `${this.threecontainerwidth}px`;

      if (this.camera instanceof THREE.OrthographicCamera) {
        const aspect = this.threecontainerwidth / container.clientHeight;
        const currentZoom = this.camera.zoom;

        // Store initial size only once when first switching to orthographic
        if (this.initialFrustumSize === null) {
          this.initialFrustumSize = this.camera.top - this.camera.bottom;
        }

        // Always use the initial size for calculations
        const size = this.initialFrustumSize;

        // Update all frustum planes using the initial size
        this.camera.left = -size * aspect / 2;
        this.camera.right = size * aspect / 2;
        this.camera.top = size / 2;
        this.camera.bottom = -size / 2;

        this.camera.zoom = currentZoom;
      } else {
        // Handle perspective camera
        this.camera.aspect = this.threecontainerwidth / container.clientHeight;
      }

      this.camera.updateProjectionMatrix();
      this.renderer.setSize(this.threecontainerwidth, container.clientHeight);
      this.renderer.autoClear = false;
      this.renderer.setClearColor(0x000000, 0.0);

      await this.setVars();

      if (this.isEditMode) {
        this.hot?.render();
      }

      if (this.viewportGizmo) {
        this.viewportGizmo.left = this.threecontainerwidth - 128;
      }

      this.updateThreeFrame();
    } catch (error) {
      console.warn("splitDragEnd error:", error);
    }
  }

  async setVars() {

    try {

      // API vars
      var changes = await this.excels[this.PROJECT].changeNamedExpression('screensize', this.threecontainerwidth + "," + window.innerHeight);
      console.log('setvars changes', changes);

      this.updateHotChanges(changes);

      var changes2 = await this.excels[this.PROJECT].changeNamedExpression('screenwidth', this.threecontainerwidth);
      this.updateHotChanges(changes2);
      var changes3 = await this.excels[this.PROJECT].changeNamedExpression('screenheight', window.innerHeight);
      this.updateHotChanges(changes3);
      // TODO
      // var changes4 = await this.excels[this.PROJECT].addNamedExpression('window.height', 123);
      // this.updateHotChanges(changes4);
      // var changes5 = await this.excels[this.PROJECT].changeNamedExpression('window_width', 234324);
      // this.updateHotChanges(changes5);


      var dataset = this.dataset;
      var sheetid = this.sheetid; //TODO: muss nicht stimmen!

      var allchanges = changes.concat(changes2).concat(changes3);

      // TODO: update model/  verweise => setcontens
      for (var j = 0; j < allchanges?.length; j++) {
        var a = allchanges[j].address;
        if (a)
          if (dataset[a.row][1] != null && dataset[a.row][1] != "" && a.row > this.outputrow && dataset[a.row][0] != "#") {

            var typ = dataset[a.row][1];
            var objectname = "$" + sheetid + "#" + dataset[a.row][0];
            var commentrow = SheetHelper.getCommentRow(dataset, a.row); // dataset[a.row - 1]

            if (this.isInList(this.overlayTypes, typ)) {
              // 2d overlays 
              var overlay = await this.updateOverlay(j, objectname, dataset[a.row], commentrow, typ);
            }
            else {
              //    var newnode = await this.cad.createOrUpdateCADNode(a.row, sheetid, objectname, dataset[a.row], commentrow, scenenode, this.excels, this.selectedSubprojectID, this.toload, this.workers, this.customerid, this.projectid, this.project.configModel);
            }
          }
      }
    }
    catch (e) {
      console.log(e);
    }
  }


  refnames = [];
  async formulaChange(event: any) {
    console.log("formulaChange", this.formula);
    try {
      this.refnames = [];
      // bezeichnungen aus referenzen
      var cellValue = this.formula;
      if (cellValue) {
        this.refs = Utils.getCellReferences(cellValue);


        for (var i = 0; i < this.refs?.length; i++) {
          var ref = this.refs[i];
          var cellAddress = await this.excels[this.PROJECT].simpleCellAddressFromString(ref, this.sheetid);
          var cell = await this.excels[this.PROJECT].getCellValue(cellAddress);
          this.outputrow = SheetHelper.getOutputRow(this.dataset);
          if (cellAddress.row > this.outputrow) {
            var c = { sheet: this.sheetid, row: cellAddress.row, col: 0 };
            var rightCellValue = await this.excels[this.PROJECT].getCellValue(c);
          } else {
            var c = { sheet: this.sheetid, row: cellAddress.row, col: 5 };
            var rightCellValue = await this.excels[this.PROJECT].getCellValue(c);
          }
          if (!this.refnames.includes(ref + ": " + rightCellValue)) {
            this.refnames.push(ref + ": " + rightCellValue);
          }
        }
      }

      this.applyCellMeta();

    } catch (error) {
      console.warn('Error in afterSelection:', error);
    }
  }

  private updateHotChanges(changes) {
    if (changes)
      for (var j = 0; j < changes.length; j++) {
        var change = changes[j];
        var cellAddress = change.address;
        var cellValue = change.newValue;
        if (cellAddress)
          this.dataset[cellAddress.row][cellAddress.col] = cellValue;
      }

  }
  openfunctionsdialog() {

    const dialogRef = this.dialog.open(DialogfunctionsComponent, {
      width: '980px',
      data: {
      }
    });

  }
  restrictMove = false
  selectedName = "";
  selectedNode: any;

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  /// --- für javascript api vbaapi
  async getactiveconfig() {
    var configModel = await this.createConfigModel();
    console.log('getactiveconfig', configModel);
    return configModel;
  }
  async saveconfig(id?: string) {
    var configModel = await this.createConfigModel();

    if (id) {
      await this.afs.doc(`configs/${this.customerid}/${this.projectid}/${id}`).set(
        JSON.parse(JSON.stringify(configModel)));
      return id;
    } else {
      var r = await this.afs.collection(`configs/${this.customerid}/${this.projectid}`).add(
        JSON.parse(JSON.stringify(configModel)));
      return r.id;
    }
  }

  previousCameraPosition: any;
  previousControlsTarget: any;
  previousCamera: any;
  setIsometricView(initial: boolean = false) {
    // Store previous camera state
    this.previousCamera = this.camera;
    this.previousCameraPosition = this.camera.position.clone();
    this.previousControlsTarget = this.controls.target.clone();

    const boundingBox = new THREE.Box3().setFromObject(this.scene);
    const center = boundingBox.getCenter(new THREE.Vector3());
    const size = boundingBox.getSize(new THREE.Vector3());

    const maxDim = Math.max(size.x, size.y, size.z);
    let distance;

    if (this.camera instanceof THREE.PerspectiveCamera) {
      const fov = this.camera.fov * (Math.PI / 180);
      distance = maxDim / (2 * Math.tan(fov / 2));
    } else {
      distance = maxDim;
    }

    distance *= 1.5;

    const aspect = this.renderer.domElement.width / this.renderer.domElement.height;
    const frustumSize = maxDim * 1.5 / 100;

    // Adjust near plane to be further from camera to prevent clipping
    const nearPlane = -distance; // Use negative distance for near plane
    const farPlane = distance * 3;

    this.camera = new THREE.OrthographicCamera(
      frustumSize * aspect / -2,
      frustumSize * aspect / 2,
      frustumSize / 2,
      frustumSize / -2,
      nearPlane,
      farPlane
    );

    // Set camera position and orientation
    if (initial && this.project?.camera) {
      const cameraData = JSON.parse(this.project.camera);
      this.camera.matrix.fromArray(cameraData);
      this.camera.matrix.decompose(this.camera.position, this.camera.quaternion, this.camera.scale);

      // Restore zoom from project if available, otherwise use default zoom
      if (this.project.camerazoom !== undefined) {
        this.camera.zoom = this.project.camerazoom;
      } else {
        this.camera.zoom = 1;
      }
      this.camera.updateProjectionMatrix();
    } else {
      this.camera.position.copy(this.previousCameraPosition);
      this.camera.lookAt(this.previousControlsTarget);

      // Maintain zoom level when switching to isometric
      if (this.previousCamera instanceof THREE.OrthographicCamera) {
        this.camera.zoom = this.previousCamera.zoom;
      } else {
        // Set a reasonable default zoom when switching from perspective
        this.camera.zoom = 1;
      }
      this.camera.updateProjectionMatrix();
    }

    this.camera.up.set(0, 0, 1);

    // Update controls to use new camera
    this.controls.object = this.camera;
    this.controls.target.copy(this.previousControlsTarget);
    this.controls.update();

    // Update viewport gizmo
    if (this.viewportGizmo) {
      this.viewportGizmo.camera = this.camera;
      this.viewportGizmo.updateMatrix();
    }

    this.project.sceneSettings.iso = true;
    this.updateThreeFrame();
  }


  resetToPerspective() {
    if (this.previousCamera) {
      this.project.sceneSettings.iso = false;

      // Create a new perspective camera
      const aspect = this.rendererContainer.nativeElement.clientWidth / this.rendererContainer.nativeElement.clientHeight;
      const newCamera = new THREE.PerspectiveCamera(45, aspect, 0.1, 10000);

      // Copy position and rotation from current camera
      newCamera.position.copy(this.camera.position);
      newCamera.rotation.copy(this.camera.rotation);
      newCamera.up.copy(this.camera.up);

      // Store old camera reference and switch to new one
      const oldCamera = this.camera;
      this.camera = newCamera;

      // Update controls
      this.controls.object = this.camera;
      this.controls.target.copy(this.controls.target);
      this.controls.update();

      // Update viewport gizmo
      if (this.viewportGizmo) {
        this.viewportGizmo.camera = this.camera;
        this.viewportGizmo.updateMatrix();
      }

      this.updateThreeFrame();
    }
  }
  async loadconfig(configid) { // TODO
    var r = await this.afs.collection("configs/" + this.customerid + "/" + this.projectid).doc(configid).get();
    return r;
  }
  /// --- fr javascript api vbaapi ende


  running = false;
  async controlC(val: any) {
    console.log('controlC from editor', JSON.stringify(val));

    // nur zumtetst    
    if (val.typ == "BUTTON" && val.outputValue == "save") {
      if (!this.isEditMode) {
        var configModel = await this.createConfigModel();
        const dialogRef = this.dialog.open(DialogsaveconfigComponent, {
          width: '980px',
          data: {
            configModel: configModel,
            project: this.project,
            projectid: this.projectid
          },
        });

        dialogRef.afterClosed().subscribe(async result => {
          console.log('The dialog was closed', result);
          if (result) {
            configModel.enduserdata = result;
            var r = await this.afs.collection("configs/" + this.customerid + "/" + this.projectid).add(
              JSON.parse(JSON.stringify(configModel)));
            var text = "";
            var configid = r.id;
            console.log("config saved", r);

            // save glb and collada
            var blob = Utils.saveGltf(this.scene, this.projectid, this.customerid, configid, this.afs);

            if (this.project.userformsendmail) text = "You will receive an email shortly with your configuration.";
            this.snackBar.open("Configuration saved. " + text + " " + configid, "", { duration: 5000 });
          }
        });
      }
    }
    if (val.typ == "BUTTON" && val.outputValue != "save") {
      console.log("BUTTON", this.project.scriptcode);
      eval(this.project.scriptcode + "  " + val.onclick + ";    console.log('excels. ', this.project)");
      //      eval(val.onclick)
      return;
    }
    if (val.typ == "CHECKBOX") {
      val.outputValue = !val.outputValue;
    }
    if (val.typ == "RADIO") {
      // !val.outputValue;
      // alten auf false setzen
      var r = val.sheet.row - 1;
      while (this.cellformulas[r][1]?.indexOf("radio") > -1) {
        this.cellformulas[r][2] = false;
        this.dataset[r][2] = false;
        var cell = JSON.parse(JSON.stringify(val.sheet)); cell.row = r;
        await this.sheetChange(cell, false, true, false, false);
        r--;
      }
      r = val.sheet.row + 1;
      while (this.cellformulas[r][1]?.indexOf("radio") > -1) {
        this.cellformulas[r][2] = false;
        this.dataset[r][2] = false;
        var cell = JSON.parse(JSON.stringify(val.sheet)); cell.row = r;
        await this.sheetChange(cell, false, true, false, false);
        r++;
      }
      val.outputValue = true;
      //       this.cellformulas[cell.row][cell.col] = false;
      //      await this.sheetChange(cell, v, v);

    }

    var cell = val.sheet;
    if (cell) {
      var valtemp = val.outputValue;
      var v = null;
      if (!isNaN(valtemp))
        var f = parseFloat(valtemp);
      if (isNaN(f))
        v = valtemp
      else
        v = f;

      // untersheet aktiviert -> Wert von UI als Parameter in Mainsheet
      if (this.sheetSelected) {
        cell.sheet = this.sheetid;
        var paramname = val.controlName;
        cell.row = this.selectedNode.userData.rowindex;

        // search col for paramname
        var ca1 = await Utils.searchCellByValue(this.excels[this.PROJECT], { col: 0, row: cell.row, sheet: cell.sheet }, "#", 1);
        cell.col = (await Utils.searchCellByValue(this.excels[this.PROJECT], { col: 0, row: ca1.row, sheet: ca1.sheet }, paramname, 2))?.col;

        //var address = await Utils.searchControlNameAddress(this.excels[this.PROJECT], paramname);

      }

      if (this.isMainConfig)
        this.cellformulas[cell.row][cell.col] = v;

      var exportedchanges = await this.sheetChange(cell, v, v);
      //  this.jsongui = await this.excels[this.PROJECT].createJsonGUI(this.sheetid);
      //  this.valueSubject.next(v);
    }


  }

  openDialog(): void {
    // this.saveproject();

    const dialogRef = this.dialog.open(PublishDialog, {
      width: '990px',
      height: '615px',
      data: { project: this.project, userid: this.customerid }
      //   data: { name: this.name, animal: this.animal },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }

  openMenuDialog(): void {
    const dialogRef = this.dialog.open(DialogmenuComponent, {
      width: '980px',
      height: '615px',
      data: { project: this.project, userid: this.customerid }
      //   data: { name: this.name, animal: this.animal },
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }

  openSubProjectDialog(): void {
    const dialogRef = this.dialog.open(DialogsubprojectComponent, {
      width: '980px',
      data: { excels: this.excels, hf: this.hf, sheetid: this.sheetid, showAnimations: this.project.sceneSettings.showAnimations, PROJECT: this.PROJECT, dataset: this.dataset },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('project dialog was closed', result);

    });
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
  showfilesDialog(filter, edit = false, primitive = false) {
    const dialogRef = this.dialog.open(FilesDialog, {
      width: '1250px',
      height: '95%',
      data: { filter: filter },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);

      if (result) {
        this.copyMessage(result)

        // hack
        if (filter == "png")
          if (!edit)
            if (primitive)
              this.addPrimitive('image3d', result)
            else
              this.addGui('image', result)

        if (edit)
          this.cellSelected = result;

      }
      if (filter == "gltf") {
        if (!edit)
          this.addPrimitive('gltf', result);
        if (edit) {

          var cell = { row: this.curSelectedRow, col: this.curSelectedCol, sheet: this.sheetid };
          this.sheetChange(cell, [[result]], [[result]], true);
        }
      }

    });

  }
  async showmaterialsDialog(baseScene, selected) {
    const dialogRef = this.dialog.open(MaterialbComponent, {
      width: '800px',
      data: { baseScene: baseScene, val: selected },
    });

    dialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed', result);
      if (result) {
        var sc = this.hot.getSelected();
        var col = sc[0][1];
        var row1 = sc[0][0];
        var changes = await this.excels[this.PROJECT].setCellContents({ sheet: this.sheetid, col: col, row: row1 }, result);
        this.dataset[row1][col] = result;
        this.cellformulas[row1][col] = result;
        this.sheetChange({ sheet: this.sheetid, row: row1, col: col }, result, result);

        this.hot.render();
        // todo applyc
      }
    });

  }

  async showshapeDialog() {
    const dialogRef = this.dialog.open(DialogshapeComponent, {
      width: '1150px',
      data: {
        value: this.dataset[this.hot.getSelected()[0][0]][this.hot.getSelected()[0][1]],
      },
    });

    dialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed', result);
      if (result) {
        if (result.copytable) {
          var ij = result.cellref;
          var changes = await this.excels[this.PROJECT].setCellContents({ sheet: this.sheetid, col: ij[1], row: ij[0] }, result.td);
          for (var i = 0; i < result.td.length; i++)
            for (var j = 0; j < result.td[i].length; j++) {
              this.dataset[ij[0] + i][ij[1] + j] = result.td[i][j];
              this.cellformulas[ij[0] + i][ij[1] + j] = result.td[i][j];
              //   this.sheetChange({ sheet: this.sheetid, row: ij[0] + i, col: ij[1] + j }, result.td[i][j], result.td[i][j]);
            }
          this.hot.render();
          // this.sheetChange({ sheet: this.sheetid, row: row1, col: col }, result.val, result.val);
        }

        var sc = this.hot.getSelected();
        var col = sc[0][1];
        var row1 = sc[0][0];
        var changes = await this.excels[this.PROJECT].setCellContents({ sheet: this.sheetid, col: col, row: row1 }, result.val);
        this.dataset[row1][col] = result.val;
        this.cellformulas[row1][col] = result.val;
        this.sheetChange({ sheet: this.sheetid, row: row1, col: col }, result.val, result.val);
        this.hot.render();

      }
    });

  }

  private lastSaveTime: number = Date.now();
  public isSaving = false;  // Add new flag for saving state
  public saveSuccess = false; // Add new flag for save success animation
  public processingMessage = ''; // Track the current processing message

  // Update this whenever you save
  private updateLastSaveTime() {
    this.lastSaveTime = Date.now();
  }

  // Call this in your save method
  async save() {
    if (this.isSaving) return;  // Prevent multiple simultaneous saves

    this.isSaving = true;
    try {
      await this.saveproject();  // Your existing save logic
      this.updateLastSaveTime();
      this.saveSuccess = true;
      setTimeout(() => {
        this.saveSuccess = false;
      }, 2000); // Reset after 2 seconds
    } finally {
      this.isSaving = false;
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    // If navigation was already confirmed through our router events handler, allow it
    if (this.deactivating.observers.length > 0) {
      return this.deactivating.asObservable();
    }

    const timeSinceLastSave = Date.now() - this.lastSaveTime;
    if (timeSinceLastSave > 10000) {
      // Only show dialog if it wasn't already shown by router events
      const dialogRef = this.dialog.open(ConfirmLeaveDialogComponent, {
        width: '400px',
        disableClose: true,
        data: { timeSinceLastSave: Math.floor(timeSinceLastSave / 1000) }
      });

      dialogRef.afterClosed().subscribe(result => {
        this.deactivating.next(result);
        this.deactivating.complete();
      });

      return this.deactivating.asObservable();
    }

    return true;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    const timeSinceLastSave = Date.now() - this.lastSaveTime;
    if (timeSinceLastSave > 10000) {
      // This empty string is required to prevent the browser dialog in some browsers
      $event.returnValue = '';
      return '';
    }
    return null;
  }

  private handleNavigation(allow: boolean) {
    if (allow) {
      this.deactivating.next(true);
      this.deactivating.complete();
      window.history.back();
    } else {
      this.deactivating.next(false);
      this.deactivating.complete();
      // Stay on current page
      history.pushState(null, '', window.location.pathname);
    }
  }

  ngOnDestroy() {
    if (this.interactionManager) {
      this.interactionManager.dispose();
    }
    if (this.cursorSubscription) {
      this.cursorSubscription.unsubscribe();
    }
  }



  private cursorSubscription: Subscription;  // Add this line

  public exportToSTEP(): void {
    const dialogRef = this.dialog.open(ExportDialogComponent, {
      data: { scene: this.scene }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Handle any post-export actions if needed
      }
    });
  }

  ngOnInit() {
    // Initialize theme from localStorage or default to dark mode
    const savedTheme = localStorage.getItem('theme') || 'dark';
    this.isDarkMode = savedTheme === 'dark';
    if (this.isDarkMode) {
      document.body.classList.add('darkmode');
    } else {
      document.body.classList.remove('darkmode');
    }

    // Get URL parameters for AI model and run number
    this.route.queryParams.subscribe(params => {
      if (params['prompt'] && params['model']) {
        // Wait for aiPromptEditor to be available
        const checkEditor = setInterval(() => {
          if (this.aiPromptEditor) {
            clearInterval(checkEditor);

            // Show the editor
            this.showAIPromptEditor = true;

            // Configure the editor
            this.aiPromptEditor.aiProvider = params['model'];

            // Set the prompt directly in the current tab
            const decodedPrompt = decodeURIComponent(params['prompt']);
            this.aiPromptEditor.tabs[0].prompt = decodedPrompt;

            // If do5runs is specified, trigger the 5x run after a delay
            if (params['do5runs']) {
              setTimeout(() => {
                // For multi-prompt mode, we want to run just this single prompt 5 times
                if (params['multiPrompt']) {
                  this.aiPromptEditor.do5runs();
                } else {
                  this.aiPromptEditor.do5runs();
                }
              }, 2000);
            }
          }
        }, 100);

        // Clear interval after 10 seconds if editor is not found
        setTimeout(() => clearInterval(checkEditor), 10000);
      }
    });

    // ... rest of existing ngOnInit code ...
  }

  // Add this new method
  toggleTheme() {
    this.isDarkMode = !this.isDarkMode;
    if (this.isDarkMode) {
      document.body.classList.add('darkmode');
    } else {
      document.body.classList.remove('darkmode');
    }
    // Save preference to localStorage
    localStorage.setItem('theme', this.isDarkMode ? 'dark' : 'light');
  }

  handleAISuggestion(suggestion: { cell: string, value: string }) {
    // Convert cell reference (e.g. 'A1') to row and column indices
    const col = suggestion.cell.match(/[A-Z]+/)[0];
    const row = parseInt(suggestion.cell.match(/\d+/)[0]) - 1;
    const colIndex = col.split('').reduce((acc, char) => acc * 26 + char.charCodeAt(0) - 64, 0) - 1;

    // Update the cell value
    if (this.hot) {
      this.hot.setDataAtCell(row, colIndex, suggestion.value);
    }
  }

  showAIAgent = false;  // Add this property

  toggleAIAgent() {
    if (this.isAIAgentMinimized) {
      this.isAIAgentMinimized = false;
    } else {
      this.showAIAgent = !this.showAIAgent;
      if (this.showAIAgent) {
        // Trigger initial analysis when showing the agent
        const aiAgent = this.aiAgentComponent;
        if (aiAgent) {
          aiAgent.analyzeModel();
        }
      }
    }
  }

  @ViewChild(AIAgentComponent) aiAgentComponent: AIAgentComponent;

  minimizeAIAgent() {
    this.isAIAgentMinimized = true;
  }

  isAIAgentMinimized = false;

  private updateTitle() {
    if (this.project?.name) {
      this.titleService.setTitle(`${this.project.name} - SheetBuild`);
    } else {
      this.titleService.setTitle('SheetBuild');
    }
  }

  public isRecreating = false;
  public hasRecreated = false;

  async recreateModel() {
    // Start fade out animation
    this.isRecreating = true;
    this.processing = true;
    this.processingMessage = 'Recreating model...';

    try {
      // Wait for fade out animation to complete
      await new Promise(resolve => setTimeout(resolve, 300));

      // Clear existing scene objects except lights and camera
      await ThreeUtils.clearScene(this.scene);
      await this.applyCellMeta();
      this.jsongui = await this.excels[this.PROJECT].createJsonGUI(this.sheetid);
      await this.createModel(this.scene, this.dataset, this.PROJECT);

      // Start fade in animation
      this.isRecreating = false;
      this.hasRecreated = true;

      // Reset hasRecreated after animation completes
      setTimeout(() => {
        this.hasRecreated = false;
      }, 300);

    } catch (error) {
      console.error('Error recreating model:', error);
      this.toastr.error('Error recreating model');
      this.isRecreating = false;
    } finally {
      this.processing = false;
      this.processingMessage = '';
    }
  }

  private async handleDimensionTextClick(object: any) {
    var dl = object as any;
    var editcell = dl.parent.userData.row[dl.parent.userData.commentrow.indexOf("editcell")];

    const dialogRef = this.dialog.open(InputdialogComponent, {
      width: '250px',
      data: {
        name: object.userData.dimensionValue.toString(),
        title: 'Enter Dimension Value',
        okbutton: 'Update'
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (editcell) {
          var cellRef = await this.excels[this.PROJECT].simpleCellAddressFromString(editcell, dl.parent.userData.sheetid);

          var changes = await this.excels[this.PROJECT].setCellContents(cellRef, result);
          // #todo apply changes to dataset
          for (var i = 0; i < changes.length; i++) {
            var change = changes[i];
            if (change.address.sheet == 0)
              this.dataset[change.address.row][change.address.col] = change.newValue;
          }

          if (this.isEditMode)
            this.hot.render();
          //   this.updateModel(this.PROJECT, this.sheetid, this.dataset);
          if (this.sheetid == cellRef.sheet)
            this.dataset[cellRef.row][cellRef.col] = result;

          await this.updateModel(changes, null, this.scene); // #todo here scene??
          this.updateThreeFrame();
        }
      }
    });
  }

}
